import { Modal, Button, Form, InputGroup, CloseButton } from "react-bootstrap";
import { FormGroup, Label, Input, FormText } from "reactstrap";

export default function AddmusicModal({ show, handleShow, handleClose }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered={true}
        keyboard={false}
        className="addmusic-modal"
      >
        <Modal.Body>
          {/* <CloseButton
            variant="black"
            onClick={handleClose}
            className="close-addmusic"
          /> */}
          <div className="addmuisic-modal-body ">
            <div className="modal-head text-center">
              <p className="add_m_title">Add Music</p>
              <p className="add_m_sub">
                Same music will be applied to all videos
              </p>
            </div>

            <ul className="music-list">
              {[1, 2, 3, 4, 5].map(() => {
                return (
                  <li>
                    <div className="music-list-div">
                      <div className="music-left">
                        <Form>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" />{" "}
                            </Label>
                          </FormGroup>
                        </Form>

                        <div className="music-col">
                          <div className="music-iocn">
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--majesticons"
                              width="25"
                              height="25"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <g fill="none">
                                <circle
                                  cx="6"
                                  cy="18"
                                  r="3"
                                  fill="#3399FF"
                                  stroke="#3399FF"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <circle
                                  cx="18"
                                  cy="17"
                                  r="3"
                                  fill="#3399FF"
                                  stroke="#3399FF"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <path fill="#3399FF" d="M21 3L9 6v4l12-3V3z" />
                                <path
                                  stroke="#3399FF"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 18v-8m12 7V7M9 10V6l12-3v4M9 10l12-3"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="music-cont">
                            <h5>Music1.mp3</h5>
                            <p>Added by 156</p>
                          </div>
                        </div>
                      </div>

                      <a href="#">
                        <div className="music-rigth text-center">
                          <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--ic"
                            width="32"
                            height="32"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#2D1159"
                              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM9.5 16.5v-9l7 4.5l-7 4.5z"
                            />
                          </svg>
                          <p>PLAY</p>
                        </div>
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>

            <div className="floor-plan-btns add-musicbnt mt-4">
              <Button className="back-btn" onClick={handleClose}>
                {" "}
                Back
              </Button>
              <Button className="Continue-btn">Save Details</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
