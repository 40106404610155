import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Budgetitemsmid from "./Budgetitemsmid";


import ScrollTop from "components/scrollTop";

const Budgetitems = () => {
  return (
    <div>
      <ScrollTop />
      <Header />
      <Budgetitemsmid/>
      <Footer />
    </div>
  );
};

export default Budgetitems;
