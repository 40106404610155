import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import DatePicker from "sassy-datepicker";
import Img from "../../assets/images/m_userimg.png";
import { Card, Container } from "react-bootstrap";
import location from "../../assets/svg/Path3773.svg";
import Footer from "../../components/Footer";
import Fade from "react-reveal/Fade";
import ScrollTop from "components/scrollTop";
import GetLocalUser from "store/localStorage";
import { Modal, Form } from "react-bootstrap";
export default function Appointment(props) {
  const user = GetLocalUser();
  const [date, setDate] = useState(new Date());
  const onChange = (newDate) => {
    console.log(`New date selected - ${newDate.toString()}`);
    setDate(newDate);
  };
  useEffect(() => {
    let test = document.getElementsByClassName("sdp--text__inactive");
    if (test) {
      test[0].innerHTML = "S";
      test[1].innerHTML = "M";
      test[2].innerHTML = "T";
      test[3].innerHTML = "W";
      test[4].innerHTML = "T";
      test[5].innerHTML = "F";
      test[6].innerHTML = "S";
    }
  }, []);
  const [currentTab, setcurrentTab] = useState(0);
  const [showArrange, setshowArrange] = useState();
  const openArrangeDialog = () => {
    setshowArrange(true);
  };
  const handleArrangeClose = () => {
    setshowArrange(false);
  };
  return (
    <>
      <ScrollTop />
      <Header />

      <Container className="my-con">
        <h2 className="list_title">Appointments</h2>
        <div className="appointment_wrapper">
          <div className="row  gap-2 ">
            <div className="col-md-4 col-sm-12 col-12 col-xl-3 col-lg-3 appointment_left_wrapper">
              <Fade>
                <p className="selected_date_title">Select Date</p>
                <DatePicker
                  onChange={onChange}
                  selected={date}
                  style={{ width: "100%" }}
                />
              </Fade>
            </div>
            <div className="col-md-8 col-sm-12 col-12 col-xl-8  col-lg-8 ms-auto appointment_right_wrapper ">
              {/* tabs bttn */}
              <div className="style_btn_div style_btn_div323">
                <button
                  className={currentTab == 0 ? "style_btn" : "style_btn2"}
                  onClick={() => setcurrentTab(0)}
                >
                  Upcoming
                </button>
                <div className="tedsdd"></div>
                <button
                  className={currentTab == 1 ? "style_btn" : "style_btn2"}
                  onClick={() => setcurrentTab(1)}
                >
                  Completed
                </button>
                <div className="tedsdd"></div>
                <button
                  className={currentTab == 2 ? "style_btn" : "style_btn2"}
                  onClick={() => setcurrentTab(2)}
                >
                  Cancelled
                </button>
              </div>
              <p className="appointment_heading">In person Viewing(3)</p>
              <div className="appointment_content">
                <ul>
                  {[1, 2, 3].map((d) => {
                    return (
                      <li>
                        {user?.userType == "seller" && (
                          <div className="appointment_card">
                            <Fade bottom cascade collapse>
                              <div className="top_card">
                                <div className="flex-user">
                                  <img src={Img} widht="50px" height="50px" />

                                  <div className="nameappoint">
                                    <h6 className="card_name_title">
                                      Json Boyd
                                    </h6>
                                    <p className="visitor">visitor</p>
                                  </div>
                                </div>
                                <div className="top_right_card">
                                  {" "}
                                  <span>
                                    <svg
                                      aria-hidden="true"
                                      role="img"
                                      className="iconify iconify--uis"
                                      width="15"
                                      height="15"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#1C0434"
                                        d="M2 19c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-8H2v8zM19 4h-2V3c0-.6-.4-1-1-1s-1 .4-1 1v1H9V3c0-.6-.4-1-1-1s-1 .4-1 1v1H5C3.3 4 2 5.3 2 7v2h20V7c0-1.7-1.3-3-3-3z"
                                      ></path>
                                    </svg>
                                    17-03-2021
                                  </span>{" "}
                                  <span>
                                    <svg
                                      aria-hidden="true"
                                      role="img"
                                      className="iconify iconify--mdi"
                                      width="15"
                                      height="15"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#1C0434"
                                        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2M7.7 15.5L7 14.2l4-2.3V7h1.5v5.8l-4.8 2.7Z"
                                      />
                                    </svg>
                                    10:30 AM
                                  </span>{" "}
                                </div>
                              </div>
                              <div className="bottom_cards">
                                <Card.Title className="head_text2">
                                  3 BHK Flat
                                </Card.Title>
                                <Card.Title className="light_text">
                                  <Card.Img
                                    style={{ width: 15, height: 15 }}
                                    variant="top"
                                    src={location}
                                  />{" "}
                                  301 Dort Walks,Stockton,California
                                </Card.Title>
                                <button className="appointment_btn">
                                  Cancel
                                </button>
                              </div>
                            </Fade>
                          </div>
                        )}
                        {user?.userType == "buyer" && (
                          <div className="appointment_card">
                            <Fade bottom cascade collapse>
                              <div className="bottom_cards border-0 pt-0">
                                <Card.Title className="head_text2">
                                  3 BHK Flat
                                </Card.Title>
                                <Card.Title className="light_text">
                                  <Card.Img
                                    style={{ width: 15, height: 15 }}
                                    variant="top"
                                    src={location}
                                  />{" "}
                                  301 Dort Walks,Stockton,California
                                </Card.Title>
                              </div>
                              <div className="bottom_cards top_right_card mt-3 pt-2 d-flex justify-content-between ">
                                <span>
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--uis"
                                    width="15"
                                    height="15"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#1C0434"
                                      d="M2 19c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-8H2v8zM19 4h-2V3c0-.6-.4-1-1-1s-1 .4-1 1v1H9V3c0-.6-.4-1-1-1s-1 .4-1 1v1H5C3.3 4 2 5.3 2 7v2h20V7c0-1.7-1.3-3-3-3z"
                                    ></path>
                                  </svg>
                                  <span className="pl-2"> 17-03-2021</span>
                                </span>
                                <span>
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--mdi"
                                    width="15"
                                    height="15"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#1C0434"
                                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2M7.7 15.5L7 14.2l4-2.3V7h1.5v5.8l-4.8 2.7Z"
                                    />
                                  </svg>
                                  <span className="pl-1">10:20 AM</span>
                                </span>
                                <span className="align-items-center">
                                  <span className="dot green"></span>
                                  <span className="pl-1 fw-6 c-black">
                                    {" "}
                                    Confirmed
                                  </span>
                                </span>
                              </div>
                              <div className="align-items-center d-flex mt-2">
                                <button className="buyer-outline mr-2">
                                  Cancel
                                </button>
                                <button
                                  className="buyer-reschedule-btn"
                                  onClick={openArrangeDialog}
                                >
                                  Reschedule
                                </button>
                              </div>
                            </Fade>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <p className="appointment_heading">Video Tour Viewings (3)</p>
              <div className="appointment_content">
                <ul>
                  {[1, 2, 3].map((d) => {
                    return (
                      <li>
                        <div className="appointment_card">
                          <Fade bottom cascade collapse>
                            <div className="top_card">
                              <div className="flex-user">
                                <img src={Img} widht="50px" height="50px" />

                                <div className="nameappoint">
                                  <h6 className="card_name_title">Json Boyd</h6>
                                  <p className="visitor">visitor</p>
                                </div>
                              </div>
                              <div className="top_right_card">
                                {" "}
                                <span>
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--uis"
                                    width="15"
                                    height="15"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#1C0434"
                                      d="M2 19c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-8H2v8zM19 4h-2V3c0-.6-.4-1-1-1s-1 .4-1 1v1H9V3c0-.6-.4-1-1-1s-1 .4-1 1v1H5C3.3 4 2 5.3 2 7v2h20V7c0-1.7-1.3-3-3-3z"
                                    ></path>
                                  </svg>
                                  17-03-2021
                                </span>{" "}
                                <span>
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--mdi"
                                    width="15"
                                    height="15"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#1C0434"
                                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2M7.7 15.5L7 14.2l4-2.3V7h1.5v5.8l-4.8 2.7Z"
                                    />
                                  </svg>
                                  10:30 AM
                                </span>{" "}
                              </div>
                            </div>
                            <div className="bottom_cards">
                              <Card.Title className="head_text2">
                                3 BHK Flat
                              </Card.Title>
                              <Card.Title className="light_text">
                                <Card.Img
                                  style={{ width: 15, height: 15 }}
                                  variant="top"
                                  src={location}
                                />{" "}
                                301 Dort Walks,Stockton,California
                              </Card.Title>
                              <button className="appointment_btn">
                                Cancel
                              </button>
                            </div>
                          </Fade>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <p className="appointment_heading">Services (3)</p>
              <div className="appointment_content">
                <ul>
                  {[1, 2, 3].map((d) => {
                    return (
                      <li>
                        <div className="appointment_card">
                          <Fade bottom cascade collapse>
                            <div className="top_card">
                              <div className="flex-user">
                                <img src={Img} widht="50px" height="50px" />

                                <div className="nameappoint">
                                  <h6 className="card_name_title">Json Boyd</h6>
                                  <p className="visitor">visitor</p>
                                </div>
                              </div>
                              <div className="top_right_card">
                                {" "}
                                <span>
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--uis"
                                    width="15"
                                    height="15"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#1C0434"
                                      d="M2 19c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-8H2v8zM19 4h-2V3c0-.6-.4-1-1-1s-1 .4-1 1v1H9V3c0-.6-.4-1-1-1s-1 .4-1 1v1H5C3.3 4 2 5.3 2 7v2h20V7c0-1.7-1.3-3-3-3z"
                                    ></path>
                                  </svg>
                                  17-03-2021
                                </span>{" "}
                                <span>
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--mdi"
                                    width="15"
                                    height="15"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#1C0434"
                                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2M7.7 15.5L7 14.2l4-2.3V7h1.5v5.8l-4.8 2.7Z"
                                    />
                                  </svg>
                                  10:30 AM
                                </span>{" "}
                              </div>
                            </div>
                            <div className="bottom_cards">
                              <Card.Title className="head_text2">
                                3 BHK Flat
                              </Card.Title>
                              <Card.Title className="light_text">
                                <Card.Img
                                  style={{ width: 15, height: 15 }}
                                  variant="top"
                                  src={location}
                                />{" "}
                                301 Dort Walks,Stockton,California
                              </Card.Title>
                              <button className="appointment_btn">
                                Cancel
                              </button>
                            </div>
                          </Fade>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* arrange view popup */}
        <Modal
          backdrop="static"
          centered={true}
          keyboard={false}
          className="login_modal login_modal2 slot-model"
          show={showArrange}
          onHide={handleArrangeClose}
        >
          <Modal.Header className="py-0">
            <Modal.Title className="text-center">Arrange Viewing</Modal.Title>
            <div className="text-center c-grey2">
              Select your slot preference
            </div>
          </Modal.Header>
          <Modal.Body>
           
            <div className="text-center">
              <div className="slot-btn active">
                <Form.Check id="slot2" name="slot" type="radio" />
                <label htmlFor="slot2" className="pl-2">
                  Slot 1
                </label>
              </div>
              <div className="slot-btn">
                <Form.Check id="slot3" type="radio" name="slot" />
                <label htmlFor="slot3" className="pl-2">
                  Slot 2
                </label>
              </div>
              <div className="slot-btn">
                <Form.Check id="slot1" type="radio" name="slot" />
                <label htmlFor="slot1" className="pl-2">
                  Slot 3
                </label>
              </div>
            </div>
            <div className="text-center pt-5 d-flex">
              <button
                className="theme-btn w-100 border mr-3"
                onClick={handleArrangeClose}
              >
                Cancel
              </button>
              <button
                className="theme-btn w-100 black"
                onClick={handleArrangeClose}
              >
                {" "}
                Send Request
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />
    </>
  );
}
