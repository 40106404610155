import React from "react";
import { Link } from "react-router-dom";

import Email from "assets/svg/email.svg";
import Lock from "assets/svg/lock.svg";

const Settingsidebar = () => {
  return (
    <section className="profile_left_side buyersetting-left">
      <div className="settig-sidebar">
        <h6>Setting</h6>
        <a href="#">
          <svg
            aria-hidden="true"
            role="img"
            className="iconify iconify--ion"
            width="30"
            height="30"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 512 512"
          >
            <path
              fill="#151415"
              d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z"
            />
          </svg>
        </a>
      </div>
      <div className="profile_menu-buyer">
        <ul>
          <div className="BTN-rnables">
            <Link to="#" className="menu_list">
              <li>
                <img src={Email} />
                <span>Email Notification</span>
              </li>
            </Link>
            <div className="enable-disable-btn">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <Link to="/sellerprofile/change-password" className="menu_list">
            <li>
              <img src={Lock} />
              <span>Change Password</span>
            </li>
          </Link>
        </ul>
      </div>
    </section>
  );
};

export default Settingsidebar;
