import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Cleanersmid from "./Cleanersmid";

import ScrollTop from "components/scrollTop";

const Cleaners = () => {
  return (
    <div>
      <ScrollTop />
      <Header />
      <Cleanersmid/>
      <Footer />
    </div>
  );
};

export default Cleaners;
