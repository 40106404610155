import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";
import * as CONST from "./actionType";
import * as apis from "backend_helper/api_helper";
import * as act from "./actions";
import { logInApi } from "backend_helper/api_helper";
import { SetLocalUser } from "store/localStorage";

function* signUp({ payload: { data, callback } }) {
  try {
    let response = yield call(apis.signUp, data);
    callback && callback(response);
    if (response.status == "failure") {
      yield put(act.signUpFail(response));
    }
    yield put(act.signUpSuccess());
  } catch (error) {
    yield put(act.signUpFail(error));
  }
}
function* logInSaga({ payload: { data, callback } }) {
  try {
    let response = yield call(logInApi, data);
    if (response?.error) {
      yield put(act.loginFail(response));
      return;
    }
    callback && callback(response);
    if (response?.data?.isSuccess) {
      SetLocalUser(response?.data?.data);
      localStorage.setItem("authUser", JSON.stringify(response?.data?.data));
    }
    // updateToken(response.data.Token);
    // navigate.push(`/`);
    console.log(response, "111111111");
    yield put(act.loginSuccess(response?.data));
  } catch (error) {
    console.log(error, "898998898989");
  }
}

function* onSendOtp({ payload: { data, callback } }) {
  try {
    let response = yield call(apis.sendOtpApi, data);
    callback && callback(response);
    if (response?.status == "failure") {
      yield put(act.sendOtpFail(response));
    }
    yield put(act.sendOtpSuccess(response));
  } catch (error) {
    yield put(act.sendOtpFail(error));
    console.log(error, "898998898989");
  }
}

function* onSendOtpVerify({ payload: { data, callback } }) {
  try {
    let response = yield call(apis.verifyOtpApi, data);
    callback && callback(response);
    if (response?.status == "failure") {
      yield put(act.sendOtpVerifyFail(response));
    }
    yield put(act.sendOtpVerifySuccess(response));
  } catch (error) {
    yield put(act.sendOtpVerifyFail(error));
    console.log(error, "898998898989");
  }
}

export default function* signUpSaga() {
  yield takeEvery(CONST.LOGIN_USER, logInSaga);
  yield takeEvery(CONST.SIGNUP, signUp);
  yield takeEvery(CONST.SEND_OTP, onSendOtp);
  yield takeEvery(CONST.SEND_OTP_VERIFY, onSendOtpVerify);
}
