import React, { Component, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Popover,
  OverlayTrigger,
  ModalHeader,
} from "react-bootstrap";
import { Input, Form, FormGroup } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import Overlay from "react-bootstrap/Overlay";

// imges
import Muser from "assets/images/m_userimg.png";
import Spacejoy from "assets/images/spacejoy.png";

class Inboxboard extends Component {
  state = {
    data: {},
    exploreData: [],
    tab: "tab1",
  };
  render() {
   
const showSolicitor = () => {
     
  };
   
    return (
      <section className="inbox-dashboard">
        <Container>
          <div className="common-head text-left">
            <h2>Inbox</h2>
          </div>

          <Row>
            <Col lg={12}>
              <div className="inbox-board-main">
                <div className="inbox-board-sidebar mr-3">
                  <Form className="search-inputleft-side position-relative">
                    <FormGroup className="formcol-grp">
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="Search People"
                      />
                      <div className="search-icon" style={{ top: 10 }}>
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ion"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M344.5 298c15-23.6 23.8-51.6 23.8-81.7 0-84.1-68.1-152.3-152.1-152.3C132.1 64 64 132.2 64 216.3c0 84.1 68.1 152.3 152.1 152.3 30.5 0 58.9-9 82.7-24.4l6.9-4.8L414.3 448l33.7-34.3-108.5-108.6 5-7.1zm-43.1-166.8c22.7 22.7 35.2 52.9 35.2 85s-12.5 62.3-35.2 85c-22.7 22.7-52.9 35.2-85 35.2s-62.3-12.5-85-35.2c-22.7-22.7-35.2-52.9-35.2-85s12.5-62.3 35.2-85c22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2z"
                            fill="#cccccc"
                          />
                        </svg>
                      </div>
                    </FormGroup>
                  </Form>
                  <div className="middle-sidebar-left pe-0">
                    <div className="tabbing-treand">
                      <div className="tab-frame collection-frame">
                        <div className="clearfix tabing-clear">
                          <input
                            type="radio"
                            name="tab"
                            id="tab1"
                            checked={this.state.tab === "tab1"}
                            onClick={() => this.setState({ tab: "tab1" })}
                          />
                          <label for="tab1">Recent</label>

                          <input
                            type="radio"
                            name="tab"
                            id="tab2"
                            checked={this.state.tab === "tab2"}
                            onClick={() => this.setState({ tab: "tab2" })}
                          />
                          <label for="tab2">Unread</label>

                          <input
                            type="radio"
                            name="tab"
                            id="tab3"
                            checked={this.state.tab === "tab3"}
                            onClick={() => this.setState({ tab: "tab3" })}
                          />
                          <label for="tab3">Groups</label>
                        </div>

                        {this.state.tab === "tab1" && (
                          <div className="recent-online">
                            <a href="#" className="m_personn message-acitve">
                              <div className="card-body recent-online-card">
                                <div className="figure-part">
                                  <figure className="avatar me-3 position-relative">
                                    <img
                                      src={Muser}
                                      className="shadow-sm rounded-circle w45"
                                    />
                                    <div className="green-dot"></div>
                                  </figure>
                                  <div className="cont-unser">
                                    <h4 className="user-name">Michael Wong </h4>
                                    <span className="user-tagline">
                                      Yeah, we all loved it!
                                    </span>
                                  </div>
                                </div>

                                <div className="dot-time">
                                  <div className="ofline-timedot"></div>
                                  <p>7:47 PM</p>
                                </div>
                              </div>
                            </a>

                            <a href="#" className="m_personn">
                              <div className="card-body recent-online-card">
                                <div className="figure-part">
                                  <figure className="avatar me-3 position-relative">
                                    <img
                                      src={Muser}
                                      className="shadow-sm rounded-circle w45"
                                    />
                                    <div className="ofline-dot"></div>
                                  </figure>
                                  <div className="cont-unser">
                                    <h4 className="user-name">Gus Fring</h4>
                                    <span className="user-tagline">
                                      We need to re-asses our strategy.
                                    </span>
                                  </div>
                                </div>

                                <div className="dot-time">
                                  <div className="ofline-timedot"></div>
                                  <p>Oct 31</p>
                                </div>
                              </div>
                            </a>

                            <a href="#" className="m_personn position-relative">
                              <div className="card-body recent-online-card">
                                <div className="figure-part">
                                  <figure className="avatar me-3 position-relative">
                                    <img
                                      src={Muser}
                                      className="shadow-sm rounded-circle w45"
                                    />
                                    <div className="ofline-dot"></div>
                                  </figure>
                                  <div className="cont-unser">
                                    <h4 className="user-name">Walter White</h4>
                                    <span className="user-tagline">
                                      Sometimes, it's better not to talk.
                                    </span>
                                  </div>
                                </div>

                                <div className="dot-time">
                                  <div className="ofline-timedot"></div>
                                  <p>Oct 30</p>
                                </div>
                              </div>

                              <div className="pink-dot"></div>
                            </a>

                            <a href="#" className="m_personn position-relative">
                              <div className="card-body recent-online-card">
                                <div className="figure-part">
                                  <figure className="avatar me-3 position-relative">
                                    <div className="message-show">2</div>
                                    <img
                                      src={Muser}
                                      className="shadow-sm rounded-circle w45"
                                    />
                                    <div className="ofline-dot"></div>
                                  </figure>
                                  <div className="cont-unser">
                                    <h4 className="user-name">Walter White</h4>
                                    <span className="user-tagline">
                                      Sometimes, it's better not to talk.
                                    </span>
                                  </div>
                                </div>

                                <div className="dot-time">
                                  <div className="ofline-timedot"></div>
                                  <p>Oct 30</p>
                                </div>
                              </div>

                              <div className="pink-dot"></div>
                            </a>
                          </div>
                        )}

                        {this.state.tab === "tab2" && (
                          <div className="unread-msg">
                            <a href="#" className="m_personn message-acitve">
                              <div className="card-body recent-online-card">
                                <div className="figure-part">
                                  <figure className="avatar me-3 position-relative">
                                    <img
                                      src={Muser}
                                      className="shadow-sm rounded-circle w45"
                                    />
                                    <div className="green-dot"></div>
                                  </figure>
                                  <div className="cont-unser">
                                    <h4 className="user-name">Michael Wong </h4>
                                    <span className="user-tagline">
                                      Yeah, we all loved it!
                                    </span>
                                  </div>
                                </div>

                                <div className="dot-time">
                                  <div className="ofline-timedot"></div>
                                  <p>7:47 PM</p>
                                </div>
                              </div>
                            </a>
                          </div>
                        )}
                        {this.state.tab === "tab3" && (
                          <div className="groups">
                            {" "}
                            <a href="#" className="m_personn message-acitve">
                              <div className="card-body recent-online-card">
                                <div className="figure-part">
                                  <figure className="avatar me-3 position-relative">
                                    <img
                                      src={Muser}
                                      className="shadow-sm rounded-circle w45"
                                    />
                                    <div className="green-dot"></div>
                                  </figure>
                                  <div className="cont-unser">
                                    <h4 className="user-name">Groups </h4>
                                    <span className="user-tagline">
                                      Yeah, we all loved it!
                                    </span>
                                  </div>
                                </div>

                                <div className="dot-time">
                                  <div className="ofline-timedot"></div>
                                  <p>7:47 PM</p>
                                </div>
                              </div>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inbox-board-chatscreen">
                  <div className="chatscreen-header">
                    <div className="figure-part">
                      <figure className="avatar me-3 position-relative">
                        <img
                          src={Muser}
                          className="shadow-sm rounded-circle w45"
                        />
                        <div className="green-dot"></div>
                      </figure>
                      <div className="cont-unser">
                        <h4 className="user-name">Michael Wong</h4>
                      </div>
                    </div>
                    <div className="callvedio-icons">
                      <a href="javascripr;void(0)">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ic"
                          width="18"
                          height="18"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02l-2.2 2.2z"
                          />
                        </svg>
                      </a>
                      <a href="javascripr;void(0)">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ic"
                          width="18"
                          height="18"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>

                  {/* chat body */}
                  <div className="chat-body">
                    <div className="message-send-main clearfix">
                      <p className="box-message-timing">Today, 8:19 PM</p>

                      <div className="message-bg">
                        <div className="the-massage-bg">
                          <p>
                            Lorem Ipsum Dolor Sit Amet, Coeteturrey Sadipscing
                            Elitr, Sed Diam Numy Eirmod Tempor Invidunt Abore Et
                            Dolore Magna Aliquyam Erat.
                          </p>
                        </div>
                        <div className="offers-send">
                          <div className="offers-send-div">
                            <div className="property-img w-25 me-1">
                              <img src={Spacejoy} className="img-fluid w-100" />
                            </div>
                            <div className="property-img-cont">
                              <h3>3 BHK Flat/Apartment for rent</h3>
                              <p>
                                <span>£ 150</span>/month
                              </p>
                            </div>
                          </div>
                          <Button className="btn-blue mt-3">
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--mdi mr-2"
                              width="28"
                              height="28"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="m10 17l-5-5l1.41-1.42L10 14.17l7.59-7.59L19 8m0-5H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
                              />
                            </svg>
                            Offer Sent
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="message-reply mt-4">
                      <p className="box-message-timing">Today, 8:19 PM</p>

                      <div className="my-rely-box">
                        <figure className="avatar me-3 position-relative">
                          <img
                            src={Muser}
                            className="shadow-sm rounded-circle w45"
                          />
                          <div className="green-dot"></div>
                        </figure>

                        <div className="left-reply-offer">
                          <div className="message-bg">
                            <div className="the-massage-bg">
                              <p>
                                Lorem Ipsum Dolor Sit Amet, Coeteturrey
                                Sadipscing Elitr, Sed Diam Numy Eirmod Tempor
                                Invidunt Abore Et Dolore Magna Aliquyam Erat.
                              </p>
                            </div>
                            <div className="offers-send">
                              <div className="offers-send-div">
                                <div className="property-img w-25 me-1">
                                  <img
                                    src={Spacejoy}
                                    className="img-fluid w-100"
                                  />
                                </div>
                                <div className="property-img-cont">
                                  <h3>3 BHK Flat/Apartment for rent</h3>
                                  <p>
                                    <span>£ 150</span>/month
                                  </p>
                                </div>
                              </div>
                              <div className="two-btn-oofer mt-3 d-flex">
                                <Button className="reject-bnt">
                                  Reject Offer
                                </Button>
                                <Button
                                  className="accept-btn"
                                  onClick={showSolicitor}
                                >
                                  Accept Offer
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="font-11 c-grey col-12 text-center pt-4">
                      Michael Wong is typing...
                    </div>
                  </div>
                  {/* chat body */}

                  <div className="message-footer">
                    <div className="input-form-main pr-4">
                      <Form className="typing-message position-relative">
                        <FormGroup>
                          <Input
                            type="email"
                            name="email"
                            id="exampleEmail"
                            placeholder="Write your message"
                          />
                        </FormGroup>

                        <div className="icon-attached">
                          <OverlayTrigger
                            trigger="click"
                            placement="top"
                            overlay={popover}
                          >
                            <a href="#">
                              <div className="thunder-icon">
                                <svg
                                  aria-hidden="true"
                                  role="img"
                                  className="iconify iconify--ant-design"
                                  width="32"
                                  height="32"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 1024 1024"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M848 359.3H627.7L825.8 109c4.1-5.3.4-13-6.3-13H436c-2.8 0-5.5 1.5-6.9 4L170 547.5c-3.1 5.3.7 12 6.9 12h174.4l-89.4 357.6c-1.9 7.8 7.5 13.3 13.3 7.7L853.5 373c5.2-4.9 1.7-13.7-5.5-13.7z"
                                  />
                                </svg>
                              </div>
                            </a>
                          </OverlayTrigger>

                          <a href="#" className="with-bg">
                            <div className="send-icon">
                              <svg
                                width="17.431"
                                height="17.441"
                                viewBox="0 0 17.431 17.441"
                              >
                                <path
                                  id="Path_3652"
                                  data-name="Path 3652"
                                  d="M1010.955,223.508a1.7,1.7,0,0,0-1.692-.436l-14.034,4.082a1.674,1.674,0,0,0-1.207,1.326,1.965,1.965,0,0,0,.872,1.831l4.389,2.7a1.139,1.139,0,0,0,1.4-.168l5.023-5.058a.639.639,0,0,1,.924,0,.666.666,0,0,1,0,.931l-5.032,5.058a1.145,1.145,0,0,0-.166,1.411l2.677,4.434a1.665,1.665,0,0,0,1.448.825,1.829,1.829,0,0,0,.218-.009,1.7,1.7,0,0,0,1.421-1.211l4.169-14.02a1.714,1.714,0,0,0-.416-1.695Z"
                                  transform="translate(-994.013 -223.002)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </Form>
                    </div>
                    <div className="btn-crete-offer">
                      <Button className="create-oofer">Create offer</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

       
      </section>
    );
  }
}

const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <h3>Use a Quick Response</h3>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet.
      </p>
      <a href="#" className="quick-response">
        Create a Quick Response
      </a>
    </Popover.Body>
  </Popover>
);

export default Inboxboard;
