import React from "react";
import { useState, useEffect } from "react";

import { Container } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import LoginModal from "components/authentication/Login";
import SignUp from "components/authentication/Signup";
import Forgotpassword from "components/authentication/Forgotpassword";
import Verification from "components/authentication/Verification";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GetLocalUser from "store/localStorage";

const Homesearch = ({ showBuyerHde }) => {
  const [show, setShow] = useState(false);
  const [currentId, setcurrentId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showsignup, setShowsignup] = useState(false);
  const handleClose2 = () => setShowsignup(false);
  const handleShow2 = () => setShowsignup(true);

  const [showspass, setShowpass] = useState(false);
  const handleClose3 = () => setShowpass(false);
  const handleShow3 = () => setShowpass(true);

  const [show55, setShow55] = useState(false);
  const handleClose55 = () => setShow55(false);
  const handleShow55 = () => setShow55(true);

  const [showsOTP, setShowOTP] = useState(false);
  const handleClose4 = () => setShowOTP(false);
  const handleShow4 = () => setShowOTP(true);
  const loginData = useSelector((state) => state);
  let history = useHistory();

  const goSell = () => {
    history.push("/step1");
  };
  const goToSearch = () => {
    history.push("/search-filter");
  };
  const onSignClose = () => {
    console.log('signup close');
    handleShow4()
  }
  const handleVerification = () => {
    history.push("/seller-welcome");
    handleClose();
  };
  const user = GetLocalUser();

  return (
    <div>
      <LoginModal
        handleClose={handleClose55}
        handleShow={handleShow55}
        show={show55}
      />

      <Verification
        show={showsOTP}
        handleClose3={handleClose4}
        handleShow3={handleShow4}
        showsOTP={showsOTP}
        currentId={currentId}
        closeSignup={handleVerification}
      />

      <Forgotpassword
        show={showspass}
        handleClose3={handleClose3}
        handleShow3={handleShow3}
        showspass={showspass}
      />

      <SignUp
        show={showsignup}
        handleClose2={handleClose2}
        handleShow2={handleShow2}
        showsignup={showsignup}
        handleShow={handleShow55}
        onSignupClose={onSignClose}
        setcurrentId={setcurrentId}
      />
      <section className="banner-search">
        {showBuyerHde && (
          <p className="buyer_home_head">
            The property platform where you control everything
          </p>
        )}
        <Container>
          <Form className="search-input position-relative">
            <FormGroup>
              <div className="searchicon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Group_18715"
                  data-name="Group 18715"
                  width="27.398"
                  height="33.631"
                  viewBox="0 0 27.398 33.631"
                >
                  <path
                    id="Path_5960"
                    data-name="Path 5960"
                    d="M164.174,414.136A1.174,1.174,0,0,1,163,412.962v-4.949a1.174,1.174,0,1,1,2.348,0v4.949A1.174,1.174,0,0,1,164.174,414.136Z"
                    transform="translate(-150.476 -380.505)"
                    fill="#001878"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_5961"
                    data-name="Path 5961"
                    d="M166.472,392.338a5.206,5.206,0,0,0-5.185,5.215v7.339a5.185,5.185,0,0,0,10.37,0v-7.339a5.2,5.2,0,0,0-5.184-5.215Zm0,20.116a7.557,7.557,0,0,1-7.534-7.562v-7.339a7.534,7.534,0,0,1,15.067,0v7.339A7.555,7.555,0,0,1,166.472,412.454Z"
                    transform="translate(-152.772 -390.019)"
                    fill="#001878"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_5962"
                    data-name="Path 5962"
                    d="M168.7,413.733A13.744,13.744,0,0,1,155,399.975a1.174,1.174,0,0,1,2.348,0,11.35,11.35,0,0,0,22.7,0,1.174,1.174,0,0,1,2.348,0,13.744,13.744,0,0,1-13.7,13.758Z"
                    transform="translate(-155 -385.052)"
                    fill="#001878"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_5963"
                    data-name="Path 5963"
                    d="M169.359,397.1h-3.116a1.174,1.174,0,1,1,0-2.348h3.116a1.174,1.174,0,0,1,0,2.348Z"
                    transform="translate(-149.305 -387.339)"
                    fill="#001878"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_5964"
                    data-name="Path 5964"
                    d="M169.926,400.442h-4.681a1.174,1.174,0,0,1,0-2.348h4.681a1.174,1.174,0,0,1,0,2.348Z"
                    transform="translate(-149.869 -385.451)"
                    fill="#001878"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <Input
                type="email"
                name="email"
                id="banner-search"
                placeholder="Speak or type your property search"
              />
              {!user?.id && (
                <div className="btnsgorup">
                  <Button className="btn-buy" onClick={handleShow55}>
                    Buy
                  </Button>
                  <Button className="btn-sell" onClick={handleShow55}>
                    Sell
                  </Button>
                </div>
              )}
              {user?.id && (
                <div className="btnsgorup">
                  <Button className="btn-buy" onClick={goToSearch}>
                    Buy
                  </Button>
                  <Button className="btn-sell" onClick={goSell}>
                    Sell
                  </Button>
                </div>
              )}
            </FormGroup>
          </Form>
        </Container>
      </section>
    </div>
  );
};

export default Homesearch;
