import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup, CloseButton } from "react-bootstrap";


export default function Modalpayment({ show, handleShow, handleClose }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered={true}
        keyboard={false}
        className="payment-modal"
      >
        <Modal.Header>
          
          <p className="m-0 close_btn " onClick={handleClose}>
            <CloseButton variant="white" disabled={false} />
          </p>
        </Modal.Header>
        <Modal.Body>
           
        <div className="payment-modal-body text-center">
             <div className="div-icon-one">
             <div className="div-icon-two">
             <svg aria-hidden="true" role="img" className="iconify iconify--material-symbols" width="100" height="100" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#ffffff" d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"/></svg>
                 </div> 
             </div>
            <h4>Payment Successful</h4>
            <p>Lorem Ipsum Dolor Sit Amet, Coetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod.</p>
        </div>
        </Modal.Body>
      
      </Modal>
    </>
  );
}
