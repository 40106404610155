import React from "react";
import Header from "components/Header";
import Logo from "assets/images/logo2.png";
import Footer from "components/Footer";
import Sidebar from "../Sidebar";
import ScrollTop from "components/scrollTop";
import { Container } from "react-bootstrap";

export default function Notification() {
  return (
    <>
      <ScrollTop />
      <Header />
      <Container>
      <div className="seller_container">
        <Sidebar />
        <div className="profile_right_side">
          <div className="d-flex justify-content-between">
            <h4 className="dashboard_text">Notifications</h4>
          </div>
          <div className="notification-list">
            <ul>
              <li>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero
                </p>
                <span className="min-con">2 min ago</span>
              </li>
              <li>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero
                </p>
                <span className="min-con">2 min ago</span>
              </li>
              <li>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero
                </p>
                <span className="min-con">2 min ago</span>
              </li>
              <li>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero
                </p>
                <span className="min-con">2 min ago</span>
              </li>
              <li>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero
                </p>
                <span className="min-con">2 min ago</span>
              </li>
              <li>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero
                </p>
                <span className="min-con">2 min ago</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </Container>
      
      <Footer />
    </>
  );
}
