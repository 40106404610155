import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useHistory } from "react-router-dom";
import Propertyone from "assets/images/main.jpg";
import ScrollTop from "components/scrollTop";

export default function Areaprice() {
  const history = useHistory();
  return (
    <>
      <ScrollTop />
      <Header />
      <section className="common-steps-main">
        <Container>
          <div className="areaprice-main">
            <a href="#">
              <svg
                role="img"
                className="iconify iconify--ic"
                width="20"
                height="20"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z"
                />
              </svg>
              Back
            </a>

            <div className="heading-part-main">
              <div className="heading-area">
                <h4>House prices sold in this area</h4>
                <div className="drops-area">
                  <Form>
                    <FormGroup className="position-relative">
                      <Input type="select" name="select" id="exampleSelect">
                        <option>From Last 1 Year</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                      <div className="caret">
                        <svg
                          role="img"
                          className="iconify iconify--material-symbols"
                          width="18"
                          height="18"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4Z"
                          />
                        </svg>
                      </div>
                    </FormGroup>
                    <FormGroup className="position-relative">
                      <Input type="select" name="select" id="exampleSelect">
                        <option>Within 1/4 Miles</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                      <div className="caret">
                        <svg
                          role="img"
                          className="iconify iconify--material-symbols"
                          width="18"
                          height="18"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fff"
                            d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4Z"
                          />
                        </svg>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>

              <div className="aeeaa-boxss">
                <Row>
                  {[1, 2, 3, 4, 5, 6].map(() => {
                    return (
                      <Col lg={6} m={6} sm={12} xs={12}>
                        <div className="areapart-main">
                          <div className="ima-area">
                            <img
                              src={Propertyone}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="contet-area">
                            <div className="dateprice-area">
                              <h3>£ 150,000,00</h3>
                              <p>
                                <svg
                                  aria-hidden="true"
                                  role="img"
                                  className="iconify iconify--uis"
                                  width="20"
                                  height="20"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M2 19c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-8H2v8zM19 4h-2V3c0-.6-.4-1-1-1s-1 .4-1 1v1H9V3c0-.6-.4-1-1-1s-1 .4-1 1v1H5C3.3 4 2 5.3 2 7v2h20V7c0-1.7-1.3-3-3-3z"
                                  />
                                </svg>
                                17-03-2021
                              </p>
                            </div>

                            <div className="mt-3">
                              <p>
                                <strong>
                                  23,Rydal Road, Bolton, Greater Manchester BL1
                                  5LQ
                                </strong>
                              </p>
                              <p>3 bed, Semi-detached • Leasehold</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}
