import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

// images
import Vedioimg from "assets/images/vedio-img.jpg";

const Modalstatus = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="status-vedio-col"
      >
        <div className="close-icon">
          <a href="">
            <svg
              aria-hidden="true"
              role="img"
              className="iconify iconify--ion"
              width="22"
              height="22"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 33C132.3 33 32 133.3 32 257s100.3 224 224 224 224-100.3 224-224S379.7 33 256 33zm108.3 299.5c1.5 1.5 2.3 3.5 2.3 5.6 0 2.1-.8 4.2-2.3 5.6l-21.6 21.7c-1.6 1.6-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.6-2.3L256 289.8l-75.4 75.7c-1.5 1.6-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6 0-2.1.8-4.2 2.3-5.6l75.7-76-75.9-75c-3.1-3.1-3.1-8.2 0-11.3l21.6-21.7c1.5-1.5 3.5-2.3 5.6-2.3 2.1 0 4.1.8 5.6 2.3l75.7 74.7 75.7-74.7c1.5-1.5 3.5-2.3 5.6-2.3 2.1 0 4.1.8 5.6 2.3l21.6 21.7c3.1 3.1 3.1 8.2 0 11.3l-75.9 75 75.6 75.9z"
                fill="#000000"
              />
            </svg>
          </a>
        </div>
        <Modal.Body className="status-modal-main">
          <div className="status-modal">
            <h5 className="fw-7">Current status</h5>
            <p>Please select your current status</p>

            <Form>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> First time buyer
                 
                </Label>
              </FormGroup>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> Current Owner
                 
                </Label>
              </FormGroup>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> Investor
                 
                </Label>
              </FormGroup>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> Builder
                 
                </Label>
              </FormGroup>
            </Form>
            <Button className="update-password">Save status</Button>
          </div>
        </Modal.Body>

        {/* <Modal.Body className="status-modal-maintwo">
          <div className="status-modaltwo">
          <Form className="mt-4">
            <h5>Do you need to sell your current home to buy a new one?</h5>
           
           <div className="checkinline">
            <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> Yes
                 
                </Label>
              </FormGroup>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> No
                 
                </Label>
              </FormGroup>
              </div>
           
           <div className="mt-4">
              <h5>Current house status</h5>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> Needs to go on the market
                 
                </Label>
              </FormGroup>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> My house is on the market no offers accepted
                 
                </Label>
              </FormGroup>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> My house is on the market and offer is accepted
                 
                </Label>
              </FormGroup>
              <FormGroup check className="for-bg-col">
                <Label check>
                  <Input type="radio" name="radio1" /> We have exchanged contracts and waiting to complete
                 
                </Label>
              </FormGroup>
              </div>

            </Form>
            <Button className="update-password">Save status</Button>
          </div>
        </Modal.Body>

        <Modal.Body className="Builder-modal-maintwo Builder-main">
          <div className="Builder-modaltwo">
            <Form className="mt-4">
              <h5>Builder details</h5>

              <div className="Builder-input">
                <FormGroup className="position-relative">
                  <Label for="exampleEmail">
                    Please enter postcodes of interest
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Postal Code here"
                  />
                  <div className="location-ico">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--ion"
                      width="18"
                      height="18"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="#636363"
                        d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144Zm0 224a64 64 0 1 1 64-64a64.07 64.07 0 0 1-64 64Z"
                      />
                    </svg>
                  </div>
                </FormGroup>
                <div className="fillters">
                  <div className="filters-tag">
                    <p>PO16 7GZ</p>
                    <a href="#">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ion"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className="filters-tag">
                    <p>PO16 7GZ</p>
                    <a href="#">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ion"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="selection-main mt-4">
                  <h6>Select Radius ­- Within a radius</h6>
                  <div className="newselect mb-3 builder-checks">
                    <label className="newselectyes lebel-yes" for="foryesnN">
                      <input checked type="radio" name="plan" id="foryesnN" />
                      <div className="plan-yesno">
                        <span>1 Mile</span>
                      </div>
                    </label>

                    <label className="newselectyes lebel-no" for="fornonN">
                      <input checked type="radio" id="fornonN" name="plan" />
                      <div className="plan-yesno">
                        <span>3 Mile</span>
                      </div>
                    </label>

                    <label className="offrovervv lebel-yes" for="offrover">
                      <input checked type="radio" name="plan" id="offrover" />
                      <div className="plan-yesno">
                        <span>3 Mile</span>
                      </div>
                    </label>

                    <label className="Offersivtvv lebel-no" for="Offersivt">
                      <input checked type="radio" id="Offersivt" name="plan" />
                      <div className="plan-yesno">
                        <span>10 Mile</span>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="selection-main mt-4">
                  <h6>State property condition</h6>
                  <div className="newselect mb-3 builder-checks">
                    <label className="newselectyes lebel-yes" for="Repairs">
                      <input checked type="radio" name="plan" id="Repairs" />
                      <div className="plan-yesno">
                        <span>Repairs needed</span>
                      </div>
                    </label>

                    <label className="newselectyes lebel-no" for="Good">
                      <input checked type="radio" id="Good" name="plan" />
                      <div className="plan-yesno">
                        <span>Good</span>
                      </div>
                    </label>

                    <label className="offrovervv lebel-yes" for="Excellent">
                      <input checked type="radio" name="plan" id="Excellent" />
                      <div className="plan-yesno">
                        <span>Excellent</span>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="iterest-check">
                  <h6>Interest Type</h6>
                  <FormGroup check>
                    <Label check>
                    <Input type="checkbox" />
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Sed beatae tempore iure!
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" /> Lorem ipsum dolor, sit amet
                      consectetur adipisicing elit. Sed beatae tempore iure!
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Form>
            <Button className="update-password">Save status</Button>
          </div>
        </Modal.Body> */}
      </Modal>
    </div>
  );
};

export default Modalstatus;
