import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Epcmid from "pages/EPC/Epcmid";
import ScrollTop from "components/scrollTop";

const Epccol = () => {
  return (
    <div>
      <ScrollTop />
      <Header />
      <Epcmid />
      <Footer />
    </div>
  );
};

export default Epccol;
