import React, { useState } from "react";

// images
import Main from "assets/images/main.jpg";

const Buydashboard = () => {
  const [state, setState] = useState({
    tab: "tab1",
  });
  return (
    <section className="profile_right_side buyersetting-right">
      <div className="tab-frame buyer-setting">
        <div className="clearfix tabing-clear">
          <input
            type="radio"
            name="tab"
            id="tab1"
            checked={state.tab == "tab1"}
            onClick={() => setState({ tab: "tab1" })}
          />
          <label for="tab1">Saved Properties</label>

          <input
            type="radio"
            name="tab"
            id="tab2"
            checked={state.tab == "tab2"}
            onClick={() => setState({ tab: "tab2" })}
          />
          <label for="tab2">Saved Searches</label>
        </div>

        {state.tab === "tab1" && (
          <div className="Saved-online">
            {[1, 2, 3].map((d) => {
              return (
                <div className="cover-upp">
                  <div className="saved-proeprty">
                    <div className="imgw-left">
                      <img src={Main} alt="" className="img-fluid" />
                    </div>
                    <div className="cont-right">
                      <div className="propety-box-saved">
                        <div className="heart-main">
                          <h6>3 BHK FLAT FOR SALE</h6>
                          <div className="three-iocn-saved">
                            <div className="heart-col">
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--ph"
                                width="20"
                                height="20"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 256 256"
                              >
                                <path
                                  fill="currentColor"
                                  d="M128 224a7.8 7.8 0 0 1-3.9-1C119.8 220.6 20 163.9 20 92a60 60 0 0 1 108-36a60 60 0 0 1 108 36c0 30.6-17.7 62-52.6 93.4a314.3 314.3 0 0 1-51.5 37.6a7.8 7.8 0 0 1-3.9 1Zm-3.9-15ZM80 48a44 44 0 0 0-44 44c0 55.2 74 103.7 92 114.7c18-11 92-59.5 92-114.7a44 44 0 0 0-84.6-17a8 8 0 0 1-14.8 0A43.8 43.8 0 0 0 80 48Z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="box-one-price-saved">
                          <h3 className="green-big">$ 150,000,00</h3>
                        </div>
                        <div className="flat-details">
                          <h4>3 BHK Flat</h4>
                        </div>
                        <div className="location-icon">
                          <p>
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--ic"
                              width="24"
                              height="24"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#3399FF"
                                d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                              ></path>
                            </svg>
                            4571 Giraffe Hill Drive, Frisco, Texas, 75034
                          </p>
                          <div className="viewers">
                            <p>
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--ion"
                                width="18"
                                height="18"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 512 512"
                              >
                                <circle
                                  cx="256"
                                  cy="256"
                                  r="64"
                                  fill="#9D9C9C"
                                />
                                <path
                                  fill="#9D9C9C"
                                  d="M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96c-42.52 0-84.33 12.15-124.27 36.11c-40.73 24.43-77.63 60.12-109.68 106.07a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416c46.71 0 93.81-14.43 136.2-41.72c38.46-24.77 72.72-59.66 99.08-100.92a32.2 32.2 0 0 0-.1-34.76ZM256 352a96 96 0 1 1 96-96a96.11 96.11 0 0 1-96 96Z"
                                />
                              </svg>
                              566 views
                            </p>
                            <p>
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--ion"
                                width="18"
                                height="18"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#9D9C9C"
                                  d="M256 448a32 32 0 0 1-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8c-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 0 0 9.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64c.54 54.21-18.63 104.26-58.61 153c-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 0 1-18 5.56Z"
                                />
                              </svg>
                              11 Save
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {state.tab === "tab2" && (
          <div className="Saved-online">
            {state.tab === "tab2" && (
              <div className="Saved-online">
                {[1, 2, 3].map((d) => {
                  return (
                    <div className="cover-upp">
                      <div className="saved-proeprty">
                        <div className="imgw-left">
                          <img src={Main} alt="" className="img-fluid" />
                        </div>
                        <div className="cont-right">
                          <div className="propety-box-saved">
                            <div className="heart-main">
                              <h6>3 BHK FLAT FOR SALE</h6>
                              <div className="three-iocn-saved">
                                <div className="heart-col">
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--ph"
                                    width="20"
                                    height="20"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 256 256"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M128 224a7.8 7.8 0 0 1-3.9-1C119.8 220.6 20 163.9 20 92a60 60 0 0 1 108-36a60 60 0 0 1 108 36c0 30.6-17.7 62-52.6 93.4a314.3 314.3 0 0 1-51.5 37.6a7.8 7.8 0 0 1-3.9 1Zm-3.9-15ZM80 48a44 44 0 0 0-44 44c0 55.2 74 103.7 92 114.7c18-11 92-59.5 92-114.7a44 44 0 0 0-84.6-17a8 8 0 0 1-14.8 0A43.8 43.8 0 0 0 80 48Z"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="box-one-price-saved">
                              <h3 className="green-big">$ 150,000,00</h3>
                            </div>
                            <div className="flat-details">
                              <h4>3 BHK Flat</h4>
                            </div>
                            <div className="location-icon">
                              <p>
                                <svg
                                  aria-hidden="true"
                                  role="img"
                                  className="iconify iconify--ic"
                                  width="24"
                                  height="24"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#3399FF"
                                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                                  ></path>
                                </svg>
                                4571 Giraffe Hill Drive, Frisco, Texas, 75034
                              </p>
                              <div className="viewers">
                                <p>
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--ion"
                                    width="18"
                                    height="18"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 512 512"
                                  >
                                    <circle
                                      cx="256"
                                      cy="256"
                                      r="64"
                                      fill="#9D9C9C"
                                    />
                                    <path
                                      fill="#9D9C9C"
                                      d="M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96c-42.52 0-84.33 12.15-124.27 36.11c-40.73 24.43-77.63 60.12-109.68 106.07a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416c46.71 0 93.81-14.43 136.2-41.72c38.46-24.77 72.72-59.66 99.08-100.92a32.2 32.2 0 0 0-.1-34.76ZM256 352a96 96 0 1 1 96-96a96.11 96.11 0 0 1-96 96Z"
                                    />
                                  </svg>
                                  566 views
                                </p>
                                <p>
                                  <svg
                                    aria-hidden="true"
                                    role="img"
                                    className="iconify iconify--ion"
                                    width="18"
                                    height="18"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="#9D9C9C"
                                      d="M256 448a32 32 0 0 1-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8c-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 0 0 9.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64c.54 54.21-18.63 104.26-58.61 153c-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 0 1-18 5.56Z"
                                    />
                                  </svg>
                                  11 Save
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Buydashboard;
