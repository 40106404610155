export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

export const SEND_OTP_VERIFY = "SEND_OTP_VERIFY";
export const SEND_OTP_VERIFY_SUCCESS = "SEND_OTP_VERIFY_SUCCESS";
export const SEND_OTP_VERIFY_FAIL = "SEND_OTP_VERIFY_FAIL";

export const LOGIN_USER = "LOGIN_USER";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const API_ERROR = "LOGIN_API_ERROR";

export const UPDATE_TOKEN2 = "UPDATE_TOKEN2";
