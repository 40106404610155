import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "./Sidebar";
import Dashboard from "./Buyer/Dashboard";
import { Switch, Route } from "react-router";
import ScrollTop from "components/scrollTop";
import { Container } from "react-bootstrap";

export default function Profile() {
  return (
    <>
      <ScrollTop />
      <Header />

      <Container>
      <div className="seller_container">
        <Sidebar />
        <Dashboard />
      </div>
      </Container>
     
      <Footer />
    </>
  );
}
