import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Inboxboard from "./inboxboard";
import ScrollTop from "components/scrollTop";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

const Inbox = () => {
  const [showSolicitorConfirmation, setshowArrangeSolicitorConfirmation] =
    useState();

  // const showSolicitor = () => {
  //   setshowArrangeSolicitorConfirmation(true);
  // };
  const handleSolicitorClose = () => {};
  return (
    <div>
      <ScrollTop />
      <Header />
      <Inboxboard />
      <Footer />

      <Modal
        backdrop="static"
        centered={true}
        keyboard={false}
        className="login_modal login_modal2"
        show={showSolicitorConfirmation}
        onHide={handleSolicitorClose}
      >
        <Modal.Header className="py-0">
          <Modal.Title className="text-center font-24 fw-6">
            Do you have Solicitor?
          </Modal.Title>
         
        </Modal.Header>
        <div className="text-center c-black font-14">
            A solicitor is required to carry out conveyancing to transfer a
            property from one person to another
          </div>
      </Modal>
    </div>
  );
};

export default Inbox;
