import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowLeftOutline from "../../assets/images/arrow-left@2x.png";
import ArrowRightOutline from "../../assets/images/arrow-right@2x.png";
import { responsiveBanner } from "./constants";
import { useEffect } from "react";
import { useRef } from "react";

export const CarouselBanner = ({
  data = [],
  dotListClass,
  imageClass,
  btnClass,
  activeIndex,
}) => {
  const carouselRef = useRef();

  useEffect(() => {
    carouselRef?.current?.goToSlide(activeIndex);
  }, [activeIndex]);
  return (
    <Carousel
      ref={carouselRef}
      additionalTransfrom={0}
      arrows
      autoPlay
      autoPlaySpeed={3000}
      centerMode={false}
      partialVisbile={false}
      containerClass="carousel-container h-full"
      itemClass="carousel-item-padding-40-px"
      className="w-full h-full"
      dotListClass={`custom-dot-list-style absolute !bottom-2 m-auto ${
        dotListClass && dotListClass
      }`}
      draggable
      focusOnSelect={false}
      infinite
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsiveBanner}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={true}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      customLeftArrow={<div style={{ display: "none" }}></div>}
      customRightArrow={<div style={{ display: "none" }}></div>}
      customButtonGroup={<ButtonGroup btnClass={btnClass} />}
      customDot={<CustomDot length={data?.length} />}
    >
      {data.map((item, index) => {
        return (
          <div
            key={index}
            // className="w-full h-full rounded-xl md:rounded-3xl flex items-center justify-center"
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {item.mediaType === "image" ? (
              <img
                alt=""
                src={item.base64}
                style={{
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                  borderRadius: "22px",
                }}
              />
            ) : (
              <video
                alt=""
                src={item.base64}
                style={{
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                  borderRadius: "22px",
                }}
                controls
              ></video>
            )}
            <span
              style={{
                position: "absolute",
                bottom: "12px",
                left: "25px",
                width: "88px",
                height: "30px",
                background:
                  "0% 0% no-repeat padding-box padding-box rgb(0 0 0 / 30%)",
                backdropFilter: "blur(9px)",
                color: "white",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "13px",
                fontWeight: 600,
                letterSpacing: "0.03px",
              }}
            >
              {item.tag}
            </span>
          </div>
        );
      })}
    </Carousel>
  );
};

const ButtonGroup = ({ next, previous, btnClass }) => {
  // const btnStyle = `absolute top-0 bg-black bg-opacity-50 flex items-center justify-center w-[35px] h-[40px] ${
  //   btnClass && btnClass
  // }`;

  // const arrowStyle = "w-[10px] h-[18px] invert";

  const arrowStyle = {
    width: "7px",
    height: "11px",
    filter: "invert(100%)",
    position: "absolute",
  };
  const btnStyle = {
    position: "absolute",
    top: 0,
    background: "#00000055 0% 0% no-repeat padding-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    outline: "none",
    appearance: "none",
    border: "none",
  };
  return (
    <div
      style={{
        position: "absolute",
        top: "48%",
        left: 0,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        gap: 4,
        marginBottom: 4,
      }}
      className="carousel-button-group"
    >
      <button onClick={() => previous()} style={{ left: "20px", ...btnStyle }}>
        <img
          alt=""
          src={ArrowLeftOutline}
          style={{ left: "11px", ...arrowStyle }}
        />
      </button>
      <button onClick={() => next()} style={{ right: "20px", ...btnStyle }}>
        <img
          alt=""
          src={ArrowRightOutline}
          style={{ right: "11px", ...arrowStyle }}
        />
      </button>
    </div>
  );
};

const CustomDot = ({ length, onClick, ...rest }) => {
  const { index, active } = rest;

  return (
    <button
      className={active ? "active" : "inactive"}
      onClick={() => onClick()}
      style={{
        marginBottom: "12px",
        outline: "none",
        appearance: "none",
        border: "none",
        background: "transparent",
      }}
    >
      {active ? (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "55px",
            height: "28px",
            borderRadius: "5px",
            fontSize: "14px",
            background:
              "0% 0% no-repeat padding-box padding-box rgb(0 0 0 / 30%)",
            color: "white",
            backdropFilter: "blur(1px)",
          }}
        >{`${index + 1}/${length}`}</span>
      ) : null}
    </button>
  );
};
