import React from "react";
import "./Modal.css";
import DeleteIcon from "../../assets/icon/delete.png";
import EditIcon from "../../assets/icon/edit.png";
import UnlistIcon from "../../assets/icon/unlist.png";
import CheckMarkIcon from "../../assets/icon/check-mark.png";

const PropertiesListModal = ({ visiable, onClick }) => {
  return (
    <div className="propertiesCardModalContainer">
      <div onClick={onClick} className="modalItemBox">
        <div className="modalIconBox">
          <img src={CheckMarkIcon} className="icon" />
        </div>
        <div>Mark as Sold</div>
      </div>
      <div onClick={onClick} className="modalItemBox">
        <div className="modalIconBox">
          <img src={UnlistIcon} className="icon" />
        </div>
        <div>Un-list</div>
      </div>
      <div onClick={onClick} className="modalItemBox">
        <div className="modalIconBox">
          <img src={EditIcon} className="icon" />
        </div>
        <div>Edit</div>
      </div>
      <div onClick={onClick} className="modalItemBox">
        <div className="modalIconBox">
          <img src={DeleteIcon} className="icon" />
        </div>
        <div>Delete</div>
      </div>
    </div>
  );
};

export default PropertiesListModal;
