import ScrollTop from "components/scrollTop";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import { createPropertySaveForm } from "store/actions";
import { createPropertySelector } from "store/selectors";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import CommercialDetail from "./CommercialDetail";
import LandDetail from "./LandDetail";
import ResidentialDetail from "./ResidentialDetail";

export default function Step1() {
  const history = useHistory();
  const { propertyType, ...prevData } = useSelector(
    createPropertySelector.step1
  );
  const dispatch = useDispatch();
  const [fields, setFields] = useState(prevData);

  const onFieldChange = (e) => {
    let d = e.target;
    setFields((prev) => ({
      ...prev,
      [d.name]: d.value,
    }));
  };

  const content = () => {
    let data = { fields, onFieldChange };

    switch (propertyType) {
      case "Residential":
        return <ResidentialDetail {...data} />;
      case "Commercial":
        return <CommercialDetail {...data} />;
      case "Land":
        return <LandDetail {...data} />;
      default:
        history.push("/step2");
    }
  };

  const onSubmit = () => {
    dispatch(createPropertySaveForm(fields));
    history.push("/step3");
  };

  return (
    <>
      <Header />
      <Container>
        <div className="list_your_property">
          <h2 className="list_title">Property Details</h2>
          {/* <h2 className="list_title">List your property</h2> */}
          <div className="box_shadow_wrapper">
            <Fade>
              {/* <Stepper /> */}
              <>
                <div className="custom-steeper">
                  <div className="common-step step1 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                      {/* Property <br /> information */}
                      Address and <br /> Ownership
                    </span>
                  </div>

                  <div className="common-step step2 position-relative">
                    <div className="stepbox process">02</div>
                    <span>Property Details</span>
                  </div>

                  <div className="common-step step3 position-relative">
                    <div className="stepbox uundeprocss">03</div>
                    <span>Value</span>
                  </div>

                  <div className="common-step step4 position-relative">
                    <div className="stepbox uundeprocss">04</div>
                    <span>
                      Images and <br /> Video
                    </span>
                  </div>

                  <div className="common-step step5 position-relative">
                    <div className="stepbox uundeprocss">05</div>
                    <span>Submit and review</span>
                  </div>
                </div>

                {content()}

                <div className="home_type text-center">
                  <div className="style_btn_div submit-btn">
                    <button
                      className="back_btn style_btn"
                      onClick={history.goBack}
                    >
                      Back{" "}
                    </button>
                    <button
                      className="style_btn yes_btn ml-2"
                      // onClick={() => toast.success("Page not linked")}
                      onClick={onSubmit}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </>
            </Fade>
          </div>
        </div>
      </Container>

      <ScrollTop />
      <Footer />
    </>
  );
}
