import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

export const SellerDashboard = ({ user }) => {
  const history = useHistory();

  const data = [
    {
      label: "Uploaded Properties",
      count: user?.sellerPropertyUploadCount,
      _key: "uploadedProperties",
    },
    {
      label: "Sold Properties",
      count: user?.sellerPropertySoldCount,
      _key: "soldProperties",
    },
    {
      label: "Properties In Review",
      count: user?.sellerPropertyInReviewCount,
      _key: "propertiesInReview",
    },
    {
      label: "New Offers",
      count: user?.sellerPropertyOfferCount,
      _key: "newOffers",
    },
  ];

  const countStyle = (_key) => {
    switch (_key) {
      case "uploadProperties":
        return { color: "#001978" };

      case "soldProperties":
        return { color: "#3399FF" };

      case "propertiesInReview":
        return { color: "#131212" };

      case "newOffers":
        return { color: "#FFFFFF" };

      default:
        return { color: "#001978" };
    }
  };

  return (
    <div
      style={{
        background: "#FFFFFF",
        boxShadow: `0px 2px 12px #0000000D`,
        border: `0.5px solid #3399FF42`,
        borderRadius: "9px",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: "22px",
          lineHeight: "27px",
          color: "#151415",
        }}
      >
        Dashboard
      </div>
      <div
        style={{
          display: "flex",
          gap: "40px",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {data.map((item, index) => {
          return (
            <CardItem
              key={index}
              item={item}
              cardStyle={index === data.length - 1 && { background: "#3399FF" }}
              countStyle={countStyle(item["_key"])}
              labelStyle={index === data.length - 1 && { color: "#FFFFFF" }}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          className="Continue-btn"
          style={{ width: "250px", height: "50px" }}
          onClick={() => history.push("/step1")}
        >
          Post Property
        </Button>
      </div>
    </div>
  );
};

const CardItem = ({ item, cardStyle, countStyle, labelStyle }) => {
  return (
    <div
      style={{
        background: "#F8F8F8",
        borderRadius: "22px",
        width: "244px",
        height: "159px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        ...cardStyle,
      }}
    >
      <div style={{ position: "relative" }}>
        <span
          style={{
            background: `#001978 0% 0% no-repeat padding-box`,
            opacity: "0.05",
            width: "26px",
            height: "26px",
            position: "absolute",
            margin: "auto",
            borderRadius: "50%",
          }}
        ></span>
        <p style={{ fontSize: "35px", color: "#001978", ...countStyle }}>
          {item?.count}
        </p>
      </div>
      <p
        style={{
          fontSize: "17px",
          lineHeight: "24px",
          color: "#131212",
          ...labelStyle,
        }}
      >
        {item?.label}
      </p>
    </div>
  );
};
