import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";


// images
import Cardone from "assets/images/card-one.jpg";



const Floorplanmid = () => {
    const history = useHistory();
    return (
        <section className='Floorplanmid-main'>
              <Container>

                  <div className="wrap-box">
                  <div className="Floorplanmid-in">
                   <h2>Upload Floor Plan</h2>
                   <Button className='direct-floor' onClick={()=> history.push("/payment-method")}>Direct Pay £25 for floorplan</Button>
               </div>
                 <div className="floorplan-box">
                     <h5 className='text-center'>Upload your floor plan image below.</h5>
                     <div className="floor-upload">
                         <div className="floor-img-upload position-relative">
                         <input type="file" className="custom-file-input" />
                         <div className="imges-iocn">
                         <svg aria-hidden="true" role="img" className="iconify iconify--bi" width="32" height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="#C0C0C0"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z"/><path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71l-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/></g></svg>
                         </div>
                         </div>
                         <p>OR</p>
                         <div className="floor-img-upload floor-img-uploadwo position-relative">
                          <input type="file" className="custom-file-input" />
                          <div className="create-floor-plan">
                               <div className="edit-icon text-center">
                               <svg  aria-hidden="true" role="img" className="iconify iconify--ic" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75l1.83-1.83z"/></svg>
                               </div>
                              <h6>Create floor Plan</h6>
                          </div>
                         </div>
                     </div>

                      <div className="floor-plan-btns">
                          <Button className='back-btn'> Back</Button>
                          <Button className="Continue-btn"> Continue</Button>

                      </div>
                 </div>

                  </div>
              
              </Container>
        </section>
    );
}

export default Floorplanmid;
