import React, { useState } from "react";
import Stepper from "../../../components/Stepper";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Propertydetailstep from "./Propertydetailstep";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import ScrollTop from "components/scrollTop";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPropertySelector } from "store/selectors";
import { createPropertySaveForm } from "store/actions";
import { useEffect } from "react";
import { uploadMediaRequest } from "utils/helper";
import { createProperty } from "backend_helper/api_helper";

export default function Stepsix() {
  const history = useHistory();
  const [showMessage, setshowMessage] = useState();
  const prevData = useSelector(createPropertySelector?.formData);
  const dispatch = useDispatch();
  const [propertyImages, setImages] = React.useState(
    prevData.propertyImages || []
  );

  const onSubmit = () => {
    propertyImages.forEach((image, index) => {
      let payload = new FormData();
      payload.append("media", image?.file);
      payload.append("mediaType", image?.mediaType);
      uploadMediaRequest({
        payload,
        onSuccess: (data) => {
          dispatch(
            createPropertySaveForm({
              ...prevData,
              propertyImages: [
                ...propertyImages,
                (propertyImages[index] = { ...image, imgUrl: data?.url }),
              ],
            })
          );
        },
      });

      if (index === propertyImages?.length - 1) {
        sumbitPropertyDetails();
      }
    });
  };

  const sumbitPropertyDetails = () => {
    createProperty(prevData)
      .then(({ isSuccess, err }) => {
        if (isSuccess) {
          setshowMessage(true);
          history.push("/");
          return;
        }
        console.log(err);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // function handleClick() {
  //   setshowMessage(true);
  //   setTimeout(() => {
  //     setshowMessage(false);
  //     history.push("/");
  //   }, 8000);
  // }
  return (
    <>
      <ScrollTop />
      <Header />
      <section className="common-steps-main">
        <Container>
          <Row>
            <div className="list-main stepthree">
              <div className="list-heading">
                <h2>Review and Submit</h2>
              </div>
              <div className="list-main-under">
                {/* <Stepper/> */}

                <div className="custom-steeper">
                  <div className="common-step step1 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                      Property <br /> information
                    </span>
                  </div>

                  <div className="common-step step2 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>Property details</span>
                  </div>

                  <div className="common-step step3 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>Value</span>
                  </div>

                  <div className="common-step step4  active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                      Images and <br /> Video
                    </span>
                  </div>

                  <div className="common-step step5 active-line position-relative">
                    <div className="stepbox process">
                      5
                      {/* <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg> */}
                    </div>
                    <span>
                      Submit and <br></br> review
                    </span>
                  </div>
                </div>

                <div className="wraping-col">
                  <div className="form-heading-steo4">
                    <div className="left-cont-srep4">
                      <h3>Property Preview</h3>
                    </div>
                  </div>

                  <Propertydetailstep />

                  <div className="floor-plan-btns steps-btn mt-5">
                    <Button
                      className="back-btn"
                      onClick={() => history.push("/step5")}
                    >
                      {" "}
                      Back
                    </Button>
                    <Button className="Continue-btn" onClick={onSubmit}>
                      {" "}
                      Upload
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Footer />
      <Modal className="confirmDialog" show={showMessage} centered={true}>
        <div className="px-4 py-5 font-16 opacity-8 text-center fw-5">
          Please allow up to 24 hours for the listing to go live. We will email
          you once your listing has gone live.
        </div>
      </Modal>
    </>
  );
}
