import { propTypes } from "react-bootstrap/esm/Image";
import { Modal, Button, Form, InputGroup, CloseButton } from "react-bootstrap";
import React, { useState, useEffect } from "react";

export default function Confirmation({
  show,
  title,
  content,
  handleClose,
}) {
  function onClose(param) {
    handleClose(param);
  }

  return (
    <>
      <Modal show={show} centered={true} className="confirmDialog">
        <div className="p-4 px-4">
          <div className="px-3 pb-3 font-18 fw-6 w-100 text-center">
            {title}
          </div>
          <div className="font-14 pt-2">{content}</div>
          <div className="text-center d-flex">
            <button
              className="theme-btn border mr-3 w-100"
              onClick={() => onClose(false)}
            >
              No
            </button>
            <button
              className="theme-btn black w-100"
              onClick={() => onClose(true)}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

// Confirmation.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string.isRequired,
// };
