import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import Sliderproperties from "./Sliderproperties";
// images
import Main from "assets/images/main.jpg";
import sentImg from "assets/images/msg-sent.png";
import Bed from "assets/images/bed.png";
import Car from "assets/images/car.png";
import Bath from "assets/images/bath.png";
import Dummymap from "assets/images/map.png";
import close_icon from "assets/svg/close_icon.svg";

const Propertydetail = () => {
  const [showArrange, setshowArrange] = useState();
  const [requestSent, setrequestSent] = useState();
  const [openSendOffer, setopenSendOffer] = useState();
  const history = useHistory();

  const openArrangeDialog = () => {
    setshowArrange(true);
  };
  const openSendOfferPopup = () => {
    setopenSendOffer(true);
  };
  const handleopenSendOffer = () => {
    setopenSendOffer(false);
  };
  const handleArrangeClose = () => {
    setshowArrange(false);
    setrequestSent(true);
    setTimeout(() => {
      setrequestSent(false);
    }, 3000);
  };

  return (
    <section className="propety-details">
      <Container>
        <div onClick={history.goBack} className="go-back-at">
          <div className="go-back-div">
            <p>
              <svg
                aria-hidden="true"
                role="img"
                className="iconify iconify--ic"
                width="22"
                height="22"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"
                />
              </svg>
              Go back to Home
            </p>
          </div>
        </div>

        <div className="property-details--head mt-4">
          <h3>Property details</h3>
          <div className="view-div">
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.553"
                height="12.443"
                viewBox="0 0 15.553 12.443"
              >
                <path
                  id="Path_3732"
                  data-name="Path 3732"
                  d="M550.744,399.222a3.023,3.023,0,1,0,.891-2.137A3.023,3.023,0,0,0,550.744,399.222Zm7.5-4.63a11.02,11.02,0,0,1,3.266,4.4.559.559,0,0,1,0,.445c-1.622,3.756-4.512,6-7.73,6h-.008c-3.21,0-6.1-2.248-7.723-6a.559.559,0,0,1,0-.445c1.623-3.756,4.511-6,7.723-6h.008a7.21,7.21,0,0,1,4.462,1.591Zm-4.463,6.506a1.881,1.881,0,0,0,0-3.763,1.466,1.466,0,0,0-.265.023,1.555,1.555,0,0,1-1.555,1.493h-.038a1.981,1.981,0,0,0-.038.369A1.891,1.891,0,0,0,553.777,401.1Z"
                  transform="translate(-546 -393)"
                  fill="#9D9C9C"
                  fillRule="evenodd"
                  opacity="0.8"
                ></path>
              </svg>
              566 views
            </p>

            <p>
              <svg
                aria-hidden="true"
                role="img"
                className="iconify iconify--ri"
                width="16"
                height="16"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#9D9C9C"
                  d="M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2z"
                />
              </svg>
              11 Save
            </p>
          </div>
        </div>

        <div className="propety-details-box">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="propety-images">
                <div className="img-main">
                  <img src={Main} className="img-fluid" />
                </div>
                <div className="img-list mt-3">
                  <ul>
                    <li className="position-relative first-grey">
                      <img src={Main} className="img-fluid" />
                      <div className="div-overlay-grey">
                        <div className="playicon">
                          <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--material-symbols"
                            width="28"
                            height="28"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#190835"
                              d="M9.525 18.025q-.5.325-1.013.037Q8 17.775 8 17.175V6.825q0-.6.512-.888q.513-.287 1.013.038l8.15 5.175q.45.3.45.85t-.45.85Z"
                            />
                          </svg>
                        </div>
                      </div>
                    </li>
                    <li>
                      <img src={Main} className="img-fluid" />
                    </li>
                    <li>
                      <img src={Main} className="img-fluid" />
                    </li>
                    <li>
                      <img src={Main} className="img-fluid" />
                    </li>
                    <li>
                      <img src={Main} className="img-fluid" />
                    </li>
                    <li>
                      <img src={Main} className="img-fluid" />
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="propety-images-details">
                <div className="propety-box-one">
                  <div className="box-one-price">
                    <h3 className="green-big">$ 150,000,00</h3>
                    <div className="three-iocn">
                      <div className="exclamation">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--zondicons"
                          width="28"
                          height="28"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="#F52F2F"
                            d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
                          />
                        </svg>
                      </div>
                      <div className="heart-col">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ph"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="currentColor"
                            d="M128 224a7.8 7.8 0 0 1-3.9-1C119.8 220.6 20 163.9 20 92a60 60 0 0 1 108-36a60 60 0 0 1 108 36c0 30.6-17.7 62-52.6 93.4a314.3 314.3 0 0 1-51.5 37.6a7.8 7.8 0 0 1-3.9 1Zm-3.9-15ZM80 48a44 44 0 0 0-44 44c0 55.2 74 103.7 92 114.7c18-11 92-59.5 92-114.7a44 44 0 0 0-84.6-17a8 8 0 0 1-14.8 0A43.8 43.8 0 0 0 80 48Z"
                          />
                        </svg>
                      </div>

                      <div className="share-col">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ph"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="currentColor"
                            d="M216 200a40 40 0 0 1-80 0a41 41 0 0 1 2.7-14.5l-46.1-29.6a40 40 0 1 1 0-55.8l46.1-29.6A41 41 0 0 1 136 56a40.1 40.1 0 1 1 11.4 27.9l-46.1 29.6a40.3 40.3 0 0 1 0 29l46.1 29.6A40 40 0 0 1 216 200Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="flat-details">
                    <h4>3 BHK Flat</h4>
                    <p>
                      Added on <span className="font-big">17 Dec, 2022</span>
                    </p>
                  </div>
                  <div className="location-icon">
                    <p>
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="24"
                        height="24"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#3399FF"
                          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                        />
                      </svg>
                      4571 Giraffe Hill Drive, Frisco, Texas, 75034
                    </p>
                  </div>
                </div>

                <div className="propety-box-two mt-3">
                  <div className="facilit-list">
                    <ul>
                      <li>
                        <div className="facilit-list-one">
                          <div className="facilit-listicon">
                            <img src={Bed} />
                          </div>
                          <p>3 Bedrooms</p>
                        </div>
                      </li>
                      <li>
                        <div className="facilit-list-one">
                          <div className="facilit-listicon">
                            <img src={Car} />
                          </div>
                          <p>3 Parking</p>
                        </div>
                      </li>
                      <li>
                        <div className="facilit-list-one">
                          <div className="facilit-listicon">
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--maki"
                              width="15"
                              height="15"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="#001978"
                                d="M13 8c0 3.31-2.19 6-5.5 6S2 11.31 2 8a5.33 5.33 0 0 1 5 3.61V7H4.5A1.5 1.5 0 0 1 3 5.5v-3a.5.5 0 0 1 .9-.3l1.53 2l1.65-3a.5.5 0 0 1 .84 0l1.65 3l1.53-2a.5.5 0 0 1 .9.3v3A1.5 1.5 0 0 1 10.5 7H8v4.61A5.33 5.33 0 0 1 13 8z"
                              />
                            </svg>
                          </div>
                          <p>Garden</p>
                        </div>
                      </li>
                      <li>
                        <div className="facilit-list-one">
                          <div className="facilit-listicon">
                            <img src={Bath} />
                          </div>
                          <p>3 Bathrooms</p>
                        </div>
                      </li>
                    </ul>

                    <div className="details-teo-btn w-100">
                      <Button
                        className="view-more w-100 fixed_text"
                        onClick={openArrangeDialog}
                      >
                        Arrange Viewing
                      </Button>
                      <Button
                        className="edit-details text22 w-100"
                        onClick={openSendOfferPopup}
                      >
                        Send Offer
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="map-location">
            <h5>Map Location</h5>
            <img src={Dummymap} />
          </div>

          <div className="near-public-facilty">
            <h5>Nearest public facilities</h5>

            <div className="near-list-main">
              <div className="near-list-one">
                <div className="near-list-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--gridicons"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#7D7F88"
                      d="M9 20c0 1.1-.9 2-2 2s-1.99-.9-1.99-2S5.9 18 7 18s2 .9 2 2zm8-2c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2s2-.9 2-2s-.9-2-2-2zm.396-5a2 2 0 0 0 1.952-1.566L21 5H7V4a2 2 0 0 0-2-2H3v2h2v11a2 2 0 0 0 2 2h12a2 2 0 0 0-2-2H7v-2h10.396z"
                    />
                  </svg>
                </div>
                <div className="near-list-content">
                  <h6>Minimarket</h6>
                  <p>200m</p>
                </div>
              </div>

              <div className="near-list-one">
                <div className="near-list-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ri"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#7D7F88"
                      d="M21 20h2v2H1v-2h2V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v17zM11 8H9v2h2v2h2v-2h2V8h-2V6h-2v2zm3 12h2v-6H8v6h2v-4h4v4z"
                    />
                  </svg>
                </div>
                <div className="near-list-content">
                  <h6>Hospital</h6>
                  <p>100m</p>
                </div>
              </div>

              <div className="near-list-one">
                <div className="near-list-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--icomoon-free"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#7D7F88"
                      d="M3.5 0c-1.657 0-3 1.567-3 3.5c0 1.655.985 3.042 2.308 3.406l-.497 8.096A.931.931 0 0 0 3.25 16h.5c.55 0 .972-.449.939-.998l-.497-8.096C5.515 6.541 6.5 5.155 6.5 3.5c0-1.933-1.343-3.5-3-3.5zm10.083 0l-.833 5h-.625l-.417-5h-.417l-.417 5h-.625l-.833-5h-.417v6.5a.5.5 0 0 0 .5.5h1.302l-.491 8.002a.931.931 0 0 0 .939.998h.5c.55 0 .972-.449.939-.998L12.197 7h1.302a.5.5 0 0 0 .5-.5V0h-.417z"
                    />
                  </svg>
                </div>
                <div className="near-list-content">
                  <h6>Eating Places</h6>
                  <p>200m</p>
                </div>
              </div>

              <div className="near-list-one">
                <div className="near-list-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ph"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 256 256"
                  >
                    <path
                      fill="#7D7F88"
                      d="M188 24H68a32.1 32.1 0 0 0-32 32v128a32.1 32.1 0 0 0 32 32h12l-14.4 19.2a8 8 0 0 0 1.6 11.2A7.7 7.7 0 0 0 72 248a8 8 0 0 0 6.4-3.2L100 216h56l21.6 28.8a8 8 0 0 0 6.4 3.2a7.7 7.7 0 0 0 4.8-1.6a8 8 0 0 0 1.6-11.2L176 216h12a32.1 32.1 0 0 0 32-32V56a32.1 32.1 0 0 0-32-32Zm0 176H68a16 16 0 0 1-16-16v-48h152v48a16 16 0 0 1-16 16Zm-92-28a12 12 0 1 1-12-12a12 12 0 0 1 12 12Zm88 0a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z"
                    />
                  </svg>
                </div>
                <div className="near-list-content">
                  <h6>Train Station</h6>
                  <p>500m</p>
                </div>
              </div>
            </div>
          </div>

          <div className="about-location">
            <h5>About location’s neighborhood</h5>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
              elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
              magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
              justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
              takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
              sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
              tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet
            </p>
          </div>

          <div className="additional-feature">
            <h5>Additional Features</h5>
            <Row>
              <Col lg={3} md={6} sm={12}>
                <div className="style-description">
                  <p>Property Style</p>
                  <h6>Detached Style</h6>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="style-description">
                  <p>Property Age</p>
                  <h6>Brand New</h6>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="style-description">
                  <p>Tenure</p>
                  <h6>Freehold</h6>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="style-description">
                  <p>Central Heating</p>
                  <h6>Full Central Heating</h6>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="style-description">
                  <p>Reception rooms</p>
                  <h6>1 Room</h6>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="style-description">
                  <p>Broadband connnected</p>
                  <h6>Yes - 5.2 Mbps</h6>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="style-description">
                  <p>Overall property condition</p>
                  <h6>Good</h6>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <div className="style-description">
                  <p>Energy Performance Certificate</p>
                  <h6>
                    <a href="#">View Certificate</a>
                  </h6>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Sliderproperties />
        {/* arrange view popup */}
        <Modal
          backdrop="static"
          centered={true}
          keyboard={false}
          className="login_modal login_modal2 slot-model"
          show={showArrange}
          onHide={handleArrangeClose}
        >
          <Modal.Header className="py-0">
            <Modal.Title className="text-center">Arrange Viewing</Modal.Title>
            <div className="text-center c-black opacity-6">
              Please select from below options
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex">
              <div className="mx-auto">
                {" "}
                <div className="d-flex">
                  <Form.Check type="radio" checked />
                  <div className="pl-2">
                    <div className="fw-6 font-14 c-blue">In person Viewing</div>
                    <div className="font-12 c-grey">Spaces left 3</div>
                  </div>
                </div>
                <div className="d-flex pt-3">
                  <Form.Check type="radio" />
                  <div className="pl-2">
                    <div className="fw-6 font-14">Video Tour Viewings</div>
                    <div className="font-12 c-grey">Spaces left 3</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center pt-3 c-grey2">
              Select your slot preference
            </div>
            <div className="pt-1 text-center">
              <div className="slot-btn active">
                <Form.Check id="slot2" name="slot" type="radio" />
                <label htmlFor="slot2" className="pl-2">
                  Slot 1
                </label>
              </div>
              <div className="slot-btn">
                <Form.Check id="slot3" type="radio" name="slot" />
                <label htmlFor="slot3" className="pl-2">
                  Slot 2
                </label>
              </div>
              <div className="slot-btn">
                <Form.Check id="slot1" type="radio" name="slot" />
                <label htmlFor="slot1" className="pl-2">
                  Slot 3
                </label>
              </div>
            </div>
            <div className="text-center pt-4 d-flex">
              <button
                className="theme-btn w-100 border mr-3"
                onClick={handleArrangeClose}
              >
                Cancel
              </button>
              <button
                className="theme-btn w-100 black"
                onClick={handleArrangeClose}
              >
                {" "}
                Send Request
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* request popup */}
        <Modal
          backdrop="static"
          centered={true}
          keyboard={false}
          className="login_modal login_modal2 slot-model"
          show={requestSent}
          // onHide={handleArrangeClose}
        >
          <Modal.Header className="py-2">
            <Modal.Title className="text-center">
              <div className="mx-auto">
                <img className="sent-img" src={sentImg} />
              </div>
              <div className="text-center c-black">Request sent</div>
            </Modal.Title>
            <div className="c-black opacity-8 py-2 text-center">
              Request has been sent to seller. You will be notified once seller
              accepts your request.
            </div>
          </Modal.Header>
        </Modal>
        {/* send offer */}
        <Modal
          backdrop="static"
          centered={true}
          keyboard={false}
          className="login_modal login_modal2 slot-model"
          show={openSendOffer}
          onHide={handleopenSendOffer}
        >
          <Modal.Header className="py-0">
            <Modal.Title className="text-center">Offer Price</Modal.Title>
            <div className="text-center opacity-8" style={{color: "#28272B"}}>
              Please enter your offer for 4571 Giraffe Hill drive, Frisco,
              Texas, 75034
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="w-100 pt-4">
                {/* <Form.Label className="input_label pt-0">Email</Form.Label> */}
                <InputGroup className="position-relative">
                  <Form.Control
                    type="text"
                    className="inputField form-control"
                    name="email"

                    // onChange={this.handleChange.bind(this)}
                  />
                  <div className="email-svg">
                    <h4>£</h4>
                  </div>
                </InputGroup>
              </div>
            </div>
            <div className="text-center pt-4 d-flex">
              <button
                className="theme-btn w-100 border mr-3"
                onClick={handleopenSendOffer}
              >
                Cancel
              </button>
              <button
                className="theme-btn w-100 black"
                onClick={handleopenSendOffer}
              >
                {" "}
                Send Offer
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </section>
  );
};

export default Propertydetail;
