import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
// import SLidercard from "pages/Home/slidercard";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

// images
import Cardone from "assets/images/card-one.jpg";

const data = [
  {
    id: "1",
    img: "/img/auction_1.jpg",
    title: "Walking On Air",
    owner: "Richard",
    price: "1.5 ETH",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        aria-hidden="true"
        role="img"
        className="iconify iconify--material-symbols"
        width="26"
        height="26"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          fill="#111"
          d="M8.025 22L6.25 20.225L14.475 12L6.25 3.775L8.025 2l10 10Z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        aria-hidden="true"
        role="img"
        className="iconify iconify--material-symbols"
        width="23"
        height="23"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          fill="#111"
          d="M16 22L6 12L16 2l1.775 1.775L9.55 12l8.225 8.225Z"
        />
      </svg>
    </div>
  );
}

function Sliderproperties() {
  var settings = {
    arrow: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="near-properties">
      <Container>
        <div className="slider-head">
          <h3>Similar Properties</h3>
          <a href="#" className="view-all">
            View all
          </a>
        </div>

        <Slider {...settings}>
          <div>
            <Card className="card-main">
              <CardImg top width="100%" src={Cardone} alt="Card image cap" />
              <CardBody>
                <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                <CardText className="card-address">
                  <div className="cardlocation-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.635"
                      height="15"
                      viewBox="0 0 12.635 15"
                    >
                      <path
                        id="Path_3773"
                        data-name="Path 3773"
                        d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                        transform="translate(-155.5 -334.818)"
                        fill="#39f"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                  301 Dort Walks, Stockton, California
                </CardText>
                <p className="properties-prize">£ 150,000,00</p>
                <Button className="view-property">View Property</Button>
              </CardBody>
            </Card>
          </div>
          <div>
            <Card className="card-main">
              <CardImg top width="100%" src={Cardone} alt="Card image cap" />
              <CardBody>
                <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                <CardText className="card-address">
                  <div className="cardlocation-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.635"
                      height="15"
                      viewBox="0 0 12.635 15"
                    >
                      <path
                        id="Path_3773"
                        data-name="Path 3773"
                        d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                        transform="translate(-155.5 -334.818)"
                        fill="#39f"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                  301 Dort Walks, Stockton, California
                </CardText>
                <p className="properties-prize">£ 150,000,00</p>
                <Button className="view-property">View Property</Button>
              </CardBody>
            </Card>
          </div>
          <div>
            <Card className="card-main">
              <CardImg top width="100%" src={Cardone} alt="Card image cap" />
              <CardBody>
                <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                <CardText className="card-address">
                  <div className="cardlocation-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.635"
                      height="15"
                      viewBox="0 0 12.635 15"
                    >
                      <path
                        id="Path_3773"
                        data-name="Path 3773"
                        d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                        transform="translate(-155.5 -334.818)"
                        fill="#39f"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                  301 Dort Walks, Stockton, California
                </CardText>
                <p className="properties-prize">£ 150,000,00</p>
                <Button className="view-property">View Property</Button>
              </CardBody>
            </Card>
          </div>
          <div>
            <Card className="card-main">
              <CardImg top width="100%" src={Cardone} alt="Card image cap" />
              <CardBody>
                <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                <CardText className="card-address">
                  <div className="cardlocation-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.635"
                      height="15"
                      viewBox="0 0 12.635 15"
                    >
                      <path
                        id="Path_3773"
                        data-name="Path 3773"
                        d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                        transform="translate(-155.5 -334.818)"
                        fill="#39f"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                  301 Dort Walks, Stockton, California
                </CardText>
                <p className="properties-prize">£ 150,000,00</p>
                <Button className="view-property">View Property</Button>
              </CardBody>
            </Card>
          </div>
          <div>
            <Card className="card-main">
              <CardImg top width="100%" src={Cardone} alt="Card image cap" />
              <CardBody>
                <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                <CardText className="card-address">
                  <div className="cardlocation-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.635"
                      height="15"
                      viewBox="0 0 12.635 15"
                    >
                      <path
                        id="Path_3773"
                        data-name="Path 3773"
                        d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                        transform="translate(-155.5 -334.818)"
                        fill="#39f"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                  301 Dort Walks, Stockton, California
                </CardText>
                <p className="properties-prize">£ 150,000,00</p>
                <Button className="view-property">View Property</Button>
              </CardBody>
            </Card>
          </div>
          <div>
            <Card className="card-main">
              <CardImg top width="100%" src={Cardone} alt="Card image cap" />
              <CardBody>
                <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                <CardText className="card-address">
                  <div className="cardlocation-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.635"
                      height="15"
                      viewBox="0 0 12.635 15"
                    >
                      <path
                        id="Path_3773"
                        data-name="Path 3773"
                        d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                        transform="translate(-155.5 -334.818)"
                        fill="#39f"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                  301 Dort Walks, Stockton, California
                </CardText>
                <p className="properties-prize">£ 150,000,00</p>
                <Button className="view-property">View Property</Button>
              </CardBody>
            </Card>
          </div>
        </Slider>

        {/* <Swiper


            navigation={false}

          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <div className="slider-main">
            <SwiperSlide>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property">View Property</Button>
                </CardBody>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property">View Property</Button>
                </CardBody>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property">View Property</Button>
                </CardBody>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property">View Property</Button>
                </CardBody>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property">View Property</Button>
                </CardBody>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property">View Property</Button>
                </CardBody>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property">View Property</Button>
                </CardBody>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property">View Property</Button>
                </CardBody>
          </Card>
        </SwiperSlide>

        </div>


        </Swiper> */}
      </Container>
    </section>
  );
}

export default Sliderproperties;
