// Api BASE URLs
// http://13.58.48.196:3001

export const API_URL = "https://admin.ubsold.com";
export const API_VERSION = "/api";
export const APP_NAME = "UBSold";
export const PANEL_NAME = "Website";

//User or Login
export const UserLogin = "/users/signin";
export const SignupLogin = "/users/signup";

//otp verifiy
export const otpSend = "/users/resend";
export const otpVerify = "/users/verify";

// upload media
export const uploadMedia = "/images/upload";

// property
export const createProperty = "/properties";
export const sellerProperties = "/properties/for/seller";
export const sellerUploadSoldProperties = "/properties/for/seller";
export const buyerProperties = "/properties/for/buyer";

// get user details
export const userById = "/users";
