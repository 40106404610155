import React from "react";

export const DeleteIcon = ({ onClick }) => (
  <div
    className="delete-icon pointer"
    style={{ position: "absolute", top: "10px", right: "10px" }}
  >
    <a onClick={onClick}>
      <div className="delete-col">
        <svg
          aria-hidden="true"
          role="img"
          className="iconify iconify--material-symbols"
          width="22"
          height="22"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="#111111"
            d="M5 6V4h3.5l1-1h5l1 1H19v2Zm3 15q-.825 0-1.412-.587Q6 19.825 6 19V7h12v12q0 .825-.587 1.413Q16.825 21 16 21Z"
          />
        </svg>
      </div>
    </a>
  </div>
);
