import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import app_store from "../assets/svg/apple-store.svg";
import facebook from "../assets/svg/facebook-f.svg";
import google from "../assets/svg/google-plus-g.svg";
import youtube from "../assets/svg/youtube.svg";
import twitter from "../assets/svg/twitter.svg";
import play_store from "../assets/svg/play-store.svg";

export default function Footer() {
  return (
    <>
      <div className="footer_container">
        <Row className="p-0 m-0 footer_container_wrapper">
          <Col sm={12} md={6} xl={3} lg={3}>
            <ul className="">
              <li>
                <img width="103" height="88" src={Logo} />
              </li>
              <li>
                <Link className="footer_link" to="">
                  Mobile App
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Our Services
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Customer Support
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={6} xl={3} lg={3}>
            <ul className="">
              <li>
                <span> Company</span>
              </li>
              <li>
                <Link className="footer_link" to="">
                  About us
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Contact us
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Give Feeback
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Report a problem
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={6} xl={3} lg={3}>
            <ul className="">
              <li>
                <span>Services</span>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Properties
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Sell
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Buy
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  Floor Plan
                </Link>
              </li>
              <li>
                <Link className="footer_link" to="">
                  EPC
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={6} xl={3} lg={3}>
            <ul className="">
              <li>
                <span>Contact us</span>
              </li>
              <li>
                <a className="footer_link" href="tel:0161 888 1234">
                  Toll free -0161 888 1234
                </a>
              </li>
              <li>
                <a className="footer_link" href="mailto:feedback@ubsold.com">
                  Email -feedback@ubsold.com
                </a>
              </li>
            </ul>

            <ul className="app-stores">
              <li>
                <Link><img src={ 'https://ubsold.s3.us-east-2.amazonaws.com/images/0804d873bceb633ccdc0de9542eb514bd61c2452.svg' || app_store} alt="" className="img-fluid" /></Link>
              </li>
              <li>
                <Link><img src={ 'https://ubsold.s3.us-east-2.amazonaws.com/images/b92439f0f8f0979ff3778c6b15ff445c91b0c3ec.svg'|| play_store} alt="" className="img-fluid" /></Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="m-0 footer_copyright_section ">
          <Col className="" sm={12} md={6} xl={6} lg={6}>
            <span>Copyright @ 2022 UB Sold</span>
          </Col>
          <Col sm={12} md={6} xl={6} lg={6}>
            <span className="d-flex social_icon">
              <img src={'https://ubsold.s3.us-east-2.amazonaws.com/images/e5a27d0c40a776bcda84db33ba43a6053ff4d70f.svg'|| facebook} width="10px" />{" "}
              <img src={'https://ubsold.s3.us-east-2.amazonaws.com/images/8f4fb718511813ff99f2a189dcac563410f1ce4e.svg'|| twitter} width="20px" />{" "}
              <img src={'https://ubsold.s3.us-east-2.amazonaws.com/images/dc26518077960897e74c7c8fdee9356fbcf0f265.svg' || youtube} width="20px" />{" "}
              <img src={'https://ubsold.s3.us-east-2.amazonaws.com/images/5c6eaa8dfb227c7098dfba4398d12d7b406a9420.svg' || google} width="20px" />{" "}
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
}
