import Axios from "./Axios";
import * as url from "./ApiUrl";

export const signUp = (data) => Axios.post(url.SignupLogin, data);
export const logInApi = (data) => Axios.post(url.UserLogin, data);
export const sendOtpApi = (data) => Axios.post(url.otpSend, data);
export const verifyOtpApi = (data) => Axios.post(url.otpVerify, data);

// upload media
export const uploadMedia = (data) => Axios.post(url.uploadMedia, data);

// create property
export const createProperty = (data) => Axios.post(url.createProperty, data);

// get properties
export const getSellerProperties = (query) =>
  Axios.get(`${url.sellerProperties}${query ? `?${query}` : ""}`);

export const getSellerUploadProperties = (query) =>
  Axios.get(`${url.sellerUploadSoldProperties}${query ? `?${query}` : ""}`);

  export const getSellerSoldProperties = (query) =>
  Axios.get(`${url.sellerUploadSoldProperties}${query ? `?${query}` : ""}`);

export const getBuyerProperties = (query) =>
  Axios.get(`${url.buyerProperties}?${query ? `?${query}` : ""}`);

// get user details
export const getUserById = (id) => Axios.get(`${url.userById}/${id}`);
