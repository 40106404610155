import React from "react";
import Stepper from "../../../components/Stepper";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Form, InputGroup, Col, Row, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import ScrollTop from "components/scrollTop";
import { Button, FormGroup, Label, Input, FormText } from "reactstrap";

export default function Step1() {
  const history = useHistory();

  return (
    <>
      <Header />
      <Container>
        <div className="list_your_property">
          <h2 className="list_title">Address and Ownership</h2>
          <div className="box_shadow_wrapper">
            <Fade>
              {/* <Stepper /> */}

              <div className="custom-steeper">
                <div className="common-step step1 active-line position-relative">
                  <div className="stepbox active-step">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--ic"
                      width="18"
                      height="18"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#111"
                        d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                      />
                    </svg>
                  </div>
                  <span>
                    Address and <br /> Ownership
                  </span>
                </div>

                <div className="common-step step2 position-relative">
                  <div className="stepbox process">02</div>
                  <span>Property Details</span>
                </div>

                <div className="common-step step3 position-relative">
                  <div className="stepbox uundeprocss">03</div>
                  <span>Value</span>
                </div>

                <div className="common-step step4 position-relative">
                  <div className="stepbox uundeprocss">04</div>
                  <span>
                    Images and <br /> Video
                  </span>
                </div>

                <div className="common-step step5 position-relative">
                  <div className="stepbox uundeprocss">05</div>
                  <span>Review and Submit</span>
                </div>
              </div>

              <div className="buyer-stepper-form">
                <div className="head-col">
                  <h3>Please confirm your details</h3>
                  <a href="#">Amend</a>
                </div>

                <Form className="confirmation-form ero-col address-ownership">
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup className="position-relative">
                        <div className="reals-me-first">
                          <Label for="exampleEmail">Your Price</Label>
                        </div>
                        <Input
                          type="number"
                          name="email"
                          id="exampleEmail"
                          placeholder="Marry Jackson"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <FormGroup className="position-relative">
                        <div className="reals-me-first">
                          <Label for="exampleEmail">Phone number</Label>
                        </div>
                        <Input
                          type="tel"
                          name="email"
                          id="exampleEmail"
                          placeholder="7888888959"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="position-relative">
                        <div className="reals-me-first">
                          <Label for="exampleEmail">Email address</Label>
                        </div>
                        <Input
                          type="number"
                          name="email"
                          id="exampleEmail"
                          placeholder="Jimmyni.01@gmail.com"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className="position-relative">
                        <div className="reals-me-first">
                          <Label for="exampleEmail">Address</Label>
                        </div>
                        <Input
                          type="text"
                          name="email"
                          id="exampleEmail"
                          placeholder="Balistreri, Center North, Eloise, Avon"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="floor-plan-btns steps-btn mt-5">
                    {/* <Button className="back-btn"> Back</Button> */}
                    <Button
                      className="Continue-btn"
                      onClick={() => history.push("/buyerstep2")}
                    >
                      {" "}
                      Continue
                    </Button>
                  </div>
                </Form>
              </div>
            </Fade>
          </div>
        </div>
      </Container>
      <ScrollTop />
      <Footer />
    </>
  );
}
