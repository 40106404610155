import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import close_icon from "assets/svg/close_icon.svg";

// images
import Vedioimg from "assets/images/vedio-img.jpg";

const Modaladdress = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: 419 }}
        className="name-address-modal"
      >
        <Modal.Header>
          <h4 className="mt-4 mb-0 name_addres">Name & Address</h4>
          <p className="m-0 close_btn " onClick={props?.onHide}>
            {/* <CloseButton
              variant="white"

              disabled={false}
              style={{ width: "15px", height: "15px", color: "#fff" }}
            /> */}
            <img
              src={close_icon}
              style={{ width: "18px", height: "18px", color: "#fff" }}
            />
          </p>
        </Modal.Header>
        <Modal.Body className="adress-modal-main">
          <div className="vedio-modal">
            <div className="vedio-modal text-center">
              <div className="evidence-proof">
                <h6>Evidence of Name</h6>
                <p>Provide one from the following options:</p>
                <ul>
                  <li>Passport</li>
                  <li>Driving licence</li>
                  <li>Inland Revenue Tax Notification</li>
                </ul>
                <div className="upload-btn-wrapper">
                  <button className="btn">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--ooui"
                      width="16"
                      height="16"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#190835"
                        d="M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z"
                      ></path>
                      <path fill="#190835" d="M10 1L5 7h4v8h2V7h4z"></path>
                    </svg>
                    Upload image
                  </button>
                  <input type="file" name="myfile" />
                </div>
              </div>

              <div className="evidence-proof mt-3">
                <h6>Evidence of Address</h6>
                <p>
                  Provide one from the following options (dated within the last
                  3 months):
                </p>
                <ul>
                  <li>Mortgage statement</li>
                  <li>Bank or Building Society Statement</li>
                  <li>Current Local Authority Tax Bill</li>
                  <li>Utility Bill (not mobile phone)</li>
                </ul>
                <div className="upload-btn-wrapper">
                  <button className="btn">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--ooui"
                      width="16"
                      height="16"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#190835"
                        d="M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z"
                      ></path>
                      <path fill="#190835" d="M10 1L5 7h4v8h2V7h4z"></path>
                    </svg>
                    Upload image
                  </button>
                  <input type="file" name="myfile" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Modaladdress;
