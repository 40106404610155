import React, { useState, useEffect } from "react";

import { Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DatePicker from "sassy-datepicker";


// images
import Cardone from "assets/images/card-one.jpg";

const Availabilitymid = () => {
  const [date, setDate] = useState(new Date());
  const onChange = (newDate) => {
    console.log(`New date selected - ${newDate.toString()}`);
    setDate(newDate);
  };
  useEffect(() => {
    let test = document.getElementsByClassName("sdp--text__inactive");
    if (test) {
      test[0].innerHTML = "S";
      test[1].innerHTML = "M";
      test[2].innerHTML = "T";
      test[3].innerHTML = "W";
      test[4].innerHTML = "T";
      test[5].innerHTML = "F";
      test[6].innerHTML = "S";
    }
  }, []);
  const history = useHistory();
  return (
    <section className="Floorplanmid-main">
      <Container>
        <div className="wrap-box wrap-date">
        <div className="go-back-div epcsellerback">
          <p>
            <svg aria-hidden="true" role="img" className="iconify iconify--ic" width="22" height="22" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"></path>
            </svg>Back
            </p>
            <Button className="lockright">
            <svg  role="img" className="iconify iconify--bx" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M5 22h14a2 2 0 0 0 2-2V9a1 1 0 0 0-1-1h-3v-.777c0-2.609-1.903-4.945-4.5-5.198A5.005 5.005 0 0 0 7 7v1H4a1 1 0 0 0-1 1v11a2 2 0 0 0 2 2zm12-12v2h-2v-2h2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-2 3h2v2H7v-2z" fill="#fff"/></svg>
            </Button>
        </div>
          <div className="floorplan-box pro-valuation availability-here">
            <h5 className="text-center">Select your availability</h5>
           
             
             <div className="calende-availalbity">

              <div className="monthselector">
                  <p>October 2018</p>
                  <p className="activedate">November 2018</p>
                  <p>December 2018</p>
                  
              </div>
             <DatePicker
                  onChange={onChange}
                  selected={date}
                  style={{ width: "100%" }}
                />

                <div className="slot-available">
                    <p className="slots-available">SLOTS AVAILABLE</p>
                    <ul>
                      <li>
                      11:00
                      </li>
                      <li>14:30</li>
                      <li>15:00</li>
                      <li>14:30</li>
                      <li>15:00</li>
                      <li className="activeslot">18:00</li>
                    </ul>
                </div>
             </div>



            <div className="floor-plan-btns mt-4">
              <Button className="back-btn"> Add to Checkout</Button>
              <Button className="Continue-btn"> Make Payment</Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Availabilitymid;
