import {
  getBuyerProperties,
  getSellerProperties,
  getSellerUploadProperties,
  getUserById,
  uploadMedia,
} from "backend_helper/api_helper";

export const uploadMediaRequest = ({ payload, onSuccess }) => {
  uploadMedia(payload).then(({ data }) => {
    data?.isSuccess && onSuccess && onSuccess(data?.data);
  });
};

export const getSellerPropertiesRequest = ({ payload, onSuccess }) => {
  getSellerProperties(payload).then(({ data }) => {
    data?.isSuccess && onSuccess && onSuccess(data);
  });
};

export const getSellerUploadPropertiesRequest = ({ payload, onSuccess }) => {
  getSellerUploadProperties(payload).then(({ data }) => {
    data?.isSuccess && onSuccess && onSuccess(data);
  });
};

export const getsellerSoldListPropertiesRequest = ({ payload, onSuccess }) => {
  getSellerUploadProperties(payload).then(({ data }) => {
    data?.isSuccess && onSuccess && onSuccess(data);
  });
};

export const getBuyerPropertiesRequest = ({ onSuccess }) => {
  getBuyerProperties().then(({ data }) => {
    data?.isSuccess && onSuccess && onSuccess(data);
  });
};

export const getUserByIdRequest = ({ id, onSuccess }) => {
  getUserById(id).then(({ data }) => {
    data?.isSuccess && onSuccess && onSuccess(data?.data);
  });
};

export const getLocation = () => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      const { latitude, longitude } = position.coords;
      console.log("LOCATION GET ++>==", position.coords);
      // alert(`Latitude: ${latitude}, Longitude: ${longitude}`);
    },
    (error) => {
      alert(
        "Location is required for this website. Please enable geolocation in your browser settings."
      );
    },
    { enableHighAccuracy: true }
  );
};
