import * as CONST from "./actionType";

const { data, ...authUser } = JSON.parse(
  localStorage.getItem("authUser") || "{}"
);

let initialState = {
  usre: data || {},
  authToken: data ? data?.token || null : null,
  loading: false,
};

const SignUp = (state = initialState, action) => {
  switch (action.type) {
    case CONST.SIGNUP:
    case CONST.SEND_OTP_VERIFY:
    case CONST.LOGIN_USER:
    case CONST.SEND_OTP:
      return {
        ...state,
        loading: true,
      };
      break;
    case CONST.SIGNUP_SUCCESS:
    case CONST.SIGNUP_FAIL:
    case CONST.SEND_OTP_SUCCESS:
    case CONST.SEND_OTP_FAIL:
    case CONST.UPDATE_TOKEN2:
    case CONST.SEND_OTP_VERIFY_FAIL:
    case CONST.SEND_OTP_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
      break;
    case CONST.LOGIN_SUCCESS:
      return {
        ...state,
        usre: action.payload.data,
        authToken: action.payload.data?.token,
        loading: false,
      };
      break;
    case CONST.UPDATE_TOKEN:
      return {
        ...state,
        usre: action.payload,
        authToken: null,
        loading: false,
      };
      break;
    case CONST.API_ERROR:
      return { ...state, error: action.payload, loading: false };
      break;
    default:
      return { state };
      break;
  }
};
export default SignUp;
