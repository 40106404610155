import React from "react";
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import Settingsidebar from "./Settingsidebar";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Container } from "react-bootstrap";

const Changepassword = () => {
  return (
    <div>
      <Header />
      <Container>
      <div className="seller_container">
        <Settingsidebar />
        <div className="profile_right_side">
          <div className="d-flex justify-content-between">
            <h4 className="dashboard_text">Change Password</h4>
          </div>

          <div className="passwrod-chnge-form">
            <Form>
              <FormGroup className="for-wrap">
                <Label for="exampleEmail">Old Password</Label>
                <Input
                  type="password"
                  name="Password"
                  id="exampleEmail"
                  placeholder="*********"
                />
              </FormGroup>
              <FormGroup className="for-wrap">
                <Label for="exampleEmail">New Password</Label>
                <Input
                  type="password"
                  name="Password"
                  id="exampleEmail"
                  placeholder="*********"
                />
              </FormGroup>

              <FormGroup className="for-wrap">
                <Label for="exampleEmail">Confirm Password</Label>
                <Input
                  type="password"
                  name="Password"
                  id="exampleEmail"
                  placeholder="*********"
                />
              </FormGroup>

              <Button className="update-password">Update Password</Button>
              
            </Form>
          </div>
        </div>
      </div>
      </Container>
     
      <Footer />
    </div>
  );
};

export default Changepassword;
