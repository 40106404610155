import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "react-bootstrap";
// import SLidercard from "pages/Home/slidercard";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

// images
import Cardone from "assets/images/card-one.jpg";

const data = [
  {
    id: "1",
    img: "/img/auction_1.jpg",
    title: "Walking On Air",
    owner: "Richard",
    price: "1.5 ETH",
    count: "1 of 1",
    btnText: "Place a Bid",
  },
];

function Sliderproperties() {
  const history = useHistory();
  const goToDetail = () => {
    history.push('/profile/details-page')
  }
  var settings = {
    arrow: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="near-properties">
      <Container>
        <div className="slider-head">
          <h3>Premium Featured - New to the Market</h3>
        </div>

        <div className="slider-main">
          <Slider {...settings}>
            <div>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property" type="" onClick={goToDetail}>View Property</Button>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property" onClick={goToDetail} type="button">View Property</Button>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property" onClick={goToDetail} type="button">View Property</Button>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property" onClick={goToDetail} type="button">View Property</Button>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property" onClick={goToDetail} type="button">View Property</Button>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="card-main">
                <CardImg top width="100%" src={Cardone} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-heading">3 BHK Flat</CardTitle>

                  <CardText className="card-address">
                    <div className="cardlocation-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.635"
                        height="15"
                        viewBox="0 0 12.635 15"
                      >
                        <path
                          id="Path_3773"
                          data-name="Path 3773"
                          d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                          transform="translate(-155.5 -334.818)"
                          fill="#39f"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                    301 Dort Walks, Stockton, California
                  </CardText>
                  <p className="properties-prize">£ 150,000,00</p>
                  <Button className="view-property" onClick={goToDetail} type="button">View Property</Button>
                </CardBody>
              </Card>
            </div>
          </Slider>
        </div>
      </Container>
    </section>
  );
}

export default Sliderproperties;
