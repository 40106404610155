import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Provaluationmid from "./Provaluationmid";

import ScrollTop from "components/scrollTop";

const Provaluation = () => {
  return (
    <div>
      <ScrollTop />
      <Header />
      <Provaluationmid/>
      <Footer />
    </div>
  );
};

export default Provaluation;
