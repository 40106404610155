import React, { Component, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import Accordion from "react-bootstrap/Accordion";

import Modal from "./Modalnew";
import Modaladdress from "./Modaladdress";

// images\
import Valuation from "assets/images/valuation.png";
import { Link, useHistory } from "react-router-dom";

const Middleverify = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const history = useHistory();

  const [state, setState] = useState({
    tab: "tab1",
  });
  return (
    <React.Fragment>
      <Modal show={modalShow} onHide={() => setModalShow(false)} />

      <Modaladdress show={modalAddress} onHide={() => setModalAddress(false)} />

      <section className="verify-middle">
        <Container>
          <Link to={'/'} className="go-back-at">
            <div className="go-back-div">
              <p>
                <svg
                  aria-hidden="true"
                  role="img"
                  className="iconify iconify--ic"
                  width="22"
                  height="22"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"
                  />
                </svg>
                Go back to Home
              </p>
            </div>
          </Link>

          <div className="propety-details-box">
            <div className="tab-frame verify-frame tab-new-verrify">
              <div className="clearfix tabing-clear">
                <input
                  type="radio"
                  name="tab"
                  id="tab1"
                  checked={state.tab == "tab1"}
                  onClick={() => setState({ tab: "tab1" })}
                />
                <label for="tab1">Verify</label>

                <input
                  type="radio"
                  name="tab"
                  id="tab2"
                  checked={state.tab == "tab2"}
                  onClick={() => setState({ tab: "tab2" })}
                />
                <label for="tab2">Legals & Services</label>

                <input
                  type="radio"
                  name="tab"
                  id="tab3"
                  checked={state.tab == "tab3"}
                  onClick={() => setState({ tab: "tab3" })}
                />
                <label for="tab3">Preferences</label>
              </div>

              {state.tab === "tab1" && (
                <div className="Verify-online">
                  <Row>
                    <Col lg={5} md={6} sm={12}>
                      <div className="vedio-verification position-relative">
                        <div className="vedio-cont">
                          <div className="vedio-icon">
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--ic"
                              width="18"
                              height="18"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ffffff"
                                d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l2.29 2.29c.63.63 1.71.18 1.71-.71V8.91c0-.89-1.08-1.34-1.71-.71L17 10.5z"
                              />
                            </svg>
                          </div>

                          <div className="vedio-cont-head">
                            <h6>Video Verification</h6>
                            <p>
                              To protect our buyers and sellers video
                              verification is required before viewings can take
                              place. All video verification will remain
                              confidential and will not be shared with buyers or
                              sellers.
                            </p>
                            <Button
                              className="verify-btn"
                              onClick={() => setModalShow(true)}
                            >
                              Verify
                            </Button>
                          </div>
                        </div>
                        <div className="exclamation-new">
                          <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--zondicons"
                            width="22"
                            height="22"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="#F52F2F"
                              d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </Col>

                    <Col lg={5} md={6} sm={12}>
                      <div className="vedio-verification position-relative">
                        <div className="vedio-cont">
                          <div className="vedio-icon">
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--fa6-solid"
                              width="18"
                              height="18"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96c0-35.35-28.7-64-64-64zm-336 96c35.35 0 64 28.65 64 64s-28.65 64-64 64s-64-28.65-64-64s28.7-64 64-64zm96 256H80c-8.84 0-16-7.2-16-16c0-44.2 35.82-80 80-80h64c44.18 0 80 35.82 80 80c0 8.8-7.2 16-16 16zm224-64H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0-64H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0-64H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.8 0 16 7.2 16 16s-7.2 16-16 16z"
                              />
                            </svg>
                          </div>

                          <div className="vedio-cont-head">
                            <h6>Name & Address</h6>
                            <p>
                              To protect our buyers and sellers video
                              verification is required before viewings can take
                              place. All video verification will remain
                              confidential and will not be shared with buyers or
                              sellers.
                            </p>
                            <Button
                              className="verify-btn"
                              onClick={() => setModalAddress(true)}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                        <div className="right-check">
                          <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--ic"
                            width="22"
                            height="22"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#05D48B"
                              d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {state.tab === "tab2" && (
                <div className="Legals-msg">
                  <div className="legal-col">
                    <h4>Legals</h4>
                    <div className="plans">
                      <label className="plan basic-plan" for="basic">
                        <input checked type="radio" name="plan" id="basic" />
                        <div className="plan-content">
                          <img src={require(`assets/images/solicitors.png`)} />

                          <div className="plan-details">
                            <span>Solicitor</span>
                          </div>
                        </div>
                      </label>

                      <label className="plan complete-plan" for="complete">
                        <input checked type="radio" id="complete" name="plan" />
                        <div className="plan-content">
                          <img src={Valuation} />

                          <div className="plan-details">
                            <span>Memo of sale</span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="legal-col">
                    <h4>Services</h4>
                    <div className="plans">
                      <label className="plan EPC-plan" for="EPC">
                        <input checked type="radio" name="plan" id="EPC" />
                        <div
                          className="plan-content"
                          onClick={() => history.push("/epcseller")}
                        >
                          <img src={require(`assets/images/gg.png`)} />
                          <div className="plan-details">
                            <span>EPC</span>
                          </div>
                        </div>
                      </label>

                      <label className="plan Mortgage-plan" for="Mortgage">
                        <input checked type="radio" id="Mortgage" name="plan" />
                        <div
                          className="plan-content"
                          onClick={() => history.push("/mortage-calculator")}
                        >
                          <img src={require(`assets/images/mortgage.png`)} />

                          <div className="plan-details">
                            <span>Mortgage Calculator</span>
                          </div>
                        </div>
                      </label>

                      <label className="plan Floor-plan" for="Floor">
                        <input checked type="radio" id="Floor" name="plan" />
                        <div
                          className="plan-content"
                          onClick={() => history.push("/seller-floorplan")}
                        >
                          <img src={require(`assets/images/floorplan.png`)} />

                          <div className="plan-details">
                            <span>Floor plan</span>
                          </div>
                        </div>
                      </label>

                      <label className="plan Brokers-plan" for="Brokers">
                        <input checked type="radio" id="Brokers" name="plan" />
                        <div
                          className="plan-content"
                          onClick={() => history.push("/broker")}
                        >
                          <img src={require(`assets/images/floorplan.png`)} />

                          <div className="plan-details">
                            <span>Brokers</span>
                          </div>
                        </div>
                      </label>

                      <label className="plan Property-plan" for="Property">
                        <input checked type="radio" id="Property" name="plan" />
                        <div
                          className="plan-content"
                          onClick={() => history.push("/provaluation")}
                        >
                          <img src={require(`assets/images/valuation.png`)} />
                          <div className="plan-details">
                            <span>Property Valuation</span>
                          </div>
                        </div>
                      </label>

                      {/* <label className="plan RICS-plan" for="RICS">
                        <input checked type="radio" id="RICS" name="plan" />
                        <div className="plan-content">
                          <img src={Valuation} />

                          <div className="plan-details">
                            <span>RICS</span>
                          </div>
                        </div>
                      </label> */}

                      <label className="plan Cleaner-plan" for="Cleaner">
                        <input checked type="radio" id="Cleaner" name="plan" />
                        <div
                          className="plan-content"
                          onClick={() => history.push("/cleaners")}
                        >
                          <img src={require(`assets/images/cleaners.png`)} />
                          <div className="plan-details">
                            <span>Cleaner</span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {state.tab === "tab3" && (
                <div className="Preferences">
                  <div className="forms-preference">
                    <Form className="preference-form">
                      <div className="form-div-main">
                        <h6>Viewings Preferences</h6>

                        <Row>
                          <Col lg={12}>
                            <div className="check-bg">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" />
                                  Video Call Tour
                                </Label>
                              </FormGroup>
                            </div>
                          </Col>

                          <Col l={12}>
                            <div className="check-bg">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" />
                                  On-site viewing
                                </Label>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>

                        <div className="availability-div">
                          <h5>Please enter your availability</h5>
                          <FormGroup>
                            <Label for="exampleEmail">
                              Select date and slot
                            </Label>
                            <Input type="date" name="date" id="exampleEmail" />
                          </FormGroup>
                        </div>
                      </div>

                      <div className="preference-acco">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Buyer Type</Accordion.Header>
                            <p className="please-selct">
                              Please select your preferred buyer.
                            </p>

                            <Accordion.Body>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit in voluptate velit esse
                              cillum dolore eu fugiat nulla pariatur. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Offers</Accordion.Header>
                            <p className="please-selct">Notify me when</p>

                            <Accordion.Body>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit in voluptate velit esse
                              cillum dolore eu fugiat nulla pariatur. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum.
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Middleverify;
