import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import DetailsSolicitora from 'pages/DetailsSolicitor/DetailsSolicitormid'


const DetailsSolicitor = () => {
    return (
        <div>
         <Header/>
          <DetailsSolicitora/>
         <Footer/>
            
        </div>
    );
}

export default DetailsSolicitor;
