import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

// images
import Cardone from "assets/images/card-one.jpg";

const Memorandummid = () => {
  return (
    <section className="Memorandummid">
      <Container>
        <div className="memorandum-main">
          <h5 className="text-center heading-memo">Memorandum of Sale</h5>
          <div className="agree-priced mt-5">
            <p>Agreed Price</p>
            <p className="price-here">159999/-</p>
          </div>

          <div className="vendor-info mt-4">
            <h5>VENDOR INFO</h5>
            <Form className="vedor-form">
              <Row>
                <Col lg={2} md={4} sm={12}>
                  <FormGroup>
                    <Label for="exampleSelect">Title</Label>
                    <Input type="select" name="select" id="exampleSelect">
                      <option>Mr.</option>
                      <option>Mrs.</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col lg={10} md={8} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Full Name</Label>
                    <Input
                      type="text"
                      name="text"
                      id="name"
                      placeholder="Marry Jackson"
                    />
                  </FormGroup>
                </Col>

                <Col lg={5} md={4} sm={12}>
                  <FormGroup>
                    <Label for="exampleSelect">Phone number</Label>
                    <Input
                      type="tel"
                      name="number"
                      id="number"
                      placeholder="7888888959"
                    ></Input>
                  </FormGroup>
                </Col>

                <Col lg={7} md={8} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Email ID</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Jimmyni.01@gmail.com"
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Address</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Balistreri, Center North, Eloise, Avon"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>

          <div className="vendor-info mt-4">
            <h5>VENDOR'S CONVEYANCING INFO</h5>
            <Form className="vedor-form">
              <Row>
                <Col lg={2} md={4} sm={12}>
                  <FormGroup>
                    <Label for="exampleSelect">Title</Label>
                    <Input type="select" name="select" id="exampleSelect">
                      <option>Mr.</option>
                      <option>Mrs.</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col lg={10} md={8} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Full Name</Label>
                    <Input
                      type="text"
                      name="text"
                      id="name"
                      placeholder="Marry Jackson"
                    />
                  </FormGroup>
                </Col>

                <Col lg={5} md={4} sm={12}>
                  <FormGroup>
                    <Label for="exampleSelect">Phone number</Label>
                    <Input
                      type="tel"
                      name="number"
                      id="number"
                      placeholder="7888888959"
                    ></Input>
                  </FormGroup>
                </Col>

                <Col lg={7} md={8} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Email ID</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Jimmyni.01@gmail.com"
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Address</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Balistreri, Center North, Eloise, Avon"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>

          <div className="vendor-info mt-4 property-details">
            <h5>PROPERTY DETAILS</h5>
            <Form className="vedor-form">
              <Row>
                  <Col lg={12}>
                
                <div className="lebelyeno-col tabbo-form mb-3">
                      <label className="lebeyesno lebel-yes" for="foryesn">
                        <input checked type="radio" name="plan" id="foryesn" />
                        <div className="plan-yesno">
                          <span>Freehold</span>
                        </div>
                      </label>

                      <label className="lebeyesno lebel-no" for="fornon">
                        <input checked type="radio" id="fornon" name="plan" />
                        <div className="plan-yesno">
                          <span>Leasehold</span>
                        </div>
                      </label>
                    </div>
                
               
                  </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Years remaining on lease</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="5 Years"
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Has Ownership been checked?
                    </Label>

                    <div className="lebelyeno-col">
                      <label className="lebeyesno lebel-yes" for="foryes">
                        <input checked type="radio" name="plan" id="foryes" />
                        <div className="plan-yesno">
                          <span>Yes</span>
                        </div>
                      </label>

                      <label className="lebeyesno lebel-no" for="forno">
                        <input type="radio" id="forno" name="plan" />
                        <div className="plan-yesno">
                          <span>No</span>
                        </div>
                      </label>
                    </div>
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Desired speed of Completion:
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Lorem ipsum dummy text ito"
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Expected Changes:</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Lorem ipsum dummy text ito"
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Expected Completion date:</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Lorem ipsum dummy text ito"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>

          <div className="vendor-info mt-4">
            <h5>PROPERTY DETAILS</h5>
            <Form className="vedor-form">
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Special conditions of Sale:
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Balistreri, Center North, Eloise, Avon"
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Any legal Issues:</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Balistreri, Center North, Eloise, Avon"
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Fixtures or fittings included:
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Balistreri, Center North, Eloise, Avon"
                    />
                  </FormGroup>
                </Col>

                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Extra Notes:</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Balistreri, Center North, Eloise, Avon"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button className="sbmit-btn">Submit</Button>
            </Form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Memorandummid;
