import React from "react";
import Fade from "react-reveal/Fade";
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "../Sidebar";
import { Accordion, Button, Container } from "react-bootstrap";
import ScrollTop from "components/scrollTop";

export default function FAQ() {
  return (
    <>
      <ScrollTop />
      <Header />
      <Container>
        <div className="seller_container">
          <Sidebar />
          <div className="profile_right_side">
            <div className="d-flex justify-content-between">
              <h4 className="dashboard_text">Get help (FAQS)</h4>
              <p className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </p>
            </div>

            <div className="faq_container">
              <div className="faq_search">
                <input type="text" placeholder="Search FAQs" />
              </div>
              <div className="accordion_section">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is Floorplan</Accordion.Header>
                    <Accordion.Body>
                      <div className="accrodion_text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What is EPC</Accordion.Header>
                    <Accordion.Body>
                      <div className="accrodion_text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
}
