import React from "react";
import { Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

// images
import Cardone from "assets/images/card-one.jpg";

const Sellerfloorplanmid = () => {
  const history = useHistory();
  return (
    <section className="Floorplanmid-main">
      <Container>
        <div className="wrap-box wrap-boxepcselller">
          <div
            className="go-back-div epcsellerback"
            onClick={() => history.goBack()}
          >
            <p>
              <svg
                aria-hidden="true"
                role="img"
                className="iconify iconify--ic"
                width="22"
                height="22"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"
                ></path>
              </svg>
              Back
            </p>
            <Button className="lockright">
              <svg
                role="img"
                className="iconify iconify--bx"
                width="20"
                height="20"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  d="M5 22h14a2 2 0 0 0 2-2V9a1 1 0 0 0-1-1h-3v-.777c0-2.609-1.903-4.945-4.5-5.198A5.005 5.005 0 0 0 7 7v1H4a1 1 0 0 0-1 1v11a2 2 0 0 0 2 2zm12-12v2h-2v-2h2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-2 3h2v2H7v-2z"
                  fill="#fff"
                />
              </svg>
            </Button>
          </div>
          <div className="floorplan-box seelerfloorplan">
            <h5 className="text-center">Floor Plan</h5>

            <div className="floorplan-here">
              <div className="floor-upload">
                <div className="floor-img-upload position-relative">
                  <input type="file" className="custom-file-input" />
                  <div className="imges-iocn text-center">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--bi"
                      width="32"
                      height="32"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 16 16"
                    >
                      <g fill="#C0C0C0">
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z" />
                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71l-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                      </g>
                    </svg>
                    <br></br>
                    Upload
                  </div>
                </div>
                <p>OR</p>
                <div className="floor-img-upload floor-img-uploadwo position-relative">
                  <div className="epc-main-right create-floorplan text-center">
                    <svg
                      className="iconify iconify--ic"
                      width="26"
                      height="26"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#001978"
                        d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75l1.83-1.83z"
                      />
                    </svg>
                    <p>Create floor Plan</p>
                    <Form className="inputchek">
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" />{" "}
                        </Label>
                      </FormGroup>
                    </Form>
                  </div>
                </div>

                <p>OR</p>

                <div className="floor-img-upload floor-img-uploadwo position-relative">
                  <div className="epc-main-right Purchase-floorplan text-center">
                    <p>
                      Purchase Floorplan <br></br>services £25
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="floor-plan-btns quation-btn">
              <Button className="back-btn"> Add to Checkout</Button>
              <Button className="Continue-btn"> Make Payment</Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Sellerfloorplanmid;
