import React from "react";
import { useState } from "react";
import Select from "react-select";

/**
 * @param {Object} param0
 * @param {Array<{label:String, value:String}>} param0.options
 * @param {string} param0.value
 * @param {string} param0.name
 * @param {string} param0.placeholder
 * @param {(e:Event) => void} param0.onChange
 */

export default function CustomSelect({
  onChange,
  options,
  placeholder,
  value,
  name,
}) {
  const [v, setValue] = useState(options.find((i) => i.value === value));

  React.useEffect(() => {
    if (v?.value)
      onChange &&
        onChange({
          target: {
            value: v.value,
            name,
          },
        });
  }, [v]);

  return (
    <Select
      options={options}
      placeholder={placeholder}
      value={v}
      onChange={(newValue) => {
        setValue(newValue);
      }}
    />
  );
}
