import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const NewTe = () => {
  const history = useHistory();
  return (
    <Container>
      <div className="new_csdd">
        <div className="legal-col pt-0">
          <h4>Legals & Services</h4>
          <div className="plans">
            <label className="plan Property-plan" for="Property">
              <input type="radio" id="Property" name="plan" />
              <div
                className="plan-content"
                onClick={() => history.push("/solicitor")}
              >
                <img src={require(`assets/images/valuation.png`)} />
                <div className="plan-details">
                  <span>Solicitor</span>
                </div>
              </div>
            </label>
            <label className="plan EPC-plan" for="EPC">
              <input type="radio" name="plan" id="EPC" />
              <div
                className="plan-content"
                onClick={() => history.push("/mortage-calculator")}
              >
                <img src={require(`assets/images/gg.png`)} />
                <div className="plan-details">
                  <span>Mortgage Calculator</span>
                </div>
              </div>
            </label>

            <label className="plan Mortgage-plan" for="Mortgage">
              <input type="radio" id="Mortgage" name="plan" />
              <div
                className="plan-content"
                onClick={() => history.push("/mortage-calculator")}
              >
                <img src={require(`assets/images/mortgage.png`)} />

                <div className="plan-details">
                  <span>Memo of sale</span>
                </div>
              </div>
            </label>

            <label className="plan Brokers-plan" for="Brokers">
              <input type="radio" id="Brokers" name="plan" />
              <div className="plan-content" onClick={() => history.push("/broker")}>
                <img src={require(`assets/images/floorplan.png`)} />

                <div className="plan-details">
                  <span>Brokers</span>
                </div>
              </div>
            </label>
            <label className="plan Floor-plan" for="Floor">
              <input type="radio" id="Floor" name="plan" />
              <div
                className="plan-content"
                onClick={() => history.push("/seller-floorplan")}
              >
                <img src={require(`assets/images/floorplan.png`)} />

                <div className="plan-details">
                  <span>Removals</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NewTe;
