import React, { useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import ScrollTop from "components/scrollTop";
import "./Modal.css";
import PropertiesCard from "./propertiesCard";

import GetLocalUser from "store/localStorage";
import {
  getSellerUploadPropertiesRequest,
  getsellerSoldListPropertiesRequest,
  getUserByIdRequest,
} from "utils/helper";

function Listing(props) {
  const [tab, setTab] = useState("tab1");
  const [showModal, setShowModal] = useState(false);
  const [isActive, setisActive] = useState("Upload");

  // Data

  const localUser = GetLocalUser();
  const [user, setUser] = useState({});
  const [uploadPropeties, setUploadProperties] = React.useState([]);
  const [soldProperties, setSoldProperties] = React.useState([]);

  const getSellerUploadProperties = () => {
    const payload = `filter=created`;
    getSellerUploadPropertiesRequest({
      payload,
      onSuccess: (data) => {
        console.log(data?.items);
        setUploadProperties(data?.items);
      },
    });
  };

  const getsellerSoldListProperties = () => {
    const payload = `filter=listed`;
    getsellerSoldListPropertiesRequest({
      payload,
      onSuccess: (data) => {
        setSoldProperties(data?.items);
      },
    });
  };

  React.useEffect(() => {
    getUserByIdRequest({
      id: localUser?.id,
      onSuccess: (data) => setUser(data),
    });
  }, []);

  React.useEffect(() => {
    if (user?.userType) {
      getSellerUploadProperties();
      getsellerSoldListProperties();
    }
  }, [user]);

  return (
    <>
      <ScrollTop />
      <Header />
      <div className="listingContainerBox">
        <div className="controllerContainer">
          <div className="controllerBox">
            <div
              onClick={() => setisActive("Upload")}
              className={`uploadButton ${
                isActive === "Upload" ? "activeButton" : "nonActiveButton"
              }`}
            >
              <div>Uploaded Listings</div>
            </div>
            <div
              onClick={() => setisActive("sold")}
              className={`soldButton ${
                isActive === "sold" ? "activeButton" : "nonActiveButton"
              }`}
            >
              <div>Sold Listings</div>
            </div>
          </div>
          <div className="content">
            {isActive == "Upload" &&
              uploadPropeties.map((val, key) => {
                return (
                  <PropertiesCard
                    key={key}
                    offerPrice={val.offerPrice}
                    coverPic={val.propertyImages[0].imgUrl}
                    firstPic={val.propertyImages[1].imgUrl}
                    houseType={
                      val.propertyType === "Residential"
                        ? `${val.residentialBedroomCount} Bedrooms ${val.residentialStyle} ${val.residentialType} on ${val.residentialTenure}`
                        : val.propertyType === "Commercial"
                        ? `${val.commercialType} on ${val.commercialTenure}`
                        : `${val.landSizeInAcres} Acres Land on ${val.landTenure}`
                    }
                    location={val.address}
                  />
                );
              })}
            {isActive == "sold" &&
              soldProperties.map((val, key) => {
                return (
                  <PropertiesCard
                    key={key}
                    offerPrice={val.offerPrice}
                    coverPic={val.propertyImages[0].imgUrl}
                    firstPic={val.propertyImages[1].imgUrl}
                    houseType={
                      val.propertyType === "Residential"
                        ? `${val.residentialBedroomCount} Bedrooms ${val.residentialStyle} ${val.residentialType} on ${val.residentialTenure}`
                        : val.propertyType === "Commercial"
                        ? `${val.commercialType} on ${val.commercialTenure}`
                        : `${val.landSizeInAcres} Acres Land on ${val.landTenure}`
                    }
                    location={val.address}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Listing;
