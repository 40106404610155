import { useState } from "react";

export default function GetLocalUser() {
  var user = localStorage.getItem("authUser");
  user = JSON.parse(user);
  return user || null;
}

export function SetLocalUser(user) {
  localStorage.setItem("authUser" , JSON.stringify(user));  
}
