import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Welcome from "assets/images/header_img.png";

// images
import Cardone from "assets/images/card-one.jpg";

const Buyerwlcmid = () => {
  const history = useHistory();
  return (
    <section className="Buyerwlcmid-main">
      <Container>
        <Row>
          <Col lg={4} md={6} sm={12} className="p-0">
            <div className="welcome-left">
              <h4>Welcome to UB SOLD</h4>
              <img src={Welcome} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col lg={8} md={6} sm={12} className="p-0">
            <div className="welcome-right">
              <h4>Hello John,</h4>
              <p className="color-aprt">
                The listing process is just 5 simple steps and if you have
                already taken your pictures and videos then even quicker!
              </p>

              <ul>
                <li>
                  <div className="main-number">
                    <div className="nuumber-col">01</div>
                    <p> Property Information</p>
                  </div>
                </li>
                <li>
                  <div className="main-number">
                    <div className="nuumber-col">02</div>
                    <p>Property Type</p>
                  </div>
                </li>
                <li>
                  <div className="main-number">
                    <div className="nuumber-col">03</div>
                    <p>value</p>
                  </div>
                </li>
                <li>
                  <div className="main-number">
                    <div className="nuumber-col">04</div>
                    <p>Images and Video</p>
                  </div>
                </li>
                <li>
                  <div className="main-number">
                    <div className="nuumber-col">05</div>
                    <p>Submit</p>
                  </div>
                </li>
              </ul>

              <p className="bottom-text">
                Once you've created your listing please video verify yourself
                and upload identity and ownership documents.
              </p>
              <p className="bottom-text">
                Finally, we have a number of services to help with your move,
                please do check these out.
              </p>

              <div className="hapy-cool text-center mt-5">
                <p>Happy Listing!</p>
                <Button
                  className="next-btn"
                  onClick={() => history.push("/step1")}
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Buyerwlcmid;
