import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

// images
import Cardone from "assets/images/card-one.jpg";

const Mortagecalmid = () => {
  const history = useHistory();
  return (
    <section className="Floorplanmid-main">
      <Container>
        <div className="wrap-box">
          <div className="go-back-div" onClick={() => history.goBack()}>
            <p>
              <svg
                aria-hidden="true"
                role="img"
                className="iconify iconify--ic"
                width="22"
                height="22"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"
                ></path>
              </svg>
              Back
            </p>
          </div>
          <div className="floorplan-box epcsellermain">
            <h5 className="text-center">Mortgage Calculator</h5>
            <Form className="vedor-form mortage-frm">
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Amount to borrow</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="£55445"
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Over how many years</Label>
                    <Input
                      type="number"
                      name="email"
                      id="exampleEmail"
                      placeholder="5"
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Mortgage interest rate</Label>
                    <Input
                      type="number"
                      name="email"
                      id="exampleEmail"
                      placeholder="5"
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">Repayment type</Label>

                    <div className="wrpslect">
                      <div className="slext">Capital & interest</div>
                      <div className="slexttwo">Interest Only</div>
                    </div>
                  </FormGroup>
                </Col>

                <Col lg={12} md={12} sm={12}>
                  <div className="wrapresult">
                    <div className="wrapresult-head text-center">
                      <h6>Your Result</h6>
                      <p>Repayment</p>
                    </div>
                    <FormGroup>
                      <Input
                        type="number"
                        name="email"
                        id="exampleEmail"
                        placeholder="£554.45"
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>

              <div className="floor-plan-btns mt-4">
                <Button className="back-btn"> Clear</Button>
                <Button className="Continue-btn"> Calculate</Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Mortagecalmid;
