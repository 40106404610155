import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "../Sidebar";
import ScrollTop from "components/scrollTop";
import { Container } from "react-bootstrap";
import bottom_arrow from "assets/svg/bottom_arrow.svg";

function SampleNextArrow() {
  return (
    <p className="m-0 bottom_arrow bottom_arrow_right ">
      <img src={bottom_arrow} />{" "}
    </p>
  );
}
export default function Contact() {
  const dropDowList = () => {
    return (
      <>
        <div className="contact_container sg-replace-icons contact_us">
          <p className="contact_heading">Recommended for you</p>{" "}
          <p className="contact_text">
            <label>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </label>
            {SampleNextArrow()}
          </p>
          <p className="contact_text">
            <label>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </label>
            {SampleNextArrow()}
          </p>
          <p className="contact_text">
            <label>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </label>
            {SampleNextArrow()}
          </p>
          <p className="contact_text">
            <label>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </label>
            {SampleNextArrow()}
          </p>
          <div className=" w-100 text-center">
            <button className="contact_submit">Submit</button>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <ScrollTop />
      <Header />

      <Container>
        <div className="seller_container ">
          <Sidebar />
          <div className="profile_right_side">
            <div className="d-flex justify-content-between">
              <h4 className="dashboard_text">Contact us</h4>
              <p className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </p>
            </div>
            {dropDowList()}
            {/* <div className="contact_container sg-replace-icons">
              <p className="contact_heading">Select your issue</p>{" "}
              <p className="contact_text">
                <input type="radio" /> <label>Lorem ipsum text hello</label>
              </p>
              <p className="contact_text">
                <input type="radio" /> <label>Lorem ipsum text hello</label>
              </p>
              <p className="contact_text">
                <input type="radio" /> <label>Lorem ipsum text hello</label>
              </p>
              <p className="contact_text">
                <input type="radio" /> <label>Other</label>
              </p>
              <div className="contact_input">
                <input type="text" placeholder="Type here" />
              </div>
              <div className=" w-100 text-center">
                <button className="contact_submit">Submit</button>
              </div>
            </div> */}
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
