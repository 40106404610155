import React, { useState } from "react";
import Stepper from "../../../components/Stepper";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

import { useHistory } from "react-router-dom";
import AddmusicModal from "./AddmusicModal";
import ScrollTop from "components/scrollTop";
import { useDispatch, useSelector } from "react-redux";
import { createPropertySelector } from "store/selectors";
import { RenderTag } from "./RenderTag";
import { DeleteIcon } from "./DeleteIcon";
import { createPropertySaveForm } from "store/actions";
import { AddEditTagModal } from "./AddEditTagModal";

export default function Stepfive() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const prevData = useSelector(createPropertySelector.stepHour);
  const dispatch = useDispatch();
  const [propertyImages, setImages] = React.useState(
    prevData.propertyImages || []
  );

  const [modalData, setModalData] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleUploadPlaceholderClick = (index, isCoverPhoto) => {
    setSelectedIndex(index);
    isCoverPhoto
      ? document.getElementById("input-file-front-view").click()
      : document.getElementById("input-file").click();
  };

  const onSelectFile = (isFrontView) => async (e) => {
    let file = e.target.files[0];

    let img = {
      file: file,
      base64: URL.createObjectURL(file),
      isCoverPhoto: false,
      isFrontView: isFrontView,
      position: selectedIndex,
      mediaType: "video",
    };

    setImages((_state) => {
      return _state.map((image) => {
        if (
          img.mediaType === image.mediaType &&
          img.position === image.position
        ) {
          return { ...img };
        }
        return image;
      });
    });
  };

  const deleteVideo = (idx) => {
    setImages((_state) =>
      _state.map((image) => {
        if (image.mediaType === "video" && image.position === idx) {
          return {
            mediaType: "video",
            position: idx,
            isCoverPhoto: false,
          };
        }
        return image;
      })
    );
  };

  const onSubmit = () => {
    const isValid = !propertyImages.find(
      ({ mediaType, file, tag }) => mediaType === "video" && (!file || !tag)
    );

    if (!isValid) {
      alert("All the tags and videos are required!");
      return;
    }

    dispatch(
      createPropertySaveForm({
        ...prevData,
        propertyImages,
      })
    );

    history.push("/step6");
  };

  return (
    <>
      <AddmusicModal
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
      />
      <ScrollTop />
      <Header />
      <section className="common-steps-main">
        <Container>
          <Row>
            <div className="list-main stepthree">
              <div className="list-heading">
                <h2>Property Video</h2>
                <a href="#">Watch Help Videos</a>
              </div>
              <div className="list-main-under">
                {/* <Stepper /> */}

                <div className="custom-steeper">
                  <div className="common-step step1 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                      Property <br /> information
                    </span>
                  </div>

                  <div className="common-step step2 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>Property details</span>
                  </div>

                  <div className="common-step step3 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>Value</span>
                  </div>

                  <div className="common-step step4  active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                      Images and <br /> Video
                    </span>
                  </div>

                  <div className="common-step step5 active-line position-relative">
                    <div className="stepbox process">05</div>
                    <span>
                      Submit <br></br> and review
                    </span>
                  </div>
                </div>

                <div className="wraping-col">
                  <div className="form-heading-steo4">
                    <div className="left-cont-srep4">
                      {/* <h3>Property Images</h3> */}
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: "20px",
                          color: "#190835",
                        }}
                      >
                        Upload video tour of your property
                      </p>
                    </div>
                    <input
                      id="input-file"
                      type="file"
                      accept="video/*"
                      onChange={onSelectFile(false)}
                      style={{ display: "none" }}
                    />
                  </div>

                  <div className="uploaded-images">
                    <Row>
                      <Col lg={6} md={6} sm={12}>
                        <div
                          className="left-cover-img position-relative"
                          style={{
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleUploadPlaceholderClick(0, true);
                          }}
                        >
                          <RenderTag
                            tag={
                              propertyImages?.find(
                                (item) =>
                                  item.mediaType === "video" &&
                                  item.position === 0
                              )?.tag
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalData({
                                position: 0,
                                tag: propertyImages?.find(
                                  (item) =>
                                    item.mediaType === "video" &&
                                    item.position === 0
                                )?.tag,
                              });
                            }}
                          />

                          {propertyImages?.find(
                            (item) =>
                              item.mediaType === "video" && item.position === 0
                          )?.base64 ? (
                            <div
                              className="imgbox"
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <video
                                src={
                                  propertyImages?.find(
                                    (item) =>
                                      item.mediaType === "video" &&
                                      item.position === 0
                                  )?.base64
                                }
                                alt=""
                                style={{
                                  height: "100%",
                                  zIndex: -1,
                                }}
                                className="img-fluid"
                              />
                              <DeleteIcon onClick={() => deleteVideo(0)} />
                            </div>
                          ) : (
                            <UploadVideoPlaceholder
                              style={{ height: "100%" }}
                            />
                          )}
                          <div className="cover-upload ">
                            <div className="file pointer">
                              <input
                                id="input-file-front-view"
                                type="file"
                                accept="video/*"
                                onClick={(e) => (e.target.value = null)}
                                onChange={onSelectFile(true)}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        <div className="right-box-img">
                          {propertyImages?.map(
                            (i, idx) =>
                              i.position !== 0 &&
                              i.mediaType === "video" &&
                              (i.base64 ? (
                                <div
                                  key={idx}
                                  className="imgbox position-relative overflow-hidden"
                                  style={{ width: "100%" }}
                                >
                                  <RenderTag
                                    style={{ fontSize: "11px" }}
                                    tag={i?.tag}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setModalData({
                                        position: idx - 5,
                                        tag: i?.tag,
                                      });
                                    }}
                                  />

                                  <video
                                    src={i.base64}
                                    alt=""
                                    style={{ height: "100%", width: "100%" }}
                                    className="img-fluid"
                                  />
                                  {/* <div className="cover-upload justify-content-end"> */}
                                  <DeleteIcon
                                    onClick={() => deleteVideo(idx - 5)}
                                  />
                                  {/* </div> */}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    position: "relative",
                                    width: "48%",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleUploadPlaceholderClick(
                                      idx - 5,
                                      false
                                    );
                                  }}
                                >
                                  <RenderTag
                                    style={{ fontSize: "11px" }}
                                    tag={i?.tag}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setModalData({
                                        position: idx - 5,
                                        tag: i?.tag,
                                      });
                                    }}
                                  />

                                  <UploadVideoPlaceholder key={idx} />
                                </div>
                              ))
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Form className="confirmation-form ero-col">
                    <div className="floor-plan-btns steps-btn mt-5">
                      <Button
                        className="back-btn"
                        onClick={() => history.push("/step4")}
                      >
                        {" "}
                        Back
                      </Button>
                      <Button className="Continue-btn" onClick={onSubmit}>
                        {" "}
                        Preview
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Footer />

      <AddEditTagModal
        modalData={modalData}
        onClose={() => setModalData(null)}
        onChange={({ position, value }) =>
          setImages((_state) => {
            return _state.map((image) => {
              console.log(position);
              if (
                image?.position === position &&
                image?.mediaType === "video"
              ) {
                return {
                  ...image,
                  tag: value,
                };
              }
              return image;
            });
          })
        }
      />
    </>
  );
}

const UploadVideoPlaceholder = ({ className, style }) => (
  <div className={`imgbox position-relative ${className || ""}`} style={style}>
    <svg
      aria-hidden="true"
      role="img"
      className="iconify iconify--bi"
      width="32"
      height="32"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 16 16"
    >
      <g fill="#cccccc">
        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z" />
        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71l-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
      </g>
    </svg>
  </div>
);

// <div className="wraping-col">
// <div className="form-heading-steo4">
//   <div className="left-cont-srep4">
//     {/* <h3>Property Video</h3> */}
//     <p>Upload video tour of your property</p>
//   </div>
//   {/* <div className="right-upload">
//   <div className="file">
//     <label for="input-file">
//       <svg
//         aria-hidden="true"
//         role="img"
//         className="iconify iconify--prime"
//         width="24"
//         height="24"
//         preserveAspectRatio="xMidYMid meet"
//         viewBox="0 0 24 24"
//       >
//         <path
//           fill="currentColor"
//           d="M18.22 20.75H5.78A2.64 2.64 0 0 1 3.25 18v-3a.75.75 0 0 1 1.5 0v3a1.16 1.16 0 0 0 1 1.25h12.47a1.16 1.16 0 0 0 1-1.25v-3a.75.75 0 0 1 1.5 0v3a2.64 2.64 0 0 1-2.5 2.75ZM16 8.75a.74.74 0 0 1-.53-.22L12 5.06L8.53 8.53a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06a.74.74 0 0 1-.53.22Z"
//         />
//         <path
//           fill="currentColor"
//           d="M12 15.75a.76.76 0 0 1-.75-.75V4a.75.75 0 0 1 1.5 0v11a.76.76 0 0 1-.75.75Z"
//         />
//       </svg>
//       Upload
//     </label>
//     <input id="input-file" type="file" />
//   </div>
// </div> */}
//   {/* <div className="buttom-addmusic">
//     <Button
//       className="buttom-addmusic-BTN"
//       onClick={handleShow}
//     >
//       Add Music
//     </Button>
//   </div> */}
// </div>

// {/* <div className="uploaded-images">
//   <Row>
//     <Col lg={6} md={6} sm={12}>
//       <div className="left-cover-img position-relative">
//         <div className="div-overlay-grey">
//           <div className="playicon">
//             <svg
//               aria-hidden="true"
//               role="img"
//               className="iconify iconify--material-symbols"
//               width="32"
//               height="32"
//               preserveAspectRatio="xMidYMid meet"
//               viewBox="0 0 24 24"
//             >
//               <path
//                 fill="#fff"
//                 d="M9.525 18.025q-.5.325-1.013.037Q8 17.775 8 17.175V6.825q0-.6.512-.888q.513-.287 1.013.038l8.15 5.175q.45.3.45.85t-.45.85Z"
//               />
//             </svg>
//           </div>
//         </div>
//         <img
//           src={Musiciadd}
//           alt=""
//           className="img-fluid for-music"
//         />
//         <div className="cover-upload msuic-upload">
//           <div className="file">
//             <label for="input-file">Upload vedio</label>
//             <input id="input-file" type="file" />
//           </div>
//           <div className="delete-icon">
//             <a href="#">
//               <div className="delete-cola">
//                 <svg
//                   id="ic_edit"
//                   width="32"
//                   height="32"
//                   viewBox="0 0 36 36"
//                 >
//                   <defs>
//                     <linearGradient
//                       id="linear-gradient"
//                       x1="0.5"
//                       x2="0.5"
//                       y2="1"
//                       gradientUnits="objectBoundingBox"
//                     >
//                       <stop offset="0" stop-color="#2d1159" />
//                       <stop offset="1" stop-color="#2d1159" />
//                     </linearGradient>
//                   </defs>
//                   <g
//                     id="Ellipse_528"
//                     data-name="Ellipse 528"
//                     transform="translate(0)"
//                     stroke="#fff"
//                     stroke-width="1"
//                     fill="url(#linear-gradient)"
//                   >
//                     <ellipse
//                       cx="18"
//                       cy="18"
//                       rx="18"
//                       ry="18"
//                       stroke="none"
//                     />
//                     <ellipse
//                       cx="18"
//                       cy="18"
//                       rx="17.5"
//                       ry="17.5"
//                       fill="none"
//                     />
//                   </g>
//                   <g
//                     id="Group_9076"
//                     data-name="Group 9076"
//                     transform="translate(10.762 9.376)"
//                   >
//                     <path
//                       id="Path_4875"
//                       data-name="Path 4875"
//                       d="M283.375,393.927h0a3.381,3.381,0,0,0-4.733.675l-7.505,10c-1.933,2.577-.1,5.769-.1,5.769s3.606.829,5.511-1.711l7.505-10A3.381,3.381,0,0,0,283.375,393.927Z"
//                       transform="translate(-270.25 -393.25)"
//                       fill="none"
//                       stroke="#fff"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                       stroke-width="1.2"
//                       fillRule="evenodd"
//                     />
//                     <path
//                       id="Path_4876"
//                       data-name="Path 4876"
//                       d="M276.5,396.211l5.407,4.058"
//                       transform="translate(-269.552 -392.92)"
//                       fill="none"
//                       stroke="#fff"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                       stroke-width="1.2"
//                     />
//                   </g>
//                 </svg>
//               </div>
//             </a>
//           </div>
//         </div>
//       </div>
//     </Col>
//   </Row>
// </div> */}

// <Form className="confirmation-form ero-col">
//   <div className="floor-plan-btns steps-btn mt-5">
//     <Button
//       className="back-btn"
//       onClick={() => history.push("/step4")}
//     >
//       {" "}
//       Back
//     </Button>
//     <Button
//       className="Continue-btn"
//       onClick={() => history.push("/step6")}
//     >
//       {" "}
//       Upload
//     </Button>
//   </div>
// </Form>
// </div>
