import React from "react";
import Stepper from "../../../components/Stepper";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Form, InputGroup } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import ScrollTop from "components/scrollTop";
import toast from 'react-hot-toast'
import {Container, Button} from "react-bootstrap";
export default function Coownerapproval() {
  const history = useHistory();

  return (
    <>
      <Header />
      <Container>
      <div className="list_your_property">
        <h2 className="list_title">List your property</h2>
        {/* <h2 className="list_title">List your property</h2> */}
        <div className="box_shadow_wrapper">
          <Fade>
            {/* <Stepper /> */}

            <div className="custom-steeper">
              <div className="common-step step1 position-relative">
                <div className="stepbox process">
                  {/* <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ic"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#111"
                      d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                    />
                  </svg> */}
                  01
                </div>
                <span>
                  {/* Property <br /> information */}
                  Address and <br/> Ownership
                </span>
              </div>

              <div className="common-step step2 position-relative">
                <div className="stepbox uundeprocss">02</div>
                <span>Property Details</span>
              </div>

              <div className="common-step step3 position-relative">
                <div className="stepbox uundeprocss">03</div>
                <span>Value</span>
              </div>

              <div className="common-step step4 position-relative">
                <div className="stepbox uundeprocss">04</div>
                <span>
                Images and<br /> Video
                
                </span>
              </div>

              <div className="common-step step5 position-relative">
                <div className="stepbox uundeprocss">05</div>
                <span>Review and<br /> Submit</span>
              </div>
            </div>

           
          
           <div className="Ownership-status list_start">
              <h5>Co-owner Approval</h5>
              <p>Do you have approval from all co-owners to list the property?</p>


              <div className="home_type coownertype">
                
                <div className="additional-features">
                  <div className="additional_feature ">
                    <input type="radio" value="1" id="ck-button" />
                    <label htmlFor="ck-button">Yes</label>
                  </div>
                  <div className="additional_feature2 ">
                    <input type="radio" value="1" id="ck-button2" />
                    <label htmlFor="ck-button2">No</label>
                  </div>
                 
                 
                </div>
              </div>


            </div>
          
           

          
          </Fade>
        </div>
      </div>
      </Container>

      <ScrollTop />
      <Footer />
    </>
  );
}
