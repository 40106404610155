import React, { useRef, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ReactMediaRecorder } from "react-media-recorder";
// images
import Vedioimg from "assets/images/vedio-img.jpg";
const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return (
    <div className="vedio-img-div">
      <video className="video-preview" ref={videoRef} controls />
    </div>
  );
};

const Modalnew = (props) => {
  const startBtnRef = useRef(null);
  const stopBtnRef = useRef(null);
  const [isVideoRecord, setIsVideoRecord] = useState(false);
  const [isVideoRecordCompleted, setIsVideoRecordCompleted] = useState(false);

  const recordVideo = () => {
    startBtnRef.current.click();
    console.log(startBtnRef);
    setIsVideoRecord(true);
    setTimeout(() => {
      setIsVideoRecord(false);
      stopBtnRef.current.click();
      setIsVideoRecordCompleted(true);
    }, 10000);
  };
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-vedio-col"
      >
        <Modal.Body className="vedio-modal-main">
          <div className="vedio-modal">
            <div className="vedio-modal text-center">
              <h4 className="mb-4">Upload your Video</h4>

              <ReactMediaRecorder
                video
                render={({
                  status,
                  startRecording,
                  stopRecording,
                  mediaBlobUrl,
                  previewStream,
                }) => (
                  <>
                    <div className="vedio-img-div">
                      {/* <img src={Vedioimg} className="img-fluid" /> */}
                      {/* <p>{status}</p> */}
                      <span hidden>
                        <button ref={startBtnRef} onClick={startRecording}>
                          Start Recording
                        </button>
                        <button ref={stopBtnRef} onClick={stopRecording}>
                          Stop Recording
                        </button>
                      </span>
                      {isVideoRecordCompleted && (
                        <video
                          className="video-preview"
                          src={mediaBlobUrl}
                          controls
                          autoPlay
                          
                        />
                      )}
                      {isVideoRecord && <VideoPreview stream={previewStream} />}
                    </div>
                    <p className="my-3">
                      When you are ready just press the record button & say the
                      following sentence
                    </p>

                    <div className="bio-content text-center">
                      <p>
                        I am Jimmy nikk and I am the legal owner of 301 Dorty
                        walks, Stockton, California
                      </p>
                    </div>

                    <div className="buttons-up-record">
                      <div className="upload-btn-wrapper">
                        <button className="btn upload-btn">
                          <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--ooui"
                            width="16"
                            height="16"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="#190835"
                              d="M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z"
                            />
                            <path fill="#190835" d="M10 1L5 7h4v8h2V7h4z" />
                          </svg>
                          Upload
                        </button>
                        <input type="file" name="myfile" />
                      </div>
                      <Button className="recode-btn" onClick={recordVideo}>
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ph"
                          width="18"
                          height="18"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="#ffffff"
                            d="M208 56h-27.7l-13.6-20.4A7.9 7.9 0 0 0 160 32H96a7.9 7.9 0 0 0-6.7 3.6L75.7 56H48a24.1 24.1 0 0 0-24 24v112a24.1 24.1 0 0 0 24 24h160a24.1 24.1 0 0 0 24-24V80a24.1 24.1 0 0 0-24-24Zm8 136a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8V80a8 8 0 0 1 8-8h32a7.9 7.9 0 0 0 6.7-3.6L100.3 48h55.4l13.6 20.4A7.9 7.9 0 0 0 176 72h32a8 8 0 0 1 8 8ZM128 88a44 44 0 1 0 44 44a44 44 0 0 0-44-44Zm0 72a28 28 0 1 1 28-28a28.1 28.1 0 0 1-28 28Z"
                          />
                        </svg>
                        Record
                      </Button>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Body className='vedio-modal-main'>
        <div className='vedio-modal'>
             <div className='vedio-modal text-center'>
             <h4 className='mb-4'>Upload your Video</h4>
             <div className='vedio-img-div'>
                <img src={Vedioimg} className="img-fluid"/>
             </div>

             </div>

            <div className='ulpoading-vedio-loader'>
                 <div className='ulpoading-cont'>
                     <p>Uploading Video</p>
                     <p className='comptletion'>48% Completed</p>
                 </div>
                 <ProgressBar now={50} />
            </div>
            <h5 className='security-msg mt-5 mb-3'>Your video is required for security reasons</h5>
            <div className='buttons-up-record p-0'>

                 <div className="upload-btn-wrapper">
                    <button className="btn upload-btn">

                      Change</button>

                  </div>
               <Button className='recode-btn'>Upload</Button>

            </div>

        </div>
      </Modal.Body> */}

        {/* <Modal.Body className='vedio-modal-main'>
        <div className='vedio-modal'>

          <div className='verification-vedio text-center'>
             <p>Your verification video has been uploaded successfully. We'll send you notification once it verified.</p>
          </div>
        </div>
      </Modal.Body>  */}
      </Modal>
    </div>
  );
};

export default Modalnew;
