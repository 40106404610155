import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import LoginModal from "../components/authentication/Login";
import Logo from "../assets/images/logo.png";
import user_img from "../assets/images/user_img@2x.png";
import { Link } from "react-router-dom";
import GetLocalUser from "store/localStorage";
export default function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user = GetLocalUser();
  return (
    <>
      <LoginModal
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
      />

      <Navbar
        collapseOnSelect
        expand="lg"
        className="nav_bg "
        style={{ position: "sticky", top: 0, zIndex: 1000 }}
      >
        <Container className="">
          <Link to="/" className="logo_img logo_img navbar-brand">
            <img src={Logo} alt="logo" />
          </Link>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              {user?.id && (
                <Link className="nav-link" to="/Listings">
                  Listings
                </Link>
              )}
              {user?.id && (
                <Link className="nav-link" to="/inbox">
                  Inbox
                </Link>
              )}
              {user?.id && (
                <Link className="nav-link" to="/apointments">
                  Apointments
                </Link>
              )}
              {!user?.id && (
                <Link className="nav-link" onClick={handleShow}>
                  Sell
                </Link>
              )}
              {!user?.id && (
                <Link className="nav-link" onClick={handleShow}>
                  Buy
                </Link>
              )}

              {/* <Link className="nav-link" to="/services">
                Services
              </Link>
              <Link className="nav-link" to="/faq">
                FAQ
              </Link> */}

              {!user?.id && (
                <a href="#" onClick={handleShow} className="login-sign">
                  <button className="login_btn"> Login / Sign up</button>
                </a>
              )}
              {user?.userType == "seller" && (
                <Link to="/sellerprofile">
                  <img src={user_img} width="40px" height="40px" />
                </Link>
              )}
              {user?.userType == "buyer" && (
                <Link to="/buyerprofile">
                  <img src={user_img} width="40px" height="40px" />
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
