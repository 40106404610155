import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Searchfiltermid from 'pages/Searchfilter/Searchfiltermid'


const Searchfiltercol = () => {
    return (
        <div>
         <Header/>
          <Searchfiltermid/>
         <Footer/>
            
        </div>
    );
}

export default Searchfiltercol;
