import "./App.css";
import "./app.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Route } from "react-router-dom";
import { updateToken2 } from "store/actions";
//impor components
// import Routes from './routes'
import { useEffect } from "react";
import Home from "./pages/Home";
import ListProperty1 from "./pages/ListProperty/Component/Step1";
import Stepone from "./pages/ListProperty/Component/Stepone";
import Steptwo from "./pages/ListProperty/Component/Steptwo";
import Stepthree from "./pages/ListProperty/Component/Stepthree";
import Stepfour from "./pages/ListProperty/Component/Stepfour";
import Stepfive from "./pages/ListProperty/Component/Stepfive";
import Stepsix from "./pages/ListProperty/Component/Stepsix";

import Apointments from "./pages/Appointment";
import Profile from "./pages/Profile/Components";
import Gethelp from "./pages/Profile/Components/Seller/Gethelpfaq";
import FaqPage from "./pages/Faq/faq";
import Contact from "./pages/Profile/Components/Seller/Contact";
import AboutUs from "./pages/Profile/Components/Seller/AboutUs";
import Editprofile from "./pages/Profile/Components/Seller/Editprofile";
import Detailspage from "./pages/Profile/Components/Detailspage/Detailpage";
import Detailspageinner from "./pages/Profile/Components/Detailpageinner/Detailpageinner";
import Solicitor from "./pages/Solicitor/Solicitor";
import Memorandum from "./pages/Memorandum/Memorandum";
import DetailsSolicitor from "./pages/DetailsSolicitor/DetailsSolicitor";
import Floorplan from "./pages/Floorplan/Floorplan";
import Epc from "./pages/EPC/Epc";
import Epcseller from "./pages/EPCseller/EPCseller";
import Mortagecal from "pages/Mortagecal/Mortagecal";
import Quationpage from "pages/Quationpage/Quationpage";
import Sellerfloorplan from "pages/Sellerfloorplan/Sellerfloorplan";
import Cleaners from "pages/Cleaners/Cleaners";
import Provaluation from "pages/Provaluation/Provaluation";
import Availability from "pages/Availability/Availability";
import Broker from "pages/Broker/Broker";
import ListOwnership from "pages/ListProperty/ListOwnership/ListOwnership";
import Coownerapproval from "pages/ListProperty/ListOwnership/Coownerapproval";
import Coownerdetails from "pages/ListProperty/ListOwnership/Coownerdetails";
import Budgetitems from "pages/Budgetitems/Budgetitems";
import Services from "./pages/Services";
import Listing from "./pages/Listing";
import Inbox from "./pages/Inbox/inbox";
import Payment from "pages/Paymentmethod/Paymentmethod";

// buyer
import BuyerStepone from "./pages/ListProperty/Buyersteps/Step1";
import BuyerSteptwo from "./pages/ListProperty/Buyersteps/Steptwo";

import Searchfilter from "pages/Searchfilter/Searchfilter";
import BuyerProfile from "./pages/Buyerprofile/index";

import BuyerGethelp from "./pages/Buyerprofile/Buyer/Gethelpfaq";
import BuyerfaqPage from "./pages/Buyerprofile/Buyer/FAQ";

import BuyerContact from "./pages/Buyerprofile/Buyer/Contact";
import BuyerAboutUs from "./pages/Buyerprofile/Buyer/AboutUs";
import BuyerEditprofile from "./pages/Buyerprofile/Buyer/Editprofile";
import Buyernotification from "./pages/Buyerprofile/Buyer/Notification";
import Buyerpayment from "./pages/Buyerprofile/Buyer/Paymentmethod";
import Addnewcard from "pages/Buyerprofile/Buyer/Addnewcard";
import Buyersetting from "pages/Buyerprofile/Buyersetting/Buyersetting";
import Changepassword from "pages/Buyerprofile/Buyersetting/Changepassword";
import EPCsellermid from "pages/EPCseller/EPCsellermid";

import SellerProfile from "./pages/Profile/Components/index";

import SellerGethelp from "./pages/Profile/Components/Seller/Gethelpfaq";
import SellerfaqPage from "./pages/Profile/Components/Seller/FAQ";
import SellerContact from "./pages/Profile/Components/Seller/Contact";
import SellerAboutUs from "./pages/Profile/Components/Seller/AboutUs";
import SellerEditprofile from "./pages/Profile/Components/Seller/Editprofile";
import Sellernotification from "./pages/Profile/Components/Seller/Notification";
import Sellerpayment from "./pages/Profile/Components/Seller/Paymentmethod";
import SellerAddnewcard from "pages/Profile/Components/Seller/Addnewcard";
import Sellersetting from "pages/Profile/Components/Seller/Sellersetting/Sellersetting";
import SellerChangepassword from "pages/Profile/Components/Seller/Sellersetting/Changepassword";

import Areaprice from "pages/Areaprice/Areaprice";
import scrollTop from "./components/scrollTop";
import Buywelcome from "pages/Buyerwlc/Buyerwlc";
import Buywelcome2 from "pages/Buyerwlc/Buyerwlc2";
import ProviderHome from "pages/service-provider/provider-home/provider-home";
import { useSelector, useDispatch } from "react-redux";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy"
import ScrollToTop from "backend_helper/ScrollTop";

function App() {
  const loginData = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateToken2());
  }, []);
  console.log(loginData?.SignUp?.authToken, "999TOKEN99");
  return (
    <>
      <ScrollToTop />
      <Route exact path="/" component={Home} />
      <Route  path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/apointments" component={Apointments} />
      <Route exact path="/solicitor" component={Solicitor} />
      <Route exact path="/details-solicitor" component={DetailsSolicitor} />
      <Route exact path="/memorandum" component={Memorandum} />
      <Route exact path="/floorplan" component={Floorplan} />
      <Route exact path="/epc" component={Epc} />
      <Route exact path="/epcseller" component={Epcseller} />
      <Route exact path="/mortage-calculator" component={Mortagecal} />
      <Route exact path="/quotation" component={Quationpage} />
      <Route exact path="/seller-floorplan" component={Sellerfloorplan} />
      <Route exact path="/cleaners" component={Cleaners} />
      <Route exact path="/provaluation" component={Provaluation} />
      <Route exact path="/availability" component={Availability} />
      <Route exact path="/broker" component={Broker} />
      <Route exact path="/ownerlist" component={ListOwnership} />
      <Route exact path="/coowner-approval" component={Coownerapproval} />
      <Route exact path="/coowner-detaiils" component={Coownerdetails} />
      <Route exact path="/budget-items" component={Budgetitems} />

      <Route exact path="/payment-method" component={Payment} />

      <Route exact path="/profile/dashboard" component={Profile} />
      {/* <Route exact path="/profile/faq" component={Faq} /> */}
      <Route exact path="/faq" component={FaqPage} />
      <Route exact path="/profile/contact" component={Contact} />
      <Route exact path="/profile/about-us" component={AboutUs} />
      <Route exact path="/profile/edit-profile" component={Editprofile} />
      <Route exact path="/profile/help-faq" component={Gethelp} />
      <Route exact path="/profile/details-page" component={Detailspage} />
      <Route
        exact
        path="/profile/details-verify"
        component={Detailspageinner}
      />

      <Route exact path="/services" component={Services} />
      {/* <Route exact path="/profile" component={Profile} /> */}
      <Route path="/step1" component={Stepone} />
      <Route path="/step2" component={Steptwo} />
      <Route path="/sell" component={ListProperty1} />
      <Route path="/step3" component={Stepthree} />
      <Route path="/step4" component={Stepfour} />
      <Route path="/step5" component={Stepfive} />
      <Route path="/step6" component={Stepsix} />
      <Route exact path="/Listings" component={Listing} />
      <Route exact path="/inbox" component={Inbox} />

      <Route path="/areaprice" component={Areaprice} />

      {/* buyer ruter */}

      <Route path="/buyerstep1" component={BuyerStepone} />
      <Route path="/buyerstep2" component={BuyerSteptwo} />

      <Route exact path="/buyer-welcome" component={Buywelcome} />
      <Route exact path="/seller-welcome" component={Buywelcome2} />

      <Route exact path="/search-filter" component={Searchfilter} />

      <Route exact path="/buyerprofile" component={BuyerProfile} />
      <Route exact path="/buyerprofile/faq" component={BuyerfaqPage} />
      <Route exact path="/buyerprofile/contact" component={BuyerContact} />
      <Route exact path="/buyerprofile/about-us" component={BuyerAboutUs} />
      <Route
        exact
        path="/buyerprofile/edit-profile"
        component={BuyerEditprofile}
      />
      <Route exact path="/buyerprofile/help-faq" component={BuyerGethelp} />
      <Route
        exact
        path="/buyerprofile/notification"
        component={Buyernotification}
      />
      <Route
        exact
        path="/buyerprofile/payment-method"
        component={Buyerpayment}
      />
      <Route exact path="/buyerprofile/addnewcard" component={Addnewcard} />
      <Route exact path="/buyerprofile/setting" component={Buyersetting} />
      <Route
        exact
        path="/buyerprofile/change-password"
        component={Changepassword}
      />

      {/* seller dashboard */}
      <Route exact path="/sellerprofile" component={SellerProfile} />
      <Route exact path="/sellerprofile/faq" component={SellerGethelp} />
      <Route exact path="/sellerprofile/contact" component={SellerContact} />
      <Route exact path="/sellerprofile/about-us" component={SellerAboutUs} />
      <Route
        exact
        path="/sellerprofile/edit-profile"
        component={SellerEditprofile}
      />
      <Route exact path="/sellerprofile/help-faq" component={SellerfaqPage} />
      <Route
        exact
        path="/sellerprofile/notification"
        component={Sellernotification}
      />
      <Route
        exact
        path="/sellerprofile/payment-method"
        component={Sellerpayment}
      />

      <Route
        exact
        path="/sellerprofile/addnewcard"
        component={SellerAddnewcard}
      />
      <Route exact path="/sellerprofile/setting" component={Sellersetting} />
      <Route
        exact
        path="/sellerprofile/change-password"
        component={SellerChangepassword}
      />

      {/* provider routing */}
      <Route exact path="/provider-home" component={ProviderHome} />
    </>
  );
}

export default App;
