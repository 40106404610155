import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Buyerwlcmid from "./Buyerwlcmid";
import ScrollTop from "components/scrollTop";
import { Container } from "react-bootstrap";

const Buyerwlc = () => {
  return (
    <div>
      <ScrollTop />
      <Header />
      <Container>
        <Buyerwlcmid />
      </Container>
      <Footer />
    </div>
  );
};

export default Buyerwlc;
