import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup, CloseButton } from "react-bootstrap";
import facebook from "../../assets/svg/facebook-f.svg";
import gamail from "../../assets/images/gmail.png";
import linkedIn from "assets/images/linkedinn.png";
import SignUp from "./Signup";
import Verification from "./Verification";
import Forgotpassword from "./Forgotpassword";
import { validate } from "backend_helper/validations";
import { logInTdsf, signUp } from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import close_icon from "assets/svg/close_icon.svg";

import Loader from "./loader";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import GetLocalUser from "store/localStorage";
import VideoVerificationModel from "../../pages/Profile/Components/Detailpageinner/Modalnew";

export default function Login({ show, handleShow, handleClose }) {
  const user = GetLocalUser();
  const loginData = useSelector((state) => state);
  const [state, setstate] = useState({ email: "", password: "" });
  const [error, setError] = useState();
  const [showsignup, setShowsignup] = useState(false);
  const [show4, setShow4] = useState(false);
  const [showspass, setshowspass] = useState(false);
  const handleClose2 = () => setShowsignup(false);
  const handleShow2 = () => setShowsignup(true);
  const handleClose3 = () => {
    setshowspass(false);
  };
  const handleShow3 = () => setshowspass(true);
  const handleVerification = () => {
    if (user && user?.userType == "buyer") {
    }
    handleClose();
    handleClose4();
    // openVideoVerification();
    if (user && user?.userType == "buyer") {
      navigate.push("/profile/details-page");
    } else {
      navigate.push("/seller-welcome");
    }
  };

  const [currentId, setcurrentId] = useState(null);
  const navigate = useHistory();

  const handleClose4 = () => setShow4(false);
  const handleOpen4 = () => setShow4(true);
  const onSignupClose = () => setShow4(true);
  const [showPassword, setshowPassword] = useState(false);
  const [VideoVerificationShow, setVideoVerificationShow] = useState(false);

  const openVideoVerification = () => setVideoVerificationShow(true);
  const closeVideoVerification = () => setVideoVerificationShow(false);

  const dispatch = useDispatch();
  const changeHalder = (e) => {
    const { name, value } = e.target;
    setError((prev) =>
      value !== "" || undefined || null
        ? { ...prev, [name + "Error"]: false, [name]: value }
        : { ...prev, [name + "Error"]: true, [name]: value }
    );
    setstate((prev) => ({ ...prev, [name]: value }));
  };

  //submit
  const submit = (e) => {
    e.preventDefault();
    const res = validate(state);
    setError(res);
    if (res.notValid) return;
    const callback = (ress) => {
      console.log(ress);
      let { error, isSuccess, message } = ress.data;
      console.log(isSuccess, 9088890908908);
      if (isSuccess) {
        toast.success("Logged in");
        handleClose();
        navigate.push("/");
      } else {
        console.log("error");
        toast.error(error);
      }
    };
    dispatch(logInTdsf(state, callback));
  };

  useEffect(() => {
    setstate({ email: "", password: "" });
    return () => {
      setstate({ email: "", password: "" });
    };
  }, []);

  // console.log(loginData, "loginData");
  const { email, password } = state;
  return (
    <>
      <SignUp
        show={showsignup}
        handleShow2={handleShow2}
        handleClose2={handleClose2}
        showsignup={showsignup}
        setcurrentId={setcurrentId}
        handleShow={handleShow}
        handleOpen4={handleOpen4}
        onSignupClose={onSignupClose}
      />
      <Verification
        showsOTP={show4}
        handleClose4={handleClose4}
        handleShow4={handleOpen4}
        handleShow={handleShow}
        currentId={currentId}
        closeSignup={handleVerification}
      />
      <Forgotpassword
        showspass={showspass}
        handleClose3={handleClose3}
        handleShow4={handleShow}
        handleShow={handleShow}
        handleOpen4={handleOpen4}
        handleClose4={handleClose4}
      />
      <VideoVerificationModel
        show={VideoVerificationShow}
        onHide={closeVideoVerification}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered={true}
        keyboard={false}
        className="login_modal login_modal2"
      >
        <Modal.Header>
          <Modal.Title onClick={openVideoVerification}>Sign in</Modal.Title>
          <p className="m-0 close_btn " onClick={handleClose}>
            {/* <CloseButton
              variant="white"

              disabled={false}
              style={{ width: "15px", height: "15px", color: "#fff" }}
            /> */}
            <img src={close_icon} style={{ width: "24px" }} />
          </p>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form onSubmit={submit}>
            <div className="mb-2">
              <Form.Label className="input_label pt-0">Email</Form.Label>
              <InputGroup className="position-relative">
                <Form.Control
                  type="text"
                  className="inputField form-control"
                  placeholder="Email"
                  style={{
                    borderRadius: 8,
                    background: "#F4F4F4 0% 0% no-repeat padding-box",
                  }}
                  isInvalid={error?.emailError}
                  required={error?.emailError}
                  name="email"
                  value={email}
                  onChange={changeHalder}

                  // onChange={this.handleChange.bind(this)}
                />
                <div className="email-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.365"
                    height="15.629"
                    viewBox="0 0 17.365 15.629"
                  >
                    <path
                      id="Path_3778"
                      data-name="Path 3778"
                      d="M278.971,280a4.39,4.39,0,0,1,4.394,4.385v6.859a4.392,4.392,0,0,1-4.394,4.385h-8.578A4.391,4.391,0,0,1,266,291.244v-6.859A4.385,4.385,0,0,1,270.393,280Zm1.381,5.678.069-.069a.671.671,0,0,0-.01-.868.73.73,0,0,0-.459-.226.66.66,0,0,0-.487.174l-3.915,3.126a1.36,1.36,0,0,1-1.737,0l-3.907-3.126a.664.664,0,0,0-.93.929l.114.113,3.951,3.082a2.734,2.734,0,0,0,3.394,0Z"
                      transform="translate(-266 -280)"
                      fill="#636363"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
              </InputGroup>
            </div>
            <div className="my-2">
              <Form.Label className="input_label">Password</Form.Label>
              <InputGroup className="position-relative newPass">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  className="inputField form-control tessst"
                  placeholder="Password"
                  isInvalid={error?.passwordError}
                  required={error?.passwordError}
                  name="password"
                  style={{
                    borderRadius: 8,
                    background: "#F4F4F4 0% 0% no-repeat padding-box",
                  }}
                  value={password}
                  onChange={changeHalder}
                />
                <div className="email-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.732"
                    height="18.623"
                    viewBox="0 0 15.732 18.623"
                  >
                    <path
                      id="Path_3755"
                      data-name="Path 3755"
                      d="M1120.474,395.988v1.419a3.855,3.855,0,0,1,2.758,3.664v4.569a3.907,3.907,0,0,1-3.951,3.863h-7.829a3.907,3.907,0,0,1-3.951-3.863V401.07a3.866,3.866,0,0,1,2.758-3.664v-1.419a5.109,5.109,0,1,1,10.218,0Zm-5.1-3.384a3.424,3.424,0,0,1,3.452,3.384v1.22H1111.9v-1.238a3.424,3.424,0,0,1,3.47-3.369Zm.814,11.767a.824.824,0,0,1-1.647,0V402.33a.824.824,0,0,1,1.647,0Z"
                      transform="translate(-1107.5 -390.879)"
                      fill="#636363"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>

                <a href="#">
                  <div className="eye-svg">
                    <svg
                      onClick={() => setshowPassword(!showPassword)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.553"
                      height="12.443"
                      viewBox="0 0 15.553 12.443"
                    >
                      <path
                        id="Path_3732"
                        data-name="Path 3732"
                        d="M550.744,399.222a3.023,3.023,0,1,0,.891-2.137A3.023,3.023,0,0,0,550.744,399.222Zm7.5-4.63a11.02,11.02,0,0,1,3.266,4.4.559.559,0,0,1,0,.445c-1.622,3.756-4.512,6-7.73,6h-.008c-3.21,0-6.1-2.248-7.723-6a.559.559,0,0,1,0-.445c1.623-3.756,4.511-6,7.723-6h.008a7.21,7.21,0,0,1,4.462,1.591Zm-4.463,6.506a1.881,1.881,0,0,0,0-3.763,1.466,1.466,0,0,0-.265.023,1.555,1.555,0,0,1-1.555,1.493h-.038a1.981,1.981,0,0,0-.038.369A1.891,1.891,0,0,0,553.777,401.1Z"
                        transform="translate(-546 -393)"
                        fill="#3a3b52"
                        fillRule="evenodd"
                        opacity="0.8"
                      />
                    </svg>
                  </div>
                </a>
              </InputGroup>
            </div>
            <span
              className="forgot_password "
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleShow3();
                handleClose();
              }}
            >
              Forgot Password?
            </span>
            {loginData?.SignUp?.loading ? (
              <button className="logIn_btn" disabled={true}>
                {" "}
                <Loader />
              </button>
            ) : (
              <button className="logIn_btn w-100 log22" onClick={submit}>
                {" "}
                Login
              </button>
            )}
          </form>
          <div className="or_login_content">
            <small className="p-0 text-center d-block text-muted my-3">
              Or Login with
            </small>
            <div className="social_btn_wrapper">
              <button className="social_btn">
                <img src={facebook} width="10px" />{" "}
              </button>
              <button className="social_btn" style={{ background: "#fff" }}>
                <img src={gamail} width="20px" />{" "}
              </button>
              <button className="social_btn linked-in">
                <img src={linkedIn} width="10px" />{" "}
              </button>
            </div>
            <small className="p-0 text-center d-block">
              New user?{" "}
              <b>
                <a
                  href="#"
                  className="crete-account2"
                  onClick={() => handleShow2()(handleClose())}
                >
                  Create an account
                </a>
              </b>
            </small>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
