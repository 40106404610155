import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "../Sidebar";

import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col, Container } from "react-bootstrap";

// images
import Img from "assets/images/m_userimg.png";

export default function Editprofile() {
  return (
    <>
      <Header />
      <Container>
      <div className="seller_container">
        <Sidebar />
        <div className="profile_right_side">
          <div className="edit-profile-avtar-div">
            <h3 className=" text-center">Edit Profile</h3>

            <div className="avatar-profile position-relative mt-4">
              <figure className="avatar position-relative">
                <img
                  src={Img}
                  alt="image"
                  className="shadow-sm rounded-circle w100"
                />
                <a href="#">
                  <div className=" profile-edit">
                    <svg width="40" height="40" viewBox="0 0 40 40">
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          x1="0.5"
                          x2="0.5"
                          y2="1"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#2d1159" />
                          <stop offset="1" stop-color="#2d1159" />
                        </linearGradient>
                      </defs>
                      <g id="ic_edit" transform="translate(-208.106 -292.107)">
                        <g
                          id="Ellipse_528"
                          data-name="Ellipse 528"
                          transform="translate(208.106 292.107)"
                          stroke="#fff"
                          stroke-width="1"
                          fill="url(#linear-gradient)"
                        >
                          <circle cx="20" cy="20" r="20" stroke="none" />
                          <circle cx="20" cy="20" r="19.5" fill="none" />
                        </g>
                        <g
                          id="Group_9076"
                          data-name="Group 9076"
                          transform="translate(220.355 302.87)"
                        >
                          <path
                            id="Path_4875"
                            data-name="Path 4875"
                            d="M284.309,393.975h0a3.622,3.622,0,0,0-5.07.723l-8.039,10.71c-2.071,2.76-.112,6.18-.112,6.18s3.862.888,5.9-1.832l8.039-10.71A3.622,3.622,0,0,0,284.309,393.975Z"
                            transform="translate(-270.25 -393.25)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.2"
                            fillRule="evenodd"
                          />
                          <path
                            id="Path_4876"
                            data-name="Path 4876"
                            d="M276.5,396.211l5.791,4.347"
                            transform="translate(-269.058 -392.686)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.2"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </a>
              </figure>
            </div>

            <div className="form-div">
              <Form className="edit-profile-form">
                <Row>
                  <Col lg={2} md={4} sm={12}>
                    <FormGroup className="position-relative">
                      <Label for="exampleSelect">Title</Label>
                      <Input type="select" name="select" id="exampleSelect">
                        <option>Mr.</option>
                        <option>Mrs.</option>
                      </Input>
                      <div className="down-arrow">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ic"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6l1.41-1.41z"
                          />
                        </svg>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={10} md={8} sm={12}>
                    <FormGroup>
                      <Label for="exampleEmail">Full Name</Label>
                      <Input
                        type="text"
                        name="email"
                        id="exampleEmail"
                        placeholder="Marry Jackson"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative">
                      <Label for="exampleSelect">Phone number</Label>
                      <Input
                        type="tel"
                        name="select"
                        id="exampleSelect"
                        placeholder="7888888959"
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="exampleEmail">Email address</Label>
                      <Input
                        type="text"
                        name="email"
                        id="exampleEmail"
                        placeholder="Jimmyni.01@gmail.com"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label for="exampleEmail">Address</Label>
                  <Input
                    type="text"
                    name="email"
                    id="exampleEmail"
                    placeholder="Balistreri, Center North, Eloise, Avon"
                  />
                </FormGroup>

                <Button className="update-profile">Update Profile</Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      </Container>
      
      <Footer />
    </>
  );
}
