import React from 'react'
import { Form, InputGroup } from "react-bootstrap";

const tenureOptions = ["Freehold", "Leasehold", "Unknown"];

const sizeAccuracyOpts = ["Exact Measurement", "Best Estimate"];

const permissionForDevOpts = ["Yes", "No"];

export default function LandDetail(props) {
  const { fields, onFieldChange } = props;

  const {
    landTenure,
    landSizeInAcres,
    landSizeAccuracy,
    landPermissionForDevelopment,
    landPermissionNumber,
    landPermissionImage,
  } = fields;

  return (
    <div className="list_start">
      <p className="light-heading">Add Property details</p>

      <div className="home_type">
        <p className="list_heading">Tenure</p>
        <div className="style_btn_div">
          {tenureOptions.map((v) => (
            <button
              className={v == landTenure ? "style_btn22" : "style_btn2"}
              name="landTenure"
              value={v}
              onClick={onFieldChange}
            >
              {v}
            </button>
          ))}
        </div>
      </div>

      <div className="home_type">
        <p className="list_heading">Land size in acres</p>
        <div className="style_btn_div w-50">
          <InputGroup>
            <Form.Control
              type="number"
              min={0}
              className="inputField form-control"
              name="landSizeInAcres"
              value={landSizeInAcres}
              onChange={onFieldChange}
            />
          </InputGroup>
        </div>
      </div>

      <div className="home_type">
        <p className="list_heading">Size accuary</p>
        <div className="style_btn_div">
          {sizeAccuracyOpts.map((v) => (
            <button
              className={v == landSizeAccuracy ? "style_btn22" : "style_btn2"}
              name="landSizeAccuracy"
              value={v}
              onClick={onFieldChange}
            >
              {v}
            </button>
          ))}
        </div>
      </div>

      <div className="home_type">
        <p className="list_heading">
          Does the land have planning permission for development?
        </p>
        <div className="style_btn_div">
          {permissionForDevOpts.map((v) => (
            <button
              className={
                v == landPermissionForDevelopment ? "style_btn22" : "style_btn2"
              }
              name="landPermissionForDevelopment"
              value={v}
              onClick={onFieldChange}
            >
              {v}
            </button>
          ))}
        </div>
      </div>

      <div className="home_type">
        <p className="list_heading">Planning reference number</p>
        <div className="style_btn_div">
          <InputGroup>
            <Form.Control
              type="number"
              min={0}
              className="inputField form-control"
              name="landPermissionNumber"
              value={landPermissionNumber}
              onChange={onFieldChange}
            />
          </InputGroup>
        </div>
      </div>
    </div>
  );
}
