import Select from "react-select";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";

export const AddEditTagModal = ({ modalData, onClose, onChange }) => {
  const [tag, setTag] = useState("");

  const onContinue = () => {
    onChange({ position: modalData?.position, value: tag });
    onClose();
  };

  const onChangeValue = (e) => {
    setTag(e.value);
  };

  return (
    <Modal
      show={!!modalData}
      onHide={onClose}
      backdrop="static"
      centered={true}
      keyboard={false}
      className="login_modal login_modal2"
      style={{ zIndex: 999999 }}
    >
      <Modal.Header>
        <Modal.Title
          style={{
            textAlign: "center",
            color: "#28272B",
            fontSize: "28px",
            fontWeight: 600,
          }}
        >
          Add Tag Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: "20px" }}>
        <div>
          <label
            style={{
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: "0.14px",
            }}
          >
            Select tag name
          </label>
          <Select
            className="tags-dropdown"
            options={options}
            placeholder={"Select tag name"}
            onChange={onChangeValue}
          />
        </div>

        <div
          style={{
            margin: "30px 0",
            fontSize: "16px",
            letterSpacing: "0.14px",
            color: "#636363",
            textAlign: "center",
          }}
        >
          OR
        </div>

        <div>
          <label
            style={{
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: "0.14px",
            }}
          >
            Tag Name
          </label>
          <input
            className="tag-input"
            placeholder="Enter tag name"
            onChange={(e) => {
              setTag(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "40px",
            gap: "15px",
            height: "56px",
          }}
        >
          <Button
            className="back-btn"
            style={{ width: "100%", height: "100%" }}
            onClick={onClose}
          >
            Back
          </Button>
          <Button
            className="Continue-btn"
            style={{ width: "100%", height: "100%" }}
            onClick={() => {
              if (!tag) {
                alert("Please enter tag name!");
                return;
              }
              onContinue();
            }}
          >
            Continue
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const options = [
  {
    value: "dinning",
    label: "Dinning",
  },
  {
    value: "bedroom",
    label: "Bedroom",
  },
  {
    value: "kitchen",
    label: "Kitchen",
  },
];
