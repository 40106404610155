import React, { useState, useEffect } from "react";
import { Container, Row, Carousel, Col, Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../../components/Header";

import Footer from "../../components/Footer";
import Homesearch from "./homesearch";
import backgroundImageBanner from "../../assets/images/header_img.png";
import step1 from "../../assets/svg/Group_18735.png";
import step2 from "../../assets/svg/Group_18736.png";
import propertyImg from "../../assets/images/spacejoy.png";
import videoImg from "../../assets/images/VIDEO.png";
import Fade from "react-reveal/Fade";
import step3 from "../../assets/svg/Group_18738.png";
import Group19 from "../../assets/svg/Group19.svg";
// import Group19 from "https://ubsold.s3.us-east-2.amazonaws.com/images/0954047241f2451830d15a57e58f8ad449ea6525.svg";
import Group20 from "../../assets/svg/Group20.svg";
// import Group20 from "https://ubsold.s3.us-east-2.amazonaws.com/images/f4b40b2b33710b14fc23be39ecacef1887c575c9.svg";
import location from "../../assets/svg/Path3773.svg";
import left_arrow from "../../assets/svg/left_arrow.svg";
import right_arror from "../../assets/svg/right_arror.svg";
import Cardone from "assets/images/card-one.jpg";
import Slider from "react-slick";
import NewTest from "./NewTe";
import LoginModal from "components/authentication/Login";
import SignUp from "components/authentication/Signup";
import Forgotpassword from "components/authentication/Forgotpassword";
import Verification from "components/authentication/Verification";
import { useSelector } from "react-redux";

import Play from "assets/images/play.png";

import { CardImg, CardText, CardBody, CardTitle } from "reactstrap";
import { useHistory } from "react-router-dom";
import GetLocalUser from "store/localStorage";
import VideoVerificationModel from "../../pages/Profile/Components/Detailpageinner/Modalnew";
import { SellerDashboard } from "./components/SellerDashboard";
import {
  getBuyerPropertiesRequest,
  getSellerPropertiesRequest,
  getUserByIdRequest,
} from "utils/helper";
import { getLocation } from "utils/helper";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <svg
        aria-hidden="true"
        role="img"
        className="iconify iconify--material-symbols c"
        width="23"
        height="23"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          fill="#111"
          d="M8.025 22L6.25 20.225L14.475 12L6.25 3.775L8.025 2l10 10Z"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        aria-hidden="true"
        role="img"
        className="iconify iconify--material-symbols"
        width="23"
        height="23"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          fill="#111"
          d="M16 22L6 12L16 2l1.775 1.775L9.55 12l8.225 8.225Z"
        />
      </svg>
    </div>
  );
}

function Index() {
  var settings = {
    arrow: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [user, setUser] = useState({});
  const localUser = GetLocalUser();

  var array = new Array(6);
  const [show, setShow] = useState(false);

  const [showsignup, setShowsignup] = useState(false);
  const handleClose2 = () => setShowsignup(false);
  const handleShow2 = () => setShowsignup(true);

  const [showspass, setShowpass] = useState(false);
  const handleClose3 = () => {
    setShowpass(false);
    console.log("forgot close");
  };
  const handleShow3 = () => setShowpass(true);

  const [show55, setShow55] = useState(false);
  const handleClose55 = () => setShow55(false);
  const handleShow55 = () => setShow55(true);

  const [showsOTP, setShowOTP] = useState(false);
  const handleClose4 = () => setShowOTP(false);
  const handleShow4 = () => setShowOTP(true);
  const [vdoModalShow, setvdoModalShow] = useState(false);
  const history = useHistory();
  const loginData = useSelector((state) => state);
  // console.log(user?.userType == "buyer", "4544554");

  /////LOCATION FETCHIN TRY /////

  useEffect(() => {}, [getLocation()]);
  /////LOCATION FETCHIN TRY /////

  const openD = () => {
    setvdoModalShow(true);
  };
  const closeD = () => setvdoModalShow(false);

  const [propertiesList, setPropertiesList] = useState([]);

  const getSellerProperties = () => {
    const payload = ``;

    // const payload = `filter=uploaded`;

    getSellerPropertiesRequest({
      payload,
      onSuccess: (data) => {
        // console.log(":::::::::::getPropeties");
        // console.log(data);
        setPropertiesList(data?.items);
      },
    });
  };

  const getBuyerProperties = () => {
    getBuyerPropertiesRequest({
      onSuccess: (data) => setPropertiesList(data?.items),
    });
  };

  const getProperties = () => {
    switch (user?.userType) {
      case "seller":
        getSellerProperties();
        break;

      case "buyer":
        getBuyerProperties();
        break;

      default:
        getBuyerProperties();
        break;
    }
  };

  useEffect(() => {
    getUserByIdRequest({
      id: localUser?.id,
      onSuccess: (data) => setUser(data),
    });
  }, []);

  useEffect(() => {
    if (user?.userType) {
      getProperties();
    }
  }, [user]);

  return (
    <>
      <LoginModal
        handleClose={handleClose55}
        handleShow={handleShow55}
        show={show55}
      />

      <Verification
        show={showsOTP}
        handleClose3={handleClose4}
        handleShow3={handleShow4}
        showsOTP={showsOTP}
      />

      <Forgotpassword
        show={showspass}
        handleClose3={handleClose3}
        handleShow3={handleShow3}
        showspass={showspass}
      />

      <SignUp
        show={showsignup}
        handleClose2={handleClose2}
        handleShow2={handleShow2}
        showsignup={showsignup}
        handleShow={handleShow55}
      />
      <VideoVerificationModel show={vdoModalShow} onHide={closeD} />
      <div className="homer_container">
        <Header />
        <div className="herobanner">
          <Container>
            {/* style={{backgroundImage:`url(${backgroundImageBanner})`}} */}
            <div className="hero_container">
              <div className="hero_content" >
                <Fade bottom>
                  <h1>
                    Sell your
                    <br />
                    own home.
                  </h1>
                  <div className="donwload_app   ">
                    <div className="col-md-12 col-xl-12 col-sm-12 my-3">
                      <span>
                        go on{" "}
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ic"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 22 22"
                        >
                          <path fill="#00ffff" d="m10 17l5-5l-5-5v10z" />
                        </svg>
                        control your move
                      </span>
                      <div className="col-md-12 col-xl-12 col-sm-12 my-4">
                        <button className="login_btn login_btn2">
                          Download app
                        </button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </Container>
        </div>
        <Homesearch showBuyerHde={user?.userType == "buyer"} />

        {user?.userType === "seller" && (
          <div style={{ margin: "0 100px", marginBottom: "60px" }}>
            <SellerDashboard user={user} key={user} />
          </div>
        )}
        <div className="video_wrapper position-relative">
          <img src={Play} alt={"img"} className="play-btn" />
        </div>
        {!!propertiesList.length && (
          <section className={`home-properties`}>
            <Container>
              <div className="slider-head">
                <h3 className="m-0">
                  {user?.userType === "seller"
                    ? `In Review Properties (${propertiesList?.length})`
                    : `Nearby Properties`}
                </h3>
                <a href="#" className="view-all">
                  View all
                </a>
              </div>
              {/* {renderArrows()} */}
              <Slider {...settings} className="cr_t">
                {propertiesList.map((property, index) => {
                  const { propertyImages, address, offerPrice } =
                    property || {};
                  return (
                    <div>
                      <Card className="card-main" key={index}>
                        <div className="card-img-container">
                          <CardImg
                            top
                            width="100%"
                            src={
                              propertyImages?.find(
                                ({ isCoverPhoto }) => isCoverPhoto
                              )?.imgUrl
                            }
                            alt="Card image cap"
                            className="property-card-img"
                          />
                        </div>
                        <CardBody className="pt-0">
                          <CardTitle className="card-heading">
                            3 BHK Flat
                          </CardTitle>

                          <CardText className="card-address">
                            <div className="cardlocation-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.635"
                                height="15"
                                viewBox="0 0 12.635 15"
                              >
                                <path
                                  id="Path_3773"
                                  data-name="Path 3773"
                                  d="M155.5,341.136a6.318,6.318,0,1,1,12.635,0,7.7,7.7,0,0,1-1.658,4.678,16.393,16.393,0,0,1-4.118,3.82.892.892,0,0,1-1.084,0c-.266-.172-.527-.351-.969-.676a15.689,15.689,0,0,1-3.149-3.144,7.7,7.7,0,0,1-1.657-4.678Zm4.232.193a2.086,2.086,0,1,0,2.086-2.086A2.086,2.086,0,0,0,159.732,341.328Z"
                                  transform="translate(-155.5 -334.818)"
                                  fill="#39f"
                                  fillRule="evenodd"
                                />
                              </svg>
                            </div>
                            {address}
                          </CardText>
                          <p className="properties-prize">£ {offerPrice}</p>
                          <Button
                            className="view-property"
                            onClick={() =>
                              history.push({
                                url: "/profile/details-page",
                                state: { property },
                              })
                            }
                          >
                            View Property
                          </Button>
                        </CardBody>
                      </Card>
                    </div>
                  );
                })}
              </Slider>
            </Container>
          </section>
        )}

        {user?.userType == "buyer" && <NewTest />}
        {!user?.id && (
          <div className="guid_container">
            <Fade bottom>
              <Container>
                <Carousel nextIcon={""} prevIcon="">
                  <Carousel.Item>
                    <Row className="justify-content-center align-items-center guid_container2 ">
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step1}
                          width="85px"
                        />
                        <p className="py-4 guid_text">Sign up</p>
                      </Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-center "
                        style={{ marginTop: "-22px" }}
                      >
                        <img
                          src={
                            "https://ubsold.s3.us-east-2.amazonaws.com/images/0954047241f2451830d15a57e58f8ad449ea6525.svg" ||
                            Group19
                          }
                        />
                        <p className="py-4 guid_text text-center"></p>
                      </Col>
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step2}
                          width="85px"
                        />
                        <p className="py-4 guid_text">List your property</p>
                      </Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-center "
                        style={{ marginTop: "-22px" }}
                      >
                        <img
                          src={
                            "https://ubsold.s3.us-east-2.amazonaws.com/images/f4b40b2b33710b14fc23be39ecacef1887c575c9.svg" ||
                            Group20
                          }
                        />
                        <p className="py-4 guid_text"></p>
                      </Col>
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step3}
                          width="85px"
                        />
                        <p className="py-4 guid_text">SOLD!</p>
                      </Col>
                    </Row>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Row className="justify-content-center align-items-center guid_container2 ">
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step1}
                          width="85px"
                        />
                        <p className="py-4 guid_text">Sign up</p>
                      </Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-center "
                        style={{ marginTop: "-22px" }}
                      >
                        <img
                          src={
                            "https://ubsold.s3.us-east-2.amazonaws.com/images/0954047241f2451830d15a57e58f8ad449ea6525.svg" ||
                            Group19
                          }
                        />
                        <p className="py-4 guid_text text-center"></p>
                      </Col>
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step2}
                          width="85px"
                        />
                        <p className="py-4 guid_text">List your property</p>
                      </Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-center "
                        style={{ marginTop: "-22px" }}
                      >
                        <img
                          src={
                            "https://ubsold.s3.us-east-2.amazonaws.com/images/f4b40b2b33710b14fc23be39ecacef1887c575c9.svg" ||
                            Group20
                          }
                        />
                        <p className="py-4 guid_text"></p>
                      </Col>
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step3}
                          width="85px"
                        />
                        <p className="py-4 guid_text">SOLD!</p>
                      </Col>
                    </Row>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Row className="justify-content-center align-items-center guid_container2 ">
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step1}
                          width="85px"
                        />
                        <p className="py-4 guid_text">Sign up</p>
                      </Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-center "
                        style={{ marginTop: "-22px" }}
                      >
                        <img
                          src={
                            "https://ubsold.s3.us-east-2.amazonaws.com/images/0954047241f2451830d15a57e58f8ad449ea6525.svg" ||
                            Group19
                          }
                        />
                        <p className="py-4 guid_text text-center"></p>
                      </Col>
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step2}
                          width="85px"
                        />
                        <p className="py-4 guid_text">List your property</p>
                      </Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={3}
                        className="text-center "
                        style={{ marginTop: "-22px" }}
                      >
                        <img
                          src={
                            "https://ubsold.s3.us-east-2.amazonaws.com/images/f4b40b2b33710b14fc23be39ecacef1887c575c9.svg" ||
                            Group20
                          }
                        />
                        <p className="py-4 guid_text"></p>
                      </Col>
                      <Col sm={2} md={2} lg={2} className="text-center ">
                        <img
                          className="carousel-icon"
                          src={step3}
                          width="85px"
                        />
                        <p className="py-4 guid_text">SOLD!</p>
                      </Col>
                    </Row>
                  </Carousel.Item>
                </Carousel>
              </Container>
            </Fade>
          </div>
        )}

        {!user?.id && (
          <Container>
            <div className="login_info ">
              <span>
                go on{" "}
                <svg
                  aria-hidden="true"
                  role="img"
                  className="iconify iconify--ic"
                  width="32"
                  height="32"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 22 22"
                >
                  <path fill="#00ffff" d="m10 17l5-5l-5-5v10z" />
                </svg>{" "}
                control your move
              </span>
              <button className="login_btn btn2" onClick={handleShow55}>
                {" "}
                Login / Sign up
              </button>
            </div>
          </Container>
        )}
        <Footer />
      </div>
    </>
  );
}

export default Index;
