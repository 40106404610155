import React from "react";

export const RenderTag = ({ style, onClick, tag }) => {
  return (
    <span
      onClick={onClick}
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
        fontSize: "14px",
        borderRadius: "5px",
        background: "#CECECE",
        padding: "5px 10px",
        fontWeight: 600,
        zIndex: 11,
        textTransform: "capitalize",
        ...style,
      }}
    >
      {tag ?? "Add Tag"}
    </span>
  );
};
