import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Quationpagemid from "./Quationpagemid";

import ScrollTop from "components/scrollTop";

const Quationpage = () => {
  return (
    <div>
      <ScrollTop />
      <Header />
      <Quationpagemid/>
      <Footer />
    </div>
  );
};

export default Quationpage;
