import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Floorplanmid from "pages/Floorplan/Floorplanmid";
import ScrollTop from "components/scrollTop";

const Floorplanmain = () => {
  return (
    <div>
      <ScrollTop />
      <Header />
      <Floorplanmid />
      <Footer />
    </div>
  );
};

export default Floorplanmain;
