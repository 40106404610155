import ScrollTop from "components/scrollTop";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";

import Propertyone from "assets/images/main.jpg";
import { useDispatch, useSelector } from "react-redux";
import { createPropertySaveForm } from "store/actions";
import { createPropertySelector } from "store/selectors";
import { filledInputClasses } from "@mui/material";

const fieldPerSelection = {
  ["Residential"]: {
    residentialType: "",
    residentialStyle: "",
    residentialAge: "",
    residentialTenure: "",
    residentialBedroomCount: 0,
    residentialBathroomCount: 0,
    residentialReceptionRooms: 0,
    residentialCentralHeating: "",
    residentialGarden: "",
    residentialAdditionalFeatures: "",
    residentialAdditonalFeatureText: "",
    residentialBroadbandConnection: "",
    residentialBroadbandSpeed: "",
    residentialEnergyType: "",
    residentialEnergySpecifyText: "",
  },
  ["Commercial"]: {
    commercialType: "",
    commercialTenure: "",
    commercialCurrentBusinessClassification: "",
    commercialSize: "",
    commercialSizeUnit: "",
    commercialPropertySizeInSQM: "",
    commercialCarParking: "",
    commercialCarParkingSize: "",
  },
  ["Land"]: {
    landTenure: "",
    landSizeInAcres: "",
    landSizeAccuracy: "",
    landPermissionForDevelopment: "",
    landPermissionNumber: "",
    landPermissionImage: "",
  },
};

export default function Steptwo() {
  const history = useHistory();

  const prevData = useSelector(createPropertySelector.stepTwo);
  const dispatch = useDispatch();

  const onSelectType = (propertyType) => () => {
    let dataToBeReset = {};

    Object.keys(filledInputClasses).forEach((k) => {
      if (k === propertyType) return;
      Object.assign(dataToBeReset, fieldPerSelection[k]);
    });

    dispatch(
      createPropertySaveForm({
        ...prevData,
        propertyType,
      })
    );
    history.push("/sell");
  };

  return (
    <>
      <ScrollTop />
      <Header />

      <section className="common-steps-main">
        <Container>
          <Row>
            <div className="list-main">
              <div className="list-heading">
                <h2>List your property</h2>
              </div>
              <div className="list-main-under">
                {/* <Stepper/> */}

                <div className="custom-steeper">
                  <div className="common-step step1 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                      Address and <br /> Ownership
                    </span>
                  </div>

                  <div className="common-step step2 position-relative">
                    {/* <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div> */}
                    <div className="stepbox process">02</div>
                    <span>Property Details</span>
                  </div>

                  <div className="common-step step3 position-relative">
                    <div className="stepbox uundeprocss">03</div>
                    <span>Value</span>
                  </div>

                  <div className="common-step step4 position-relative">
                    <div className="stepbox uundeprocss">04</div>
                    <span>
                      Images and <br /> Video
                    </span>
                  </div>

                  <div className="common-step step5 position-relative">
                    <div className="stepbox uundeprocss">05</div>
                    <span>Review and Submit</span>
                  </div>
                </div>

                <div className="form-headinga left-cont-srep4 pb-2">
                  {/* <h3>Property Type</h3> */}
                  <p>Please select the property type</p>
                </div>

                <div className="slect-property-type">
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <a onClick={onSelectType("Residential")}>
                        <div className="property-box position-relative">
                          <img src={Propertyone} alt="" className="img-fluid" />
                          <h5>Residental</h5>
                          <div className="overlay-img"></div>
                        </div>
                      </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <a onClick={onSelectType("Commercial")}>
                        <div className="property-box position-relative">
                          <img src={Propertyone} alt="" className="img-fluid" />
                          <h5>Commercial</h5>
                          <div className="overlay-img"></div>
                        </div>
                      </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <a onClick={onSelectType("Land")}>
                        <div className="property-box position-relative">
                          <img src={Propertyone} alt="" className="img-fluid" />
                          <h5>Land</h5>
                          <div className="overlay-img"></div>
                        </div>
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
