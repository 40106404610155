import * as CONST from "./actionType";

export const createProperty = (data, callback) => ({
  type: CONST.CREATE_PROPERTY,
  payload: { data, callback },
});

export const createPropertySuccess = (data) => ({
  type: CONST.CREATE_PROPERTY_SUCCESS,
  payload: data,
});

export const createPropertyFail = (error) => ({
  type: CONST.CREATE_PROPERTY_FAIL,
  payload: error,
});

export const createPropertySaveForm = (data) => ({
  type: CONST.CREATE_PROPERTY_SAVE_FORM,
  payload: data,
});

