import React from "react";
import Stepper from "../../../components/Stepper";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import ScrollTop from "components/scrollTop";

// images
import Propertyone from "assets/images/main.jpg";

export default function Steptwo() {
  return (
    <>
      <ScrollTop />
      <Header />

      <section className="common-steps-main">
        <Container>
          <Row>
            <div className="list-main">
              <div className="list-heading">
                <h2>List your property</h2>
              </div>
              <div className="list-main-under">
                {/* <Stepper/> */}

                <div className="custom-steeper">
                  <div className="common-step step1 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                    Address and <br /> Ownership
                    </span>
                  </div>

                  <div className="common-step step2 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>Property Details</span>
                  </div>

                  <div className="common-step step3 position-relative">
                    <div className="stepbox process">03</div>
                    <span>Value</span>
                  </div>

                  <div className="common-step step4 position-relative">
                    <div className="stepbox uundeprocss">04</div>
                    <span>
                      Images and <br /> Video
                    </span>
                  </div>

                  <div className="common-step step5 position-relative">
                    <div className="stepbox uundeprocss">05</div>
                    <span>Review and Submit</span>
                  </div>
                </div>

                <div className="form-headinga">
                  <h3>Property Type</h3>
                  <p>Please select the property type</p>
                </div>

                <div className="slect-property-type">
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <a href="/sell">
                        <div className="property-box position-relative">
                          <img src={Propertyone} alt="" className="img-fluid" />
                          <h5>Residental</h5>
                          <div className="overlay-img"></div>
                        </div>
                      </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <a href="/sell">
                        <div className="property-box position-relative">
                          <img src={Propertyone} alt="" className="img-fluid" />
                          <h5>Commercial</h5>
                          <div className="overlay-img"></div>
                        </div>
                      </a>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <a href="/sell">
                        <div className="property-box position-relative">
                          <img src={Propertyone} alt="" className="img-fluid" />
                          <h5>Land</h5>
                          <div className="overlay-img"></div>
                        </div>
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
