import CustomSelect from "components/CustomSelect";
import SelectWithMenu from "components/SelectWithMenu";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";

const typesOptions = [
  "Offices",
  "Retail",
  "Industrial",
  "Leisure",
  "Healthcare",
];
const tenureOptions = ["Freehold", "Leasehold", "Unknown"];

const optionMapFun = (value) => ({
  value,
  label: value,
});

const businessClassificationOptions = [
  "A1 - Shops",
  "A2 - Industries",
  "A3 - Hospitals",
].map(optionMapFun);

const sizeUnitOptions = ["Sqft"].map(optionMapFun);

const carparkingOptions = ["Yes", "No"];

export default function CommercialDetail(props) {
  const { fields, onFieldChange } = props;

  const {
    commercialType,
    commercialTenure,
    commercialCurrentBusinessClassification,
    commercialSizeUnit,
    commercialSize,
    commercialCarParking,
    commercialCarParkingSize,
  } = fields;

  return (
    <div className="list_start">
      <p className="light-heading">Add Property details</p>
      <div className="home_type">
        <p className="list_heading">Type</p>
        <div className="style_btn_div">
          {typesOptions.map((v) => (
            <button
              className={v == commercialType ? "style_btn22" : "style_btn2"}
              name="commercialType"
              value={v}
              onClick={onFieldChange}
            >
              {v}
            </button>
          ))}
        </div>
      </div>

      <div className="home_type">
        <p className="list_heading">Tenure</p>
        <div className="style_btn_div">
          {tenureOptions.map((v) => (
            <button
              className={v == commercialTenure ? "style_btn22" : "style_btn2"}
              name="commercialTenure"
              value={v}
              onClick={onFieldChange}
            >
              {v}
            </button>
          ))}
        </div>
      </div>

      <div className="home_type">
        <p className="list_heading">Current Business Classification</p>
        <div className="style_btn_div w-50">
          <CustomSelect
            value={commercialCurrentBusinessClassification}
            onChange={onFieldChange}
            name="commercialCurrentBusinessClassification"
            placeholder="Current Business Classification"
            options={businessClassificationOptions}
          />
        </div>
      </div>

      <div className="home_type">
        <div className="style_btn_div">
          <div className="input-group d-flex justify-content-between">
            <p className="list_heading">Size</p>
            <div>
              <SelectWithMenu
                value={commercialSizeUnit}
                onChange={onFieldChange}
                name="commercialSizeUnit"
                placeholder="Size Unit"
                options={sizeUnitOptions}
              />
            </div>
          </div>
          <InputGroup>
            <Form.Control
              type="number"
              min={0}
              className="inputField form-control"
              name="commercialSize"
              value={commercialSize}
              onChange={onFieldChange}
            />
          </InputGroup>
        </div>
      </div>

      <div className="home_type">
        <p className="list_heading">Carparking?</p>
        <div className="style_btn_div">
          {carparkingOptions.map((v) => (
            <button
              className={
                v == commercialCarParking ? "style_btn22" : "style_btn2"
              }
              name="commercialCarParking"
              value={v}
              onClick={onFieldChange}
            >
              {v}
            </button>
          ))}
        </div>
      </div>

      <div className="home_type">
        <p className="list_heading">How many parking spaces?</p>
        <div className="style_btn_div">
          <InputGroup>
            <Form.Control
              type="number"
              min={0}
              className="inputField form-control"
              name="commercialCarParkingSize"
              value={commercialCarParkingSize}
              onChange={onFieldChange}
            />
          </InputGroup>
        </div>
      </div>
    </div>
  );
}
