import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";
import * as CONST from "./actionType";
import * as act from "./actions";
import { createProperty } from "backend_helper/api_helper";

function* createSaga({ payload: { data, callback } }) {
  try {
    let response = yield call(createProperty, data);

    if (response?.error) {
      yield put(act.createPropertyFail(response?.error));
      return;
    }
    callback && callback(response);
    if (!response?.data?.isSuccess) {
      yield put(act.createPropertyFail(response?.data?.message));
      return;
    }
    yield put(act.createPropertySuccess(response?.data));
  } catch (error) {
    console.log(error);
    let err = error?.response?.data?.message ?? "Server Error!";
    yield put(act.createPropertyFail(err));
  }
}

export default function* createPropertySaga() {
  yield takeEvery(CONST.CREATE_PROPERTY, createSaga);
}
