import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { Input, Form, FormGroup } from "reactstrap";
import Accordion from "react-bootstrap/Accordion";

class Faqcenter extends Component {
  state = {
    data: {},
    exploreData: [],
    tab: "tab1",
  };
  render() {
    return (
      <section className="Faq-center">
        <Container>
          <div className="faq-search-cont text-center">
            <h1>How we can help you?</h1>

            <Form className="position-relative mt-4">
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  placeholder="Search your Question"
                />
                <div className="newsearch-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ion"
                    width="30"
                    height="30"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M344.5 298c15-23.6 23.8-51.6 23.8-81.7 0-84.1-68.1-152.3-152.1-152.3C132.1 64 64 132.2 64 216.3c0 84.1 68.1 152.3 152.1 152.3 30.5 0 58.9-9 82.7-24.4l6.9-4.8L414.3 448l33.7-34.3-108.5-108.6 5-7.1zm-43.1-166.8c22.7 22.7 35.2 52.9 35.2 85s-12.5 62.3-35.2 85c-22.7 22.7-52.9 35.2-85 35.2s-62.3-12.5-85-35.2c-22.7-22.7-35.2-52.9-35.2-85s12.5-62.3 35.2-85c22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2z"
                      fill="#cccccc"
                    />
                  </svg>
                </div>
                <div className="search-mike">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Group_18715"
                    data-name="Group 18715"
                    width="27.398"
                    height="33.631"
                    viewBox="0 0 27.398 33.631"
                  >
                    <path
                      id="Path_5960"
                      data-name="Path 5960"
                      d="M164.174,414.136A1.174,1.174,0,0,1,163,412.962v-4.949a1.174,1.174,0,1,1,2.348,0v4.949A1.174,1.174,0,0,1,164.174,414.136Z"
                      transform="translate(-150.476 -380.505)"
                      fill="#001878"
                      fillRule="evenodd"
                    ></path>
                    <path
                      id="Path_5961"
                      data-name="Path 5961"
                      d="M166.472,392.338a5.206,5.206,0,0,0-5.185,5.215v7.339a5.185,5.185,0,0,0,10.37,0v-7.339a5.2,5.2,0,0,0-5.184-5.215Zm0,20.116a7.557,7.557,0,0,1-7.534-7.562v-7.339a7.534,7.534,0,0,1,15.067,0v7.339A7.555,7.555,0,0,1,166.472,412.454Z"
                      transform="translate(-152.772 -390.019)"
                      fill="#001878"
                      fillRule="evenodd"
                    ></path>
                    <path
                      id="Path_5962"
                      data-name="Path 5962"
                      d="M168.7,413.733A13.744,13.744,0,0,1,155,399.975a1.174,1.174,0,0,1,2.348,0,11.35,11.35,0,0,0,22.7,0,1.174,1.174,0,0,1,2.348,0,13.744,13.744,0,0,1-13.7,13.758Z"
                      transform="translate(-155 -385.052)"
                      fill="#001878"
                      fillRule="evenodd"
                    ></path>
                    <path
                      id="Path_5963"
                      data-name="Path 5963"
                      d="M169.359,397.1h-3.116a1.174,1.174,0,1,1,0-2.348h3.116a1.174,1.174,0,0,1,0,2.348Z"
                      transform="translate(-149.305 -387.339)"
                      fill="#001878"
                      fillRule="evenodd"
                    ></path>
                    <path
                      id="Path_5964"
                      data-name="Path 5964"
                      d="M169.926,400.442h-4.681a1.174,1.174,0,0,1,0-2.348h4.681a1.174,1.174,0,0,1,0,2.348Z"
                      transform="translate(-149.869 -385.451)"
                      fill="#001878"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </FormGroup>
            </Form>
            <p className="mt-5 or-text fw-6">
              or choose a category to quickly find the help you need
            </p>
          </div>

          <div className="FAQ-taabing">
            <div className="row">
              <div className="FAQ-treand">
                <div className="tab-frame  faq-frame">
                  <div className="clearfix mt-4">
                    <input
                      type="radio"
                      name="tab"
                      id="tab1"
                      checked={this.state.tab === "tab1"}
                      onClick={() => this.setState({ tab: "tab1" })}
                    />

                    <label for="tab1">
                      <div className="coomn-bg">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--mdi"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M7 14c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2m5.6-4c-.8-2.3-3-4-5.6-4c-3.3 0-6 2.7-6 6s2.7 6 6 6c2.6 0 4.8-1.7 5.6-4H16v4h4v-4h3v-4H12.6Z"
                          />
                        </svg>
                      </div>
                      Getting Started
                    </label>

                    <input
                      type="radio"
                      name="tab"
                      id="tab2"
                      checked={this.state.tab === "tab2"}
                      onClick={() => this.setState({ tab: "tab2" })}
                    />

                    <label for="tab2">
                      <div className="coomn-bg">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ic"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M8 17c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1s1 .45 1 1v5c0 .55-.45 1-1 1zm4 0c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v8c0 .55-.45 1-1 1zm4 0c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm2 2H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zm1-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
                          />
                        </svg>
                      </div>
                      Listing & Sale
                    </label>

                    <input
                      type="radio"
                      name="tab"
                      id="tab3"
                      checked={this.state.tab === "tab3"}
                      onClick={() => this.setState({ tab: "tab3" })}
                    />
                    <label for="tab3">
                      <div className="coomn-bg">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ic"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75l1.83-1.83z"
                          />
                        </svg>
                      </div>
                      Profile Changes
                    </label>

                    <input
                      type="radio"
                      name="tab"
                      id="tab4"
                      checked={this.state.tab === "tab4"}
                      onClick={() => this.setState({ tab: "tab4" })}
                    />
                    <label for="tab4">
                      <div className="coomn-bg">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--mdi"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9A3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42c-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3a3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.536 5.536 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13v-1.75M0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9c-.59.68-.95 1.62-.95 2.65V20H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65c2.56.34 4.45 1.51 4.45 2.9V20Z"
                          />
                        </svg>
                      </div>
                      Social Connect
                    </label>
                  </div>

                  {this.state.tab === "tab1" && (
                    <div className="get-started">
                      <div className="faq-heading text-center">
                        <h3>Getting Started</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua.
                        </p>
                      </div>
                      <Accordion
                        defaultActiveKey="0"
                        className="accordion-main"
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="position-relative">
                            <div className="blue-dot"></div>
                            How to list properties on UB Sold?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="1"
                          className="position-relative"
                        >
                          <Accordion.Header>
                            <div className="blue-dot"></div>
                            How to upload video/images while listing property?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="3"
                          className="position-relative"
                        >
                          <Accordion.Header>
                            <div className="blue-dot"></div>
                            How to upload EPC images while listing property?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  )}

                  {this.state.tab === "tab2" && (
                    <div className="listingsale">
                      {" "}
                      <div className="faq-heading text-center">
                        <h3>Listing & Sale</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua.
                        </p>
                      </div>
                      <Accordion
                        defaultActiveKey="0"
                        className="accordion-main"
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="position-relative">
                            <div className="blue-dot"></div>
                            How to list properties on UB Sold?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="1"
                          className="position-relative"
                        >
                          <Accordion.Header>
                            <div className="blue-dot"></div>
                            How to upload video/images while listing property?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="3"
                          className="position-relative"
                        >
                          <Accordion.Header>
                            <div className="blue-dot"></div>
                            How to upload EPC images while listing property?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  )}
                  {this.state.tab === "tab3" && (
                    <div className="profile-chnages">
                      {" "}
                      <div className="faq-heading text-center">
                        <h3>Profile Changes</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua.
                        </p>
                      </div>
                      <Accordion
                        defaultActiveKey="0"
                        className="accordion-main"
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="position-relative">
                            <div className="blue-dot"></div>
                            How to list properties on UB Sold?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="1"
                          className="position-relative"
                        >
                          <Accordion.Header>
                            <div className="blue-dot"></div>
                            How to upload video/images while listing property?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="3"
                          className="position-relative"
                        >
                          <Accordion.Header>
                            <div className="blue-dot"></div>
                            How to upload EPC images while listing property?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  )}

                  {this.state.tab === "tab4" && (
                    <div className="Social-connect">
                      {" "}
                      <div className="faq-heading text-center">
                        <h3>Social Connect</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat, sed diam
                          voluptua.
                        </p>
                      </div>
                      <Accordion
                        defaultActiveKey="0"
                        className="accordion-main"
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="position-relative">
                            <div className="blue-dot"></div>
                            How to list properties on UB Sold?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="1"
                          className="position-relative"
                        >
                          <Accordion.Header>
                            <div className="blue-dot"></div>
                            How to upload video/images while listing property?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="3"
                          className="position-relative"
                        >
                          <Accordion.Header>
                            <div className="blue-dot"></div>
                            How to upload EPC images while listing property?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
}

export default Faqcenter;
