import React, { useEffect } from "react";
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import Settingsidebar from "./Settingsidebar";
import Settingdashboard from "./Settingdashboard";
import { Container } from "react-bootstrap";

export default function Buyersetting() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Container>
      <div className="seller_container">
        <Settingsidebar />
        <Settingdashboard />
      </div>
      </Container>
     
      <Footer />
    </>
  );
}
