import React, { useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Collection from "assets/svg/collection.svg";
import Drop from "assets/svg/drop.svg";
import close_icon from "assets/svg/close_icon.svg";
import Fade from "react-reveal/Fade";
import { Modal, InputGroup, Form, Container } from "react-bootstrap";
import ScrollTop from "components/scrollTop";

import { Button, FormGroup, Label, Input, FormText } from "reactstrap";

const jsonData = [
  {
    name: "Selling",
    subHeading:
      "we have simplified the process to sell your home into 5 simple steps.",
    price: "Sell your home from just £49.99",
    img: "selling",
  },
  {
    name: "Property Valuation",
    subHeading:
      "Its important to sell house at the right price. We offer online, onsite or a RICS valuation. Our prices are as follows",
    price: "£10, Onsite - £50, RICS - £100",
    img: "valuation",
  },
  {
    name: "EPC",
    subHeading:
      "when you sell your house you'll need an energy performance certificate, this confirms how energy-efficient your property is.",
    price: "£10, Onsite - £50, RICS - £100",
    img: "gg",
  },
  {
    name: "Floorplan",
    subHeading:
      "Adding a floorplan gives an indication of how your property spaces flow, therefore very useful to buyers.",
    price: "Our prices range from £45 - £65.",
    img: "floorplan",
  },
  {
    name: "Solicitors",
    subHeading:
      "Price of conveyancing will vary depending on price, ownership, property title and if your buying, selling or both.",
    price: "Our prices range from £45 - £65.",
    img: "solicitors",
  },
  {
    name: "Removals",
    subHeading:
      "Moving from one place to another depends on the number of items and the distance to between properties.",
    price: "Our prices range from £45 - £65.",
    img: "removals",
  },
  {
    name: "Mortgage brokers",
    subHeading:
      "Help you find the best lenders to help finance your property purchase",
    price: "Our prices range from £45 - £65.",
    img: "mortgage",
  },
  {
    name: "Cleaners",
    subHeading: "Our cleaners can help your property sparkle!",
    price: "Cleaners charge a flat fee of £400.",
    img: "cleaners",
  },
];

export default function Faq() {
  const [activeAccordion, setactiveAccordion] = useState("0");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [state, setState] = useState({
    tab: "tab1",
  });

  return (
    <>
      <Header />
      <ScrollTop />
      {/* modal solictor */}

      {/* modal solictor */}
      {/* modal removal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered={true}
        keyboard={false}
        size={"lg"}
        className="removals_modal"
      >
        <div className="removals_container">
          <div className="d-flex justify-content-between w-100 align-items-center">
            <p className="quote_title">Customised quote</p>
            <p className="m-0" onClick={handleClose}>
              <img src={close_icon} width="25px" height="25px" />{" "}
            </p>
          </div>
          <p className="m-0 text-muted">
            Enter the following details and we will send you a customised quote.
          </p>
          <hr />
          <div className="row mt-4">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12">
              <Form.Label className="input_label text-left">
                Collection Address
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  className="inputField form-control"
                  placeholder="Postal Code here"
                  // onChange={this.handleChange.bind(this)}
                />
                <div className="search-icon">
                  <img src={Collection} />
                </div>
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12">
              <Form.Label className="input_label text-left">
                Drop-off address
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  className="inputField form-control"
                  placeholder="Postal Code here"
                  // onChange={this.handleChange.bind(this)}
                />
                <div className="search-icon">
                  <img src={Drop} />
                </div>
              </InputGroup>
            </div>
            <p className="parking_text">
              Are you aware of any parking restrictions or access issues to your
              property?
            </p>
            <div>
              <button className="style_btn">Yes</button>
              <button className="style_btn2">No</button>
            </div>
            <p className="parking_text">
              Submit images and videos of all of your possessions to be moved.
            </p>
            <div className=" w-100 text-center">
              <button className="contact_submit">Submit</button>
            </div>
          </div>
        </div>
      </Modal>
      {/* modal remoel close */}
      <Container>
        <div className="services_section">
          <div className="row  m-0 justify-content-center text-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-10">
              <h1>Services</h1>
              <p className="service-para">
                All services are available when a property listing has been
                created
              </p>
            </div>

            <div className="search_services">
              <div className=" search_input_box">
                <Form.Label className="input_label text-left">
                  Enter your postcode to see prices
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="password"
                    className="inputField form-control"
                    placeholder="Postal Code here"
                    // onChange={this.handleChange.bind(this)}
                  />
                  <div className="search-icon">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--typcn"
                      width="20"
                      height="20"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#636363"
                        d="M17.657 5.304c-3.124-3.073-8.189-3.073-11.313 0a7.78 7.78 0 0 0 0 11.13L12 21.999l5.657-5.565a7.78 7.78 0 0 0 0-11.13zM12 13.499c-.668 0-1.295-.26-1.768-.732a2.503 2.503 0 0 1 0-3.536c.472-.472 1.1-.732 1.768-.732s1.296.26 1.768.732a2.503 2.503 0 0 1 0 3.536c-.472.472-1.1.732-1.768.732z"
                      />
                    </svg>
                    {/* icon */}
                  </div>
                  <button className="contact_submit2">Search Services</button>
                </InputGroup>
              </div>
            </div>
          </div>
          <div className="services_card_container">
            <div className="row">
              {jsonData.map((d, i) => {
                return (
                  <>
                    <div className="col-md-6 col-sm-12 col-xl-4 col-12 col-lg-4">
                      <div className="services_card">
                        <div>
                          <img src={require(`assets/images/${d.img}.png`)} />
                        </div>
                        <div className="service_card_title">
                          <p>
                            <b>{d.name}</b>
                          </p>
                          <p className="services_sub_text">{d.subHeading}</p>
                        </div>
                        <p className="services_price_text">{d.price}</p>
                        <div
                          className="services_card_hover"
                          onClick={i == 5 && handleShow}
                        >
                          <button className="book_btn">Book Now </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
