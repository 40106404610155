import React, { useState, useEffect } from "react";

import { Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Bars from "../../assets/images/bars.png";

// images
import Cardone from "assets/images/card-one.jpg";

const Budgetitemsmid = () => {
  const history = useHistory();
  return (
    <section className="Floorplanmid-main">
      <Container>
        <div className="wrap-box wrap-date">
          <div className="go-back-div epcsellerback">
            <p>
              <svg
                aria-hidden="true"
                role="img"
                className="iconify iconify--ic"
                width="22"
                height="22"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"
                ></path>
              </svg>
              Back
            </p>
            <Button className="lockright">
              <svg
                role="img"
                className="iconify iconify--bx"
                width="20"
                height="20"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  d="M5 22h14a2 2 0 0 0 2-2V9a1 1 0 0 0-1-1h-3v-.777c0-2.609-1.903-4.945-4.5-5.198A5.005 5.005 0 0 0 7 7v1H4a1 1 0 0 0-1 1v11a2 2 0 0 0 2 2zm12-12v2h-2v-2h2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-2 3h2v2H7v-2z"
                  fill="#fff"
                />
              </svg>
            </Button>
          </div>
          <div className="floorplan-box pro-valuation availability-here">
            <h5 className="text-left">Your Bucket items - 3</h5>

            <ul className="budget-item-list">
              {[1, 2, 3].map(() => {
                return (
                  <li>
                    <div className="listwrap-budget">
                      <div className="budget-left">
                        <div className="budgetname">
                          <img src={Bars} className="img-fluid bars-img" />
                          <p>EPC</p>
                        </div>
                        <div className="rating-budgt">
                          <p>
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--material-symbols"
                              width="24"
                              height="24"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#EFB514"
                                d="M8.3 20.125q-.575.45-1.175.025q-.6-.425-.4-1.125L8.15 14.4l-3.625-2.575q-.6-.425-.362-1.125q.237-.7.962-.7H9.6l1.45-4.8q.125-.35.388-.538q.262-.187.562-.187q.3 0 .562.187q.263.188.388.538L14.4 10h4.475q.725 0 .963.7q.237.7-.363 1.125L15.85 14.4l1.425 4.625q.2.7-.4 1.125q-.6.425-1.175-.025L12 17.3Z"
                              ></path>
                            </svg>
                            4.6
                          </p>
                        </div>
                      </div>
                      <div className="budget-right">
                        <h4>£50</h4>
                        <Button className="btn-detele">
                          <svg
                            role="img"
                            className="iconify iconify--ic"
                            width="30"
                            height="30"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#686868"
                              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>

            <div className="floor-plan-btns mt-4 budget-btn">
              <p>
                Amount to Pay: <strong>£150</strong>
              </p>
              <Button className="Continue-btn"> Make Payment</Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Budgetitemsmid;
