import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";
import createPropertySaga from "./create-property/saga";
import singupSaga from "./signup/saga";

export default function* rootSaga() {
  yield all([
    singupSaga(),
    createPropertySaga()
  ]);
}
