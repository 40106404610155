import React from "react";
import Stepper from "../../../components/Stepper";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import ScrollTop from "components/scrollTop";
import toast from 'react-hot-toast'
import {Container, Button , Row, Col} from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

export default function Coownerdetails() {
  const history = useHistory();

  return (
    <>
      <Header />
      <Container>
      <div className="list_your_property">
        <h2 className="list_title">List your property</h2>
        {/* <h2 className="list_title">List your property</h2> */}
        <div className="box_shadow_wrapper">
          <Fade>
            {/* <Stepper /> */}

            <div className="custom-steeper">
              <div className="common-step step1 position-relative">
                <div className="stepbox process">
                  {/* <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ic"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#111"
                      d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                    />
                  </svg> */}
                  01
                </div>
                <span>
                  {/* Property <br /> information */}
                  Address and <br/> Ownership
                </span>
              </div>

              <div className="common-step step2 position-relative">
                <div className="stepbox uundeprocss">02</div>
                <span>Property Details</span>
              </div>

              <div className="common-step step3 position-relative">
                <div className="stepbox uundeprocss">03</div>
                <span>Value</span>
              </div>

              <div className="common-step step4 position-relative">
                <div className="stepbox uundeprocss">04</div>
                <span>
                Images and<br /> Video
                
                </span>
              </div>

              <div className="common-step step5 position-relative">
                <div className="stepbox uundeprocss">05</div>
                <span>Review and<br /> Submit</span>
              </div>
            </div>

           
          
           <div className="Ownership-status list_start">
              <h5>Co-owners details</h5>
              <p>Please add names of the co-owners of the property</p>


              <Form className="vedor-form mortage-frm coower-form">
              <Row>
                <Col lg={5} md={6} sm={12}>
                <FormGroup className="position-relative">
     
                      <Input type="text" name="email" id="exampleEmail" placeholder="Marry Jackson" />
                      <div className="user-here">
                      <svg className="iconify iconify--ri" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#636363" d="M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12a6 6 0 0 1 0 12z"/></svg>
                      </div>
               </FormGroup>
                </Col>


                <Col lg={7} md={6} sm={12}>
                <FormGroup className="position-relative">
     
          <Input type="text" name="email" id="exampleEmail" placeholder="Jimmyni.01@gmail.com" />
          <div className="user-here">
          <svg  role="img" className="iconify iconify--bxs" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#636363" d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7l-8 5.334L4 8.7V6.297l8 5.333l8-5.333V8.7z"/></svg>
                      </div>
        </FormGroup>
                </Col>



                <Col lg={5} md={6} sm={12}>
                <FormGroup className="position-relative">
     
                      <Input type="text" name="email" id="exampleEmail" placeholder="Sara William" />
                      <div className="user-here">
                      <svg className="iconify iconify--ri" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#636363" d="M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12a6 6 0 0 1 0 12z"/></svg>
                      </div>
               </FormGroup>
                </Col>


                <Col lg={7} md={6} sm={12}>
                <FormGroup className="position-relative">
     
          <Input type="text" name="email" id="exampleEmail" placeholder="Jimmyni.01@gmail.com" />
          <div className="user-here">
          <svg  role="img" className="iconify iconify--bxs" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#636363" d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7l-8 5.334L4 8.7V6.297l8 5.333l8-5.333V8.7z"/></svg>
                      </div>
        </FormGroup>
                </Col>




                <Col lg={5} md={6} sm={12}>
                <FormGroup className="position-relative">
     
                      <Input type="text" name="email" id="exampleEmail" placeholder="Jimmy nikk" />
                      <div className="user-here">
                      <svg className="iconify iconify--ri" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#636363" d="M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12a6 6 0 0 1 0 12z"/></svg>
                      </div>
               </FormGroup>
                </Col>


                <Col lg={7} md={6} sm={12}>
                <FormGroup className="position-relative">
     
          <Input type="text" name="email" id="exampleEmail" placeholder="Jimmyni.01@gmail.com" />
          <div className="user-here">
          <svg  role="img" className="iconify iconify--bxs" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#636363" d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7l-8 5.334L4 8.7V6.297l8 5.333l8-5.333V8.7z"/></svg>
                      </div>
        </FormGroup>
                </Col>


                <a href="#" className="addco-oeen">+ Add Co-owner</a>
               
                

              </Row>

              <div className="floor-plan-btns mt-4">
              <Button className="back-btn"> Clear</Button>
              <Button className="Continue-btn"> Calculate</Button>
            </div>
            </Form>


            </div>
          
           

          
          </Fade>
        </div>
      </div>
      </Container>

      <ScrollTop />
      <Footer />
    </>
  );
}
