import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import ScrollTop from "components/scrollTop";

// images
import Mastrocard from "assets/images/mastro.png";
import Paypal from "assets/images/paypal.png";
import Googlepay from "assets/images/googlepay.png";
import Applepay from "assets/images/applepay.png";

export default function Paymentmethod() {
  return (
    <>
      <ScrollTop />
      <Header />
      <Container>
        <div className="seller_container">
          <Sidebar />
          <div className="profile_right_side">
            <div className="d-flex justify-content-between">
              <h4 className="dashboard_text">Payment Methods</h4>
            </div>

            <div className="card-add-here">
              <div className="card-add-btn">
                <h6>Saved Cards</h6>
                <Link to="/buyerprofile/addnewcard" className="green-col">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ion"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M346.5 240H272v-74.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-74.5c-8.8 0-16 6-16 16s7.5 16 16 16H240v74.5c0 9.5 7 16 16 16s16-7.2 16-16V272h74.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z"
                      fill="currentColor"
                    />
                    <path
                      d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"
                      fill="currentColor"
                    />
                  </svg>
                  Add Card
                </Link>
              </div>

              <div className="card-lists">
                <ul>
                  <li>
                    <div className="card-one">
                      <div className="card-logo">
                        <img src={Mastrocard} alt="" className="mastrocard" />
                      </div>
                      <div className="card-number">
                        <div className="numone">****</div>
                        <div className="numone">****</div>
                        <div className="numone">****</div>
                        <div className="numone">8945</div>
                      </div>
                      <div className="card-dates">
                        <div className="expriydate">
                          <p className="expy">EXPIRY DATE</p>
                          <p>05/2020</p>
                        </div>
                        <div className="expriydate">
                          <p className="expy">CARD HOLDER NAME</p>
                          <p>John Wilamson</p>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card-two">
                      <div className="card-logo text-white">VISA</div>
                      <div className="card-number">
                        <div className="numone">****</div>
                        <div className="numone">****</div>
                        <div className="numone">****</div>
                        <div className="numone">8945</div>
                      </div>
                      <div className="card-dates">
                        <div className="expriydate">
                          <p className="expy">EXPIRY DATE</p>
                          <p>05/2020</p>
                        </div>
                        <div className="expriydate">
                          <p className="expy">CARD HOLDER NAME</p>
                          <p>John Wilamson</p>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="card-three">
                      <div className="icon-plus">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ion"
                          width="32"
                          height="32"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M346.5 240H272v-74.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-74.5c-8.8 0-16 6-16 16s7.5 16 16 16H240v74.5c0 9.5 7 16 16 16s16-7.2 16-16V272h74.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z"
                            fill="currentColor"
                          />
                          <path
                            d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="othewr-way">
                <h4>Other Ways to Pay</h4>
                <div className="other-wway-acnhor">
                  <Row>
                    <Col lg={6}>
                      <a href="#">
                        <img
                          src={Paypal}
                          alt=""
                          className="img-fluid common-width"
                        />
                        Paypal
                      </a>
                    </Col>
                    <Col lg={6}>
                      <a href="#">
                        <img
                          src={Googlepay}
                          alt=""
                          className="img-fluid common-width"
                        />
                        Google Pay
                      </a>
                    </Col>
                    <Col lg={6}>
                      <a href="#">
                        <img
                          src={Applepay}
                          alt=""
                          className="img-fluid common-width"
                        />
                        Apple Pay
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
}
