import React from "react";
import "./Modal.css";
import SaveIcon from "../../assets/icon/save.png";
import EyEIcon from "../../assets/icon/eye.png";
import Location from "../../assets/icon/location.png";
import PropertiesListModal from "./propertiesListModal";
const PropertiesCard = ({
  offerPrice,
  coverPic,
  firstPic,
  houseType,
  location,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <div className="propertiesCardContainer">
      <div className="imageContainer">
        <div className="propertiesCoverPic">
          <img className="pic" src={coverPic} alt="My Image" />
        </div>
        <div className="propertiesOtherMedia">
          <div className="otherMedia">
            <img
              className="pic"
              src="https://images.unsplash.com/photo-1501183638710-841dd1904471?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              alt="My Image"
            />
          </div>
          <div className="otherMedia">
            <img
              className="pic"
              src="https://images.unsplash.com/photo-1501183638710-841dd1904471?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              alt="My Image"
            />
          </div>
        </div>
      </div>
      <div className="propertiesDetailsContainer">
        <div className="propertiesDetailBox">
          <div className="priceModalBox">
            <div className="propertiesOfferPrice">{`$ ${offerPrice}`}</div>
            <div
              onClick={() => {
                setIsModalOpen(!isModalOpen);
              }}
              className="modalController"
            >
              <div className="bullet"></div>
              <div className="bullet"></div>
              <div className="bullet"></div>
            </div>
            {isModalOpen && (
              <PropertiesListModal
                onClick={() => setIsModalOpen(!isModalOpen)}
              />
            )}
          </div>
          <div className="propertiesTypeText">{houseType}</div>
          <div>
            <div className="bookMarkViewContainer">
              <div className="bookMarkViewBox">
                <div className="propertiesCardIcon">
                  <img className="icon" src={SaveIcon} />
                </div>
                <div className="bookMarkViewBoxText">11 save</div>
              </div>
              <div className="bookMarkViewBox">
                <div className="propertiesCardIcon">
                  <img className="icon" src={EyEIcon} />
                </div>
                <div className="bookMarkViewBoxText">556 view</div>
              </div>
            </div>
            <div>
              <div className="bookMarkViewBox">
                <div className="propertiesCardIcon">
                  <img className="icon" src={Location} />
                </div>
                <div className="bookMarkViewBoxText">{location}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="propertiesButtonContainer">
          <div className="propertiesButton">Preview ad</div>
          <div className="propertiesButton">Edit Detail</div>
        </div>
      </div>
    </div>
  );
};

export default PropertiesCard;
