import React from "react";
import Img from "assets/images/m_userimg.png";
import contactIcon from "assets/svg/ic_contact.svg";
import ic_contact from "assets/svg/ic_contact-1.svg";
import help from "assets/svg/ic_pp-1.svg";
import { Link, Switch, useHistory } from "react-router-dom";
import abouts from "assets/svg/ic_pp.svg";
import settings from "assets/svg/Group_9390.svg";
import bottom_arrow from "assets/svg/bottom_arrow.svg";
import logout from "assets/svg/logout.svg";
import Fade from "react-reveal/Fade";
import { useDispatch } from "react-redux";
import { updateToken } from "store/actions";
import Form from "react-bootstrap/Form";
import GetLocalUser, { SetLocalUser } from "store/localStorage";

export default function Sidebar(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  function onRoleChange(e) {
    let u = GetLocalUser();
    u.userType = e.target.value;
    SetLocalUser(u);
    if (e.target.value == "seller") {
      history.push("/sellerprofile");
    } else {
      history.push("/buyerprofile");
    }
  }
  const logout2 = (e) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(updateToken(null));
    history.push("/");
  };
  return (
    <>
      {/* <Fade> */}
      <div className="profile_left_side">
        <div className="profile_top_card position-relative">
          <Link to="/sellerprofile/edit-profile">
            <div className="dashboard-edit">
              <svg
                aria-hidden="true"
                role="img"
                className="iconify iconify--ic"
                width="25"
                height="25"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#ffffff"
                  d="m14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83l3.75 3.75l1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
                />
              </svg>
            </div>
          </Link>
        </div>
        <div className="profile_img_div">
          <img src={Img} className="" />
          <p className="profile_title"> Marry Jackson</p>
          <p className="profile_sub_title"> Seller</p>
        </div>
        <div className="profile_menu">
          <ul>
            <Link to="#" className="menu_list">
              <li>
                <img src={contactIcon} />
                <span>+91 83098430948</span>
              </li>
            </Link>
            <Link to="#" className="menu_list">
              <li>
                <img src={ic_contact} />
                <span>abc@gmail.com</span>
              </li>
            </Link>
            <hr />
            <div>
              <div className="menu_list" style={{ display: "block" }}>
                <li className="d-flex ">
                  <img src={contactIcon} />
                  <div className="d-flex align-items-center  justify-content-between w-100">
                    <span className="m-0">Current profile</span>
                    <p className="m-0 bottom_arrow">
                      {/* <span>Seller</span> <img src={bottom_arrow} />{" "} */}
                      <Form.Select
                        onChange={onRoleChange}
                        id="roleSelect"
                        className="linkDropDown"
                        aria-label="Default select example"
                        value={'seller'}
                      >
                        <option value="seller">Seller</option>
                        <option value="buyer">Buyer</option>
                      </Form.Select>
                    </p>
                  </div>
                </li>
              </div>
            </div>
            <Link to="/sellerprofile/contact" className="menu_list">
              <li>
                <img src={ic_contact} />
                <span>Contact us</span>
              </li>
            </Link>
            <Link to="/sellerprofile/faq" className="menu_list">
              <li>
                <img src={help} />
                <span>Get help (FAQS)</span>
              </li>
            </Link>
            <Link to="/sellerprofile/about-us" className="menu_list">
              <li>
                <img src={abouts} />
                <span>About us</span>
              </li>
            </Link>
            <Link to="/sellerprofile/setting" className="menu_list">
              <li>
                <img src={settings} />
                <span>Settings</span>
              </li>
            </Link>
            <Link to="/" className="menu_list" onClick={logout2}>
              <li>
                <img src={logout} />
                <span>Logout</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
      {/* </Fade> */}
    </>
  );
}
