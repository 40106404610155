import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup, CloseButton } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { validate } from "backend_helper/validations";
import { sendOtp, sendOtpVerify } from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import close_icon from "assets/svg/close_icon.svg";

export default function Verification({
  showsOTP,
  handleClose4,
  handleShow4,
  currentId,
  handleShow,
  closeSignup,
}) {
  const [fields, setfields] = useState({ otp: "" });
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const changeHalder = (e) => {
    const { name, value } = e.target;
    setError((prev) =>
      value !== "" || undefined || null
        ? { ...prev, [name + "Error"]: false, [name]: value }
        : { ...prev, [name + "Error"]: true, [name]: value }
    );
    setfields({ otp: Number(value) });
  };

  const { otp } = fields;
  //submit
  const submit = (e) => {
    e.preventDefault();
    if (otp?.length < 4) {
      return toast.error("Please enter otp!");
    }
    const callback = (ress) => {
      let { error, isSuccess, message } = ress?.data;
      console.log(ress);
      if (isSuccess) {
        toast.success("Otp Verified successfully");
        console.log(ress);
        localStorage.setItem("authUser", JSON.stringify(ress?.data?.data));
        closeSignup();
        handleClose4();
      } else {
        toast.error(error);
      }
    };
    dispatch(
      sendOtpVerify(
        {
          userId: currentId !== null ? currentId : "24",
          verificationCodeEmail: otp,
        },
        callback
      )
    );
  };

  const handleChange = (otp) => setfields({ otp });

  console.log(otp, 998777);
  return (
    <>
 
      <Modal
        show={showsOTP}
        onHide={handleClose4}
        backdrop="static"
        centered={true}
        keyboard={false}
        className="login_modal forgotmodal login_modal2"
      >
        <Modal.Header>
          <Modal.Title>Verification</Modal.Title>
          <p className="modal-des">
            OTP has been sent to your email, please enter the numbers below to
            verify.
          </p>
          <p className="m-0 close_btn " onClick={() => handleClose4()}>
            {/* <CloseButton
              variant="white"
              disabled={false}
              style={{ width: "15px", height: "15px", color: "#fff" }}
            /> */}
            <img src={close_icon} style={{ width: "24px" }} />
          </p>
        </Modal.Header>
        <Modal.Body>
          <Form className="sign-input" onSubmit={submit}>
            <div className="my-2">
              <div className=" input-groupaa input-groupaa2">
                <OtpInput
                  value={otp}
                  // className="inputField form-control"
                  name="otp"
                  isInputNum={true}
                  onChange={handleChange}
                  numInputs={4}
                />
              </div>
            </div>

            <div className="btn-group-div mt-5">
              <button
                className="back_btn"
                // style={{ height: 50 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose4();
                }}
              >
                Back
              </button>
              <button
                className="logIn_btn log22"
                type="submit"
                // style={{ height: 50 }}
              >
                Verify
              </button>
            </div>
          </Form>
          <div className="or_login_content">
            <small className="p-0 text-center d-block mt-4 new_te">
              Didn't receive the OTP?
              <b>
                <a
                  href="#"
                  className="crete-account"
                  style={{ color: "#001978" }}
                  onClick={() =>
                    dispatch(
                      sendOtp(
                        {
                          userId: currentId !== null ? currentId : "24",
                          type: "email",
                        },
                        (res) =>
                          toast.success(res?.data?.message || res?.data?.error)
                      )
                    )
                  }
                >
                  {" "}
                  Resend OTP
                </a>
              </b>
            </small>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
