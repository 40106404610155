import React from "react";
import { Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

// images
import Cardone from "assets/images/card-one.jpg";

const Quationpagemid = () => {
  const history = useHistory();
  return (
    <section className="Floorplanmid-main">
      <Container>
        <div className="wrap-box wrap-boxepcselller">
        <div className="go-back-div epcsellerback">
          <p>
            <svg aria-hidden="true" role="img" className="iconify iconify--ic" width="22" height="22" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"></path>
            </svg>Back
            </p>
            <Button className="lockright">
            <svg  role="img" className="iconify iconify--bx" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M5 22h14a2 2 0 0 0 2-2V9a1 1 0 0 0-1-1h-3v-.777c0-2.609-1.903-4.945-4.5-5.198A5.005 5.005 0 0 0 7 7v1H4a1 1 0 0 0-1 1v11a2 2 0 0 0 2 2zm12-12v2h-2v-2h2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-2 3h2v2H7v-2z" fill="#fff"/></svg>
            </Button>
        </div>
          <div className="floorplan-box epcsellermain quation-main">
            <h5 className="text-center">Solicitor Quotation</h5>
            <p className="quation-sub text-center">Our quotation is £500 and is based on the following information</p>
             

             <div className="qutaion-partcenter">
                 <div className="quobox1 quotioncommon">
                     <p>Property Price</p>
                     <h5>£400,000</h5>
                 </div>
                 <div className=" quotioncommon">• No. of owners : 2</div>
                 <div className=" quotioncommon">• Tenure : Freehold</div>
                 <div className=" quotioncommon">• Property Location: BL1</div>

             </div>

            <div className="floor-plan-btns quation-btn">
              <Button className="back-btn"> Add to Checkout</Button>
              <Button className="Continue-btn"> Make Payment</Button>
            </div>

            <p className="quationpara">If any of the information is incorrect please go back to your listing and amend details to generate a new quote</p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Quationpagemid;
