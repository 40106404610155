import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

// images
import Vedioimg from "assets/images/vedio-img.jpg";

const Modalnew = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-vedio-col"
      >
        <Modal.Body className="vedio-modal-main">
        <div className="close-icon" onClick={props.onHide}>
            
           <svg aria-hidden="true" role="img" className="iconify iconify--ion" width="22" height="22" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M256 33C132.3 33 32 133.3 32 257s100.3 224 224 224 224-100.3 224-224S379.7 33 256 33zm108.3 299.5c1.5 1.5 2.3 3.5 2.3 5.6 0 2.1-.8 4.2-2.3 5.6l-21.6 21.7c-1.6 1.6-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.6-2.3L256 289.8l-75.4 75.7c-1.5 1.6-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6 0-2.1.8-4.2 2.3-5.6l75.7-76-75.9-75c-3.1-3.1-3.1-8.2 0-11.3l21.6-21.7c1.5-1.5 3.5-2.3 5.6-2.3 2.1 0 4.1.8 5.6 2.3l75.7 74.7 75.7-74.7c1.5-1.5 3.5-2.3 5.6-2.3 2.1 0 4.1.8 5.6 2.3l21.6 21.7c3.1 3.1 3.1 8.2 0 11.3l-75.9 75 75.6 75.9z" fill="#000000"/></svg>
           
        </div>
          <div className="vedio-modal">
            <Row>
              <Col lg={12} md={12} sm={12} className="mt-4">
                <div className="vedio-verification position-relative">
                  <div className="vedio-cont">
                    <div className="vedio-icon">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#ffffff"
                          d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l2.29 2.29c.63.63 1.71.18 1.71-.71V8.91c0-.89-1.08-1.34-1.71-.71L17 10.5z"
                        />
                      </svg>
                    </div>

                    <div className="vedio-cont-head">
                      <h6>Video Verification</h6>
                      <p>
                        To protect our buyers and sellers video verification is
                        required before viewings can take place. All video
                        verification will remain confidential and will not be
                        shared with buyers or sellers.
                      </p>
                      <Button className="verify-btn mt-3">Verify</Button>
                    </div>
                  </div>
                  <div className="exclamation-new">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--zondicons"
                      width="22"
                      height="22"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#F52F2F"
                        d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </Col>

              <Col lg={12} md={12} sm={12}  className="mt-4">
                <div className="vedio-verification position-relative">
                  <div className="vedio-cont">
                    <div className="vedio-icon">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--fa6-solid"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96c0-35.35-28.7-64-64-64zm-336 96c35.35 0 64 28.65 64 64s-28.65 64-64 64s-64-28.65-64-64s28.7-64 64-64zm96 256H80c-8.84 0-16-7.2-16-16c0-44.2 35.82-80 80-80h64c44.18 0 80 35.82 80 80c0 8.8-7.2 16-16 16zm224-64H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0-64H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0-64H368c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.8 0 16 7.2 16 16s-7.2 16-16 16z"
                        />
                      </svg>
                    </div>

                    <div className="vedio-cont-head">
                      <h6>Name & Address</h6>
                      <p>
                        To protect our buyers and sellers video verification is
                        required before viewings can take place. All video
                        verification will remain confidential and will not be
                        shared with buyers or sellers.
                      </p>
                      <Button className="verify-btn mt-3">Submit</Button>
                    </div>
                  </div>
                  <div className="right-check">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--ic"
                      width="22"
                      height="22"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#05D48B"
                        d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                      />
                    </svg>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Modalnew;
