import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { CarouselBanner } from "components/Carousel";
import Dummymap from "assets/images/map.png";
import { useSelector } from "react-redux";
import { createPropertySelector } from "store/selectors";
import { useState } from "react";

import BedroomIcon from "../../../assets/svg/bedroom-icon.svg";

const Propertydetailstep = () => {
  const history = useHistory();
  const propertyImagesData = useSelector(createPropertySelector.stepHour);
  const data = propertyImagesData?.propertyImages;
  const prevData = useSelector(createPropertySelector.step1);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section className="propety-detailstep">
      <Container>
        <div className="propety-details-box">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="propety-images">
                <div style={{ height: "400px" }}>
                  {/* <img src={Main} className="img-fluid" /> */}
                  <CarouselBanner data={data} activeIndex={activeIndex} />
                </div>
                <div className="img-list mt-3">
                  <ul
                    style={{
                      display: "grid",
                      width: "100%",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "18px",
                    }}
                  >
                    {data.map((item, index) => {
                      return (
                        <li
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() => setActiveIndex(index)}
                        >
                          {item.mediaType === "image" ? (
                            <img
                              style={{
                                width: "100%",
                                height: "150px",
                                borderRadius: "6px",
                              }}
                              src={item.base64}
                              className="img-fluid"
                            />
                          ) : (
                            <video
                              style={{
                                width: "100%",
                                height: "150px",
                                borderRadius: "6px",
                                objectFit: "cover",
                              }}
                              src={item.base64}
                            />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div
                  style={{
                    color: "#5D5D5D",
                    fontSize: "14px",
                    display: "flex",
                    gap: "10px",
                  }}
                  className="mb-2 "
                >
                  <span
                    style={{
                      borderRight: "2px solid rgb(93 93 93 / 20%)",
                      paddingRight: "10px",
                    }}
                  >
                    Added on{" "}
                    <span style={{ fontWeight: 500 }}>17 Dec, 2022</span>
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      borderRight: "2px solid rgb(93 93 93 / 20%)",
                      paddingRight: "10px",
                    }}
                  >
                    View Count
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Save Count
                  </span>
                </div>
              </div>
            </Col>
            {/* <Col lg={6} md={6} sm={12}>
              <div className="propety-images-details">
                <div className="propety-box-one">
                  <div className="box-one-price">
                    <h3 className="green-big">$ 150,000,00</h3>
                    <div className="three-iocn">
                      <div className="share-col">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--ph"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="currentColor"
                            d="M216 200a40 40 0 0 1-80 0a41 41 0 0 1 2.7-14.5l-46.1-29.6a40 40 0 1 1 0-55.8l46.1-29.6A41 41 0 0 1 136 56a40.1 40.1 0 1 1 11.4 27.9l-46.1 29.6a40.3 40.3 0 0 1 0 29l46.1 29.6A40 40 0 0 1 216 200Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="flat-details">
                    <h4>3 BHK Flat</h4>
                  </div>
                  <div className="location-icon">
                    <p>
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="24"
                        height="24"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#3399FF"
                          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                        />
                      </svg>
                      4571 Giraffe Hill Drive, Frisco, Texas, 75034
                    </p>
                  </div>
                </div>

                <div className="propety-box-two mt-3">
                  <div className="facilit-list">
                    <ul>
                      <li>
                        <div className="facilit-list-one">
                          <div className="facilit-listicon">
                            <img src={Bed} />
                          </div>
                          <p>3 Bedrooms</p>
                        </div>
                      </li>
                      <li>
                        <div className="facilit-list-one">
                          <div className="facilit-listicon">
                            <img src={Car} />
                          </div>
                          <p>3 Parking</p>
                        </div>
                      </li>
                      <li>
                        <div className="facilit-list-one">
                          <div className="facilit-listicon">
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--maki"
                              width="15"
                              height="15"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="#001978"
                                d="M13 8c0 3.31-2.19 6-5.5 6S2 11.31 2 8a5.33 5.33 0 0 1 5 3.61V7H4.5A1.5 1.5 0 0 1 3 5.5v-3a.5.5 0 0 1 .9-.3l1.53 2l1.65-3a.5.5 0 0 1 .84 0l1.65 3l1.53-2a.5.5 0 0 1 .9.3v3A1.5 1.5 0 0 1 10.5 7H8v4.61A5.33 5.33 0 0 1 13 8z"
                              />
                            </svg>
                          </div>
                          <p>Garden</p>
                        </div>
                      </li>
                      <li>
                        <div className="facilit-list-one">
                          <div className="facilit-listicon">
                            <img src={Bath} />
                          </div>
                          <p>3 Bathrooms</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>

          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "20px",
            }}
            className="my-3"
          >
            {prevData.propertyType === "Residential" && (
              <div
                style={{
                  background: "#FAFAFA",
                  borderRadius: "8px",
                  width: "50%",
                  padding: "18px",
                  display: "grid",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }}
              >
                {[
                  {
                    label: `${prevData?.residentialBedroomCount} Bedrooms`,
                  },
                  {
                    label: `${prevData?.residentialBathroomCount} Bathrooms`,
                  },
                  {
                    label: `${prevData?.residentialReceptionRooms} Reception`,
                  },
                ].map(({ label }, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        height: "77px",
                        padding: "3px",
                        borderRadius: "8px",
                        boxShadow: "0px 3px 12px #00000014",
                        background: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "3px",
                      }}
                    >
                      <img
                        src={BedroomIcon}
                        alt="bedroom-icon"
                        style={{ width: "35px", height: "35px" }}
                      />
                      <span style={{ fontSize: "16px", fontWeight: 500 }}>
                        {label}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              style={{
                background: "#FAFAFA",
                borderRadius: "8px",
                width: prevData.propertyType === "Residential" ? "50%" : "100%",
                padding: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              <button
                style={{
                  appearance: "none",
                  outline: "none",
                  border: "0.5px solid #9D9C9C",
                  width: "190px",
                  height: "46px",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#6C6C6C",
                  background: "#FFFFFF",
                }}
              >
                Arrange Viewing
              </button>
              <button
                style={{
                  appearance: "none",
                  outline: "none",
                  border: "0.5px solid #190835",
                  width: "190px",
                  height: "46px",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "white",
                  background: "#190835",
                }}
              >
                Send Offer
              </button>
            </div>
          </div>

          <div className="additional-feature">
            <Row>
              <Col lg={6} md={6} sm={12}>
                <div className="style-description">
                  <p>Property Style</p>
                  <h6>Detached Style</h6>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="style-description">
                  <p>Property Age</p>
                  <h6>Brand New</h6>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="style-description">
                  <p>Tenure</p>
                  <h6>Freehold</h6>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="style-description">
                  <p>Central Heating</p>
                  <h6>Full Central Heating</h6>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="style-description">
                  <p>Reception rooms</p>
                  <h6>1 Room</h6>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="style-description">
                  <p>Broadband connnected</p>
                  <h6>Yes - 5.2 Mbps</h6>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="style-description">
                  <p>Overall property condition</p>
                  <h6>Good</h6>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="style-description">
                  <p>Energy Performance Certificate</p>
                  <h6>
                    <a href="#">View Certificate</a>
                  </h6>
                </div>
              </Col>
            </Row>
          </div>

          <div className="map-location">
            <h5>Map Location</h5>
            <img src={Dummymap} />
          </div>

          {/* <div className="near-public-facilty">
            <h5>Nearest public facilities</h5>

            <div className="near-list-main">
              <div className="near-list-one">
                <div className="near-list-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--gridicons"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#7D7F88"
                      d="M9 20c0 1.1-.9 2-2 2s-1.99-.9-1.99-2S5.9 18 7 18s2 .9 2 2zm8-2c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2s2-.9 2-2s-.9-2-2-2zm.396-5a2 2 0 0 0 1.952-1.566L21 5H7V4a2 2 0 0 0-2-2H3v2h2v11a2 2 0 0 0 2 2h12a2 2 0 0 0-2-2H7v-2h10.396z"
                    />
                  </svg>
                </div>
                <div className="near-list-content">
                  <h6>Minimarket</h6>
                  <p>200m</p>
                </div>
              </div>

              <div className="near-list-one">
                <div className="near-list-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ri"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#7D7F88"
                      d="M21 20h2v2H1v-2h2V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v17zM11 8H9v2h2v2h2v-2h2V8h-2V6h-2v2zm3 12h2v-6H8v6h2v-4h4v4z"
                    />
                  </svg>
                </div>
                <div className="near-list-content">
                  <h6>Hospital</h6>
                  <p>100m</p>
                </div>
              </div>

              <div className="near-list-one">
                <div className="near-list-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--icomoon-free"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#7D7F88"
                      d="M3.5 0c-1.657 0-3 1.567-3 3.5c0 1.655.985 3.042 2.308 3.406l-.497 8.096A.931.931 0 0 0 3.25 16h.5c.55 0 .972-.449.939-.998l-.497-8.096C5.515 6.541 6.5 5.155 6.5 3.5c0-1.933-1.343-3.5-3-3.5zm10.083 0l-.833 5h-.625l-.417-5h-.417l-.417 5h-.625l-.833-5h-.417v6.5a.5.5 0 0 0 .5.5h1.302l-.491 8.002a.931.931 0 0 0 .939.998h.5c.55 0 .972-.449.939-.998L12.197 7h1.302a.5.5 0 0 0 .5-.5V0h-.417z"
                    />
                  </svg>
                </div>
                <div className="near-list-content">
                  <h6>Eating Places</h6>
                  <p>200m</p>
                </div>
              </div>

              <div className="near-list-one">
                <div className="near-list-icon">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ph"
                    width="18"
                    height="18"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 256 256"
                  >
                    <path
                      fill="#7D7F88"
                      d="M188 24H68a32.1 32.1 0 0 0-32 32v128a32.1 32.1 0 0 0 32 32h12l-14.4 19.2a8 8 0 0 0 1.6 11.2A7.7 7.7 0 0 0 72 248a8 8 0 0 0 6.4-3.2L100 216h56l21.6 28.8a8 8 0 0 0 6.4 3.2a7.7 7.7 0 0 0 4.8-1.6a8 8 0 0 0 1.6-11.2L176 216h12a32.1 32.1 0 0 0 32-32V56a32.1 32.1 0 0 0-32-32Zm0 176H68a16 16 0 0 1-16-16v-48h152v48a16 16 0 0 1-16 16Zm-92-28a12 12 0 1 1-12-12a12 12 0 0 1 12 12Zm88 0a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z"
                    />
                  </svg>
                </div>
                <div className="near-list-content">
                  <h6>Train Station</h6>
                  <p>500m</p>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="about-location">
            <h5>About location’s neighborhood</h5>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
              elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
              magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
              justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
              takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
              sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
              tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet
            </p>
          </div> */}
        </div>
      </Container>
    </section>
  );
};

export default Propertydetailstep;
