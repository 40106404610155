import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";


// images
import Cardone from "assets/images/card-one.jpg";



const Solicitoremid = () => {
    const history = useHistory();
    return (
        <section className='Solicitoremid'>
              <Container>
                  <div className='Solicitoremid-content'>
                  <div className='Solicitoremid-head text-center'>
                      <h1>Don't have a solicitor?</h1>
                      <p>Best solicitor according to your needs</p>
                  </div>

                  <div className='solicitor-card '>
                      <div className='card-img'>
                        <img src={Cardone}/>
                      </div>


                      <div className='all-cover'>

                      
                      <div className='card-content'>
                          <h5>Mark Jackson</h5>
                          <p>
                          <svg aria-hidden="true" role="img" className="iconify iconify--ic" width="18" height="18" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#EFB514" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.46 4.73L5.82 21z"/></svg>
                              4.6</p>
                      </div>

                      <div className='card-content my-3'>
                          <p className='startig-col'>Starting at</p>
                          <p className='col-green'>$25</p>
                      </div>
                      <div className='card-content'>
                      <Button className="hire-now" onClick={()=>history.push("/memorandum")}>Hire Now</Button>
                      </div>
                      </div>

                  </div>

                  </div>

               
                
              </Container>
        </section>
    );
}

export default Solicitoremid;
