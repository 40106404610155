import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

// images
import Cardone from "assets/images/card-one.jpg";

const DetailsSolicitor = () => {
  const history = useHistory();
  return (
    <section className="DetailsSolicitor-mid">
      <Container>
        <div className="mid-code">
          <div className="solicitor-details">
            <div className="back-btn">
              <a href="#">
                <svg
                  aria-hidden="true"
                  role="img"
                  className="iconify iconify--material-symbols"
                  width="18"
                  height="18"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m12 20l-8-8l8-8l1.425 1.4l-5.6 5.6H20v2H7.825l5.6 5.6Z"
                  />
                </svg>
                Back
              </a>
            </div>

            <div className="mt-4 details-solicotr-box">
              <h5 className="text-center">Details of Solicitor</h5>
              <Form className="vedor-form">
                <Row>
                  <Col lg={2} md={4} sm={12}>
                    <FormGroup>
                      <Label for="exampleSelect">Title</Label>
                      <Input type="select" name="select" id="exampleSelect">
                        <option>Mr.</option>
                        <option>Mrs.</option>
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col lg={10} md={8} sm={12}>
                    <FormGroup>
                      <Label for="exampleEmail">Full Name</Label>
                      <Input
                        type="text"
                        name="text"
                        id="name"
                        placeholder="Marry Jackson"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={5} md={4} sm={12}>
                    <FormGroup>
                      <Label for="exampleSelect">Phone number</Label>
                      <Input
                        type="tel"
                        name="number"
                        id="number"
                        placeholder="7888888959"
                      ></Input>
                    </FormGroup>
                  </Col>

                  <Col lg={7} md={8} sm={12}>
                    <FormGroup>
                      <Label for="exampleEmail">Email ID</Label>
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="Jimmyni.01@gmail.com"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={12}>
                    <FormGroup>
                      <Label for="exampleEmail">Address</Label>
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="Balistreri, Center North, Eloise, Avon"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              <Button className="sbmit-btn" onClick={()=> history.push("/memorandum")}>Submit</Button>

              </Form>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DetailsSolicitor;
