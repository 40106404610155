import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup, CloseButton } from "react-bootstrap";
import facebook from "../../assets/svg/facebook-f.svg";
import gamail from "../../assets/images/gmail.png";
import linkedIn from "assets/images/linkedinn.png";
import { validate } from "backend_helper/validations";
import { signUp, sendOtp } from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import LoginModal from "./Login";
import toast from "react-hot-toast";
import close_icon from "assets/svg/close_icon.svg";

export default function Signup({
  showsignup,
  handleClose2,
  handleShow2,
  handleShow,
  handleOpen4,
  setcurrentId,
  onSignupClose,
}) {
  const [state, setState] = useState({
    tab: "tab1",
  });
  const dispatch = useDispatch();
  const [fields, setfields] = useState({
    email: "",
    password: "",
    name: "",
    userType: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const changeHalder = (e) => {
    const { name, value } = e.target;
    setError((prev) =>
      value !== "" || undefined || null
        ? { ...prev, [name + "Error"]: false, [name]: value }
        : { ...prev, [name + "Error"]: true, [name]: value }
    );
    setfields((prev) => ({ ...prev, [name]: value }));
  };

  const { email, password, name } = fields;
  //submit
  const submit = (e) => {
    e.preventDefault();
    const res = validate(fields);
    setError(res);
    console.log(res);
    let data = {
      email: email,
      password: password,
      deviceType: "web",
      name: name,
      loginType: "mobile",
      userType: state.tab == "tab1" ? "seller" : "buyer",
    };
    const callback = (res) => {
      console.log(res, 7777777);
      const { isSuccess, error, data, message } = res.data;
      if (isSuccess) {
        // toast.success(message)
        handleOpen4(data?.id);
        setcurrentId(data?.id);
        onSignupClose(data?.id);
        dispatch(
          sendOtp(
            {
              userId: data?.id,
              type: "email",
            },
            (res) => toast.success("Otp Sent successfully")
          )
        );
        handleClose2();
        // handleShow()
      } else {
        toast.error(error);
      }
    };
    dispatch(signUp(data, callback));
  };

  useEffect(() => {}, []);
  return (
    <>
      <Modal
        show={showsignup}
        onHide={handleClose2}
        backdrop="static"
        centered={true}
        keyboard={false}
        className="login_modal login_modal2"
      >
        <Modal.Header>
          <Modal.Title>Sign up</Modal.Title>
          <p className="m-0 close_btn " onClick={handleClose2}>
            {/* <CloseButton
              variant="white"
              disabled={false}
              style={{ width: "15px", height: "15px", color: "#fff" }}
            /> */}
            <img src={close_icon} style={{ width: "24px" }} />
          </p>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="tab-frame sign-setting">
            <div className="clearfix sign-clear">
              <input
                type="radio"
                name="tab"
                id="tab1"
                checked={state.tab == "tab1"}
                onClick={() => setState({ tab: "tab1" })}
              />
              <label for="tab1">Seller</label>

              <input
                type="radio"
                name="tab"
                id="tab2"
                checked={state.tab == "tab2"}
                onClick={() => setState({ tab: "tab2" })}
              />
              <label for="tab2">Buyer</label>
              {/* <input
                type="radio"
                name="tab"
                id="tab3"
                checked={state.tab == "tab3"}
                onClick={() => setState({ tab: "tab3" })}
              />
              <label for="tab3">Service Provider</label> */}
            </div>
          </div>

          {state.tab && (
            <div className="sign-online">
              <Form className="sign-input" onSubmit={submit}>
                <div className="my-2">
                  <Form.Label className="input_label">Name</Form.Label>
                  <InputGroup className="position-relative">
                    <Form.Control
                      type="text"
                      className="inputField form-control"
                      placeholder="Name"
                      style={{
                        borderRadius: 8,
                        background: "#F4F4F4 0% 0% no-repeat padding-box",
                      }}
                      isInvalid={error?.nameError}
                      required={error?.nameError}
                      name="name"
                      value={name}
                      onChange={changeHalder}
                    />
                    <div className="email-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.053"
                        height="16.316"
                        viewBox="0 0 13.053 16.316"
                      >
                        <path
                          id="Path_3774"
                          data-name="Path 3774"
                          d="M782.845,339.316a4.3,4.3,0,1,1-1.26-3.057,4.3,4.3,0,0,1,1.26,3.057Zm-4.319,12c-3.539,0-6.526-.571-6.526-2.794s3.006-2.774,6.526-2.774c3.54,0,6.526.571,6.526,2.794S782.047,351.316,778.526,351.316Z"
                          transform="translate(-772 -334.999)"
                          fill="#636363"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </InputGroup>
                </div>
                <div className="my-2">
                  <Form.Label className="input_label">Email</Form.Label>
                  <InputGroup className="position-relative">
                    <Form.Control
                      type="email"
                      className="inputField form-control"
                      placeholder="Email"
                      style={{
                        borderRadius: 8,
                        background: "#F4F4F4 0% 0% no-repeat padding-box",
                      }}
                      isInvalid={error?.emailError}
                      required={error?.emailError}
                      name="email"
                      value={email}
                      onChange={changeHalder}
                    />
                    <div className="email-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.365"
                        height="15.629"
                        viewBox="0 0 17.365 15.629"
                      >
                        <path
                          id="Path_3778"
                          data-name="Path 3778"
                          d="M278.971,280a4.39,4.39,0,0,1,4.394,4.385v6.859a4.392,4.392,0,0,1-4.394,4.385h-8.578A4.391,4.391,0,0,1,266,291.244v-6.859A4.385,4.385,0,0,1,270.393,280Zm1.381,5.678.069-.069a.671.671,0,0,0-.01-.868.73.73,0,0,0-.459-.226.66.66,0,0,0-.487.174l-3.915,3.126a1.36,1.36,0,0,1-1.737,0l-3.907-3.126a.664.664,0,0,0-.93.929l.114.113,3.951,3.082a2.734,2.734,0,0,0,3.394,0Z"
                          transform="translate(-266 -280)"
                          fill="#636363"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                  </InputGroup>
                </div>
                <div className="my-2">
                  <Form.Label className="input_label">Password</Form.Label>
                  <InputGroup className="position-relative newPass">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      className="inputField form-control tessst"
                      placeholder="Password"
                      // style={{ background: '#ebebeb' }}
                      style={{
                        borderRadius: 8,
                        background: "#F4F4F4 0% 0% no-repeat padding-box",
                      }}
                      isInvalid={error?.passwordError}
                      required={error?.passwordError}
                      name="password"
                      value={password}
                      onChange={changeHalder}
                    />
                    <div className="email-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.732"
                        height="18.623"
                        viewBox="0 0 15.732 18.623"
                      >
                        <path
                          id="Path_3755"
                          data-name="Path 3755"
                          d="M1120.474,395.988v1.419a3.855,3.855,0,0,1,2.758,3.664v4.569a3.907,3.907,0,0,1-3.951,3.863h-7.829a3.907,3.907,0,0,1-3.951-3.863V401.07a3.866,3.866,0,0,1,2.758-3.664v-1.419a5.109,5.109,0,1,1,10.218,0Zm-5.1-3.384a3.424,3.424,0,0,1,3.452,3.384v1.22H1111.9v-1.238a3.424,3.424,0,0,1,3.47-3.369Zm.814,11.767a.824.824,0,0,1-1.647,0V402.33a.824.824,0,0,1,1.647,0Z"
                          transform="translate(-1107.5 -390.879)"
                          fill="#636363"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>

                    <a href="#">
                      <div className="eye-svg">
                        <svg
                          onClick={() => setshowPassword(!showPassword)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.553"
                          height="12.443"
                          viewBox="0 0 15.553 12.443"
                        >
                          <path
                            id="Path_3732"
                            data-name="Path 3732"
                            d="M550.744,399.222a3.023,3.023,0,1,0,.891-2.137A3.023,3.023,0,0,0,550.744,399.222Zm7.5-4.63a11.02,11.02,0,0,1,3.266,4.4.559.559,0,0,1,0,.445c-1.622,3.756-4.512,6-7.73,6h-.008c-3.21,0-6.1-2.248-7.723-6a.559.559,0,0,1,0-.445c1.623-3.756,4.511-6,7.723-6h.008a7.21,7.21,0,0,1,4.462,1.591Zm-4.463,6.506a1.881,1.881,0,0,0,0-3.763,1.466,1.466,0,0,0-.265.023,1.555,1.555,0,0,1-1.555,1.493h-.038a1.981,1.981,0,0,0-.038.369A1.891,1.891,0,0,0,553.777,401.1Z"
                            transform="translate(-546 -393)"
                            fill="#3a3b52"
                            fillRule="evenodd"
                            opacity="0.8"
                          />
                        </svg>
                      </div>
                    </a>
                  </InputGroup>
                </div>

                <button className="logIn_btn mt-5 w-100 log22">Sign Up</button>
              </Form>
            </div>
          )}

          {/* {state.tab === "tab2" && <div className="sign-online">
            <Form className="sign-input">
              <div className="my-2">
                <Form.Label className="input_label">Email</Form.Label>
                <InputGroup className="position-relative">
                  <Form.Control
                    type="text"
                    className="inputField form-control"
                    placeholder="Search..."
                    autoCorrect={false}
                  // onChange={this.handleChange.bind(this)}
                  />
                  <div className="email-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.365"
                      height="15.629"
                      viewBox="0 0 17.365 15.629"
                    >
                      <path
                        id="Path_3778"
                        data-name="Path 3778"
                        d="M278.971,280a4.39,4.39,0,0,1,4.394,4.385v6.859a4.392,4.392,0,0,1-4.394,4.385h-8.578A4.391,4.391,0,0,1,266,291.244v-6.859A4.385,4.385,0,0,1,270.393,280Zm1.381,5.678.069-.069a.671.671,0,0,0-.01-.868.73.73,0,0,0-.459-.226.66.66,0,0,0-.487.174l-3.915,3.126a1.36,1.36,0,0,1-1.737,0l-3.907-3.126a.664.664,0,0,0-.93.929l.114.113,3.951,3.082a2.734,2.734,0,0,0,3.394,0Z"
                        transform="translate(-266 -280)"
                        fill="#636363"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </InputGroup>
              </div>
              <div className="my-2">
                <Form.Label className="input_label">Password</Form.Label>
                <InputGroup className="position-relative">
                  <Form.Control
                    type="password"
                    className="inputField form-control"
                    placeholder="Search..."
                  // onChange={this.handleChange.bind(this)}
                  />
                  <div className="email-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.732"
                      height="18.623"
                      viewBox="0 0 15.732 18.623"
                    >
                      <path
                        id="Path_3755"
                        data-name="Path 3755"
                        d="M1120.474,395.988v1.419a3.855,3.855,0,0,1,2.758,3.664v4.569a3.907,3.907,0,0,1-3.951,3.863h-7.829a3.907,3.907,0,0,1-3.951-3.863V401.07a3.866,3.866,0,0,1,2.758-3.664v-1.419a5.109,5.109,0,1,1,10.218,0Zm-5.1-3.384a3.424,3.424,0,0,1,3.452,3.384v1.22H1111.9v-1.238a3.424,3.424,0,0,1,3.47-3.369Zm.814,11.767a.824.824,0,0,1-1.647,0V402.33a.824.824,0,0,1,1.647,0Z"
                        transform="translate(-1107.5 -390.879)"
                        fill="#636363"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>

                  <a href="">
                    <div className="eye-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.553"
                        height="12.443"
                        viewBox="0 0 15.553 12.443"
                      >
                        <path
                          id="Path_3732"
                          data-name="Path 3732"
                          d="M550.744,399.222a3.023,3.023,0,1,0,.891-2.137A3.023,3.023,0,0,0,550.744,399.222Zm7.5-4.63a11.02,11.02,0,0,1,3.266,4.4.559.559,0,0,1,0,.445c-1.622,3.756-4.512,6-7.73,6h-.008c-3.21,0-6.1-2.248-7.723-6a.559.559,0,0,1,0-.445c1.623-3.756,4.511-6,7.723-6h.008a7.21,7.21,0,0,1,4.462,1.591Zm-4.463,6.506a1.881,1.881,0,0,0,0-3.763,1.466,1.466,0,0,0-.265.023,1.555,1.555,0,0,1-1.555,1.493h-.038a1.981,1.981,0,0,0-.038.369A1.891,1.891,0,0,0,553.777,401.1Z"
                          transform="translate(-546 -393)"
                          fill="#3a3b52"
                          fillRule="evenodd"
                          opacity="0.8"
                        />
                      </svg>
                    </div>
                  </a>
                </InputGroup>
              </div>

              <button className="logIn_btn mt-5">Sign Up</button>
            </Form>
          </div>} */}
          <div className="or_login_content">
            <small className="p-0 text-center d-block text-muted my-3">
              Or Login with
            </small>
            <div className="social_btn_wrapper">
              <button className="social_btn">
                <img src={facebook} width="10px" />{" "}
              </button>
              <button className="social_btn" style={{ background: "#fff" }}>
                <img src={gamail} width="20px" />{" "}
              </button>
              <button className="social_btn linked-in">
                <img src={linkedIn} width="10px" />{" "}
              </button>
            </div>
            <small className="p-0 text-center d-block">
              Already have an account?{" "}
              <b>
                <a
                  href="#"
                  className="crete-account"
                  onClick={() => handleClose2()(handleShow())}
                >
                  Login
                </a>
              </b>
            </small>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
