import React from "react";
import { Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

// images
import Cardone from "assets/images/card-one.jpg";

const EPCsellermid = () => {
  const history = useHistory();
  return (
    <section className="Floorplanmid-main">
      <Container>
        <div className="wrap-box wrap-boxepcselller">
          <div
            className="go-back-div epcsellerback"
            onClick={() => history.goBack()}
          >
            <p>
              <svg
                aria-hidden="true"
                role="img"
                className="iconify iconify--ic"
                width="22"
                height="22"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"
                ></path>
              </svg>
              Back
            </p>
            <Button className="lockright">
              <svg
                role="img"
                className="iconify iconify--bx"
                width="20"
                height="20"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  d="M5 22h14a2 2 0 0 0 2-2V9a1 1 0 0 0-1-1h-3v-.777c0-2.609-1.903-4.945-4.5-5.198A5.005 5.005 0 0 0 7 7v1H4a1 1 0 0 0-1 1v11a2 2 0 0 0 2 2zm12-12v2h-2v-2h2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-2 3h2v2H7v-2z"
                  fill="#fff"
                />
              </svg>
            </Button>
          </div>
          <div className="floorplan-box epcsellermain epcseller_div">
            <h5 className="text-center">Energy Performance Certificate</h5>
            <div className="floor-upload">
              <div className="floor-img-upload position-relative">
                <input type="file" className="custom-file-input" />
                <div className="imges-iocn">
                  <svg
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--bi"
                    width="32"
                    height="32"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <g fill="#C0C0C0">
                      <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z" />
                      <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71l-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                    </g>
                  </svg>
                </div>
              </div>
              <p>OR</p>
              <div className="floor-img-upload floor-img-uploadwo position-relative">
                <div className="epc-main-right">
                  <div className="epc-service-main">
                    <div className="epc-service">
                      <h5>EPC Service</h5>
                      <div className="service-rating">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--material-symbols"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#EFB514"
                            d="M8.3 20.125q-.575.45-1.175.025q-.6-.425-.4-1.125L8.15 14.4l-3.625-2.575q-.6-.425-.362-1.125q.237-.7.962-.7H9.6l1.45-4.8q.125-.35.388-.538q.262-.187.562-.187q.3 0 .562.187q.263.188.388.538L14.4 10h4.475q.725 0 .963.7q.237.7-.363 1.125L15.85 14.4l1.425 4.625q.2.7-.4 1.125q-.6.425-1.175-.025L12 17.3Z"
                          />
                        </svg>

                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--material-symbols"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#EFB514"
                            d="M8.3 20.125q-.575.45-1.175.025q-.6-.425-.4-1.125L8.15 14.4l-3.625-2.575q-.6-.425-.362-1.125q.237-.7.962-.7H9.6l1.45-4.8q.125-.35.388-.538q.262-.187.562-.187q.3 0 .562.187q.263.188.388.538L14.4 10h4.475q.725 0 .963.7q.237.7-.363 1.125L15.85 14.4l1.425 4.625q.2.7-.4 1.125q-.6.425-1.175-.025L12 17.3Z"
                          />
                        </svg>

                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--material-symbols"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#EFB514"
                            d="M8.3 20.125q-.575.45-1.175.025q-.6-.425-.4-1.125L8.15 14.4l-3.625-2.575q-.6-.425-.362-1.125q.237-.7.962-.7H9.6l1.45-4.8q.125-.35.388-.538q.262-.187.562-.187q.3 0 .562.187q.263.188.388.538L14.4 10h4.475q.725 0 .963.7q.237.7-.363 1.125L15.85 14.4l1.425 4.625q.2.7-.4 1.125q-.6.425-1.175-.025L12 17.3Z"
                          />
                        </svg>

                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--material-symbols"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#EFB514"
                            d="M8.3 20.125q-.575.45-1.175.025q-.6-.425-.4-1.125L8.15 14.4l-3.625-2.575q-.6-.425-.362-1.125q.237-.7.962-.7H9.6l1.45-4.8q.125-.35.388-.538q.262-.187.562-.187q.3 0 .562.187q.263.188.388.538L14.4 10h4.475q.725 0 .963.7q.237.7-.363 1.125L15.85 14.4l1.425 4.625q.2.7-.4 1.125q-.6.425-1.175-.025L12 17.3Z"
                          />
                        </svg>
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--material-symbols"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#EFB514"
                            d="M8.3 20.125q-.575.45-1.175.025q-.6-.425-.4-1.125L8.15 14.4l-3.625-2.575q-.6-.425-.362-1.125q.237-.7.962-.7H9.6l1.45-4.8q.125-.35.388-.538q.262-.187.562-.187q.3 0 .562.187q.263.188.388.538L14.4 10h4.475q.725 0 .963.7q.237.7-.363 1.125L15.85 14.4l1.425 4.625q.2.7-.4 1.125q-.6.425-1.175-.025L12 17.3Z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="epc-service">
                      <h4>£55</h4>
                    </div>
                  </div>
                  <div className="book-now-wrap">
                    <Button className="Book-Now">Book Now</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default EPCsellermid;
