import React, { useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

// images
import Mastrocard from "assets/images/mastro.png";
import Paypal from "assets/images/paypal.png";
import Googlepay from "assets/images/googlepay.png";
import Applepay from "assets/images/applepay.png";

import Modalpayment from "pages/Paymentmethod/Modal";

const Payment = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Modalpayment
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
      />
      <Header />
      <section className="payment-method">
        <Container>
          <div className="payment-wrap-box">
            <h2 className="heading-select">Select payment Method</h2>
            <div className="payment-box">
              <Form className="payment-form">
                <Row>
                  <Col lg={12}>
                    <FormGroup className="position-relative">
                      <Label for="exampleEmail">Card Number</Label>
                      <span className="small-info">
                        Enter the 16-digit card number on the card
                      </span>
                      <img src={Mastrocard} className="img-fluid my-mastro" />
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="4796 - 4796 - 4796 - 4796"
                      />
                      <div className="verifiedsccol">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--material-symbols"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#01A1F1"
                            d="m8.6 22.5l-1.9-3.2l-3.6-.8l.35-3.7L1 12l2.45-2.8l-.35-3.7l3.6-.8l1.9-3.2L12 2.95l3.4-1.45l1.9 3.2l3.6.8l-.35 3.7L23 12l-2.45 2.8l.35 3.7l-3.6.8l-1.9 3.2l-3.4-1.45Zm2.35-6.95L16.6 9.9l-1.4-1.45l-4.25 4.25l-2.15-2.1L7.4 12Z"
                          />
                        </svg>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={5}>
                    <Label for="exampleEmail">CVV Number</Label>
                    <span className="small-info">
                      Enter the 3 digit number on the card
                    </span>
                  </Col>
                  <Col lg={7}>
                    <div className="cvv-number position-relative">
                      <Input
                        type="number"
                        name="number"
                        id="exampleEmail"
                        placeholder="356"
                      />
                      <div className="dots-icon">
                        <svg
                          aria-hidden="true"
                          role="img"
                          className="iconify iconify--mdi"
                          width="20"
                          height="20"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#A5ABBB"
                            d="M12 16c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2M6 16c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m12 12c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2m0-6c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={5}>
                    <Label for="exampleEmail">Expiry Date</Label>
                    <span className="small-info">
                      Enter the expiration date of the card
                    </span>
                  </Col>
                  <Col lg={7}>
                    <div className="date-expiry position-relative">
                      <div className="input-one">
                        <Input
                          type="number"
                          name="number"
                          id="exampleEmail"
                          placeholder="05"
                        />
                      </div>
                      <p>/</p>
                      <div className="input-one">
                        <Input
                          type="number"
                          name="number"
                          id="exampleEmail"
                          placeholder="2020"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="other-way-pay">
                  <Label for="">Other Ways to Pay</Label>

                  {/* <div className="lebelyeno-col other-way mb-3">
                      <label className="lebeyesno lebel-yes" for="foryes">
                        <input checked type="radio" name="plan" id="foryes" />
                        <div className="plan-yesno">
                          <span>Freehold</span>
                        </div>
                      </label>

                      <label className="lebeyesno lebel-no" for="forno">
                        <input checked type="radio" id="forno" name="plan" />
                        <div className="plan-yesno">
                          <span>Leasehold</span>
                        </div>
                      </label>

                      <label className="lebeyesno lebel-no3" for="forno3">
                        <input checked type="radio" id="forno3" name="plan" />
                        <div className="plan-yesno">
                          <span>Leasehold</span>
                        </div>
                      </label>
                    </div> */}

                  <div className="other-wway-acnhor">
                    <Row>
                      <Col lg={6}>
                        <a href="#">
                          <img
                            src={Paypal}
                            alt=""
                            className="img-fluid common-width"
                          />
                          Paypal
                        </a>
                      </Col>
                      <Col lg={6}>
                        <a href="#">
                          <img
                            src={Googlepay}
                            alt=""
                            className="img-fluid common-width"
                          />
                          Google Pay
                        </a>
                      </Col>
                      <Col lg={6}>
                        <a href="#">
                          <img
                            src={Applepay}
                            alt=""
                            className="img-fluid common-width"
                          />
                          Apple Pay
                        </a>
                      </Col>
                    </Row>

                    <div className="floor-plan-btns mt-4">
                      <Button className="back-btn"> Back</Button>
                      <Button className="Continue-btn" onClick={handleShow}>
                        {" "}
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Payment;
