export const createPropertySelector = {
  state: (state) => state.CreateProperty, 
  formData: (state) => state.CreateProperty.formData,
  stepOne: ({
    CreateProperty: {
      formData: { address, postalCode, addressCoordinates },
    },
  }) => ({
    address,
    postalCode,
    addressCoordinates,
  }),
  stepTwo: ({
    CreateProperty: {
      formData: { propertyType },
    },
  }) => ({
    propertyType,
  }),
  step1: ({ CreateProperty: { formData } }) => ({
    propertyType: formData.propertyType,
    residentialType: formData.residentialType,
    residentialStyle: formData.residentialStyle,
    residentialAge: formData.residentialAge,
    residentialTenure: formData.residentialTenure,
    residentialBedroomCount: formData.residentialBedroomCount,
    residentialBathroomCount: formData.residentialBathroomCount,
    residentialReceptionRooms: formData.residentialReceptionRooms,
    residentialCentralHeating: formData.residentialCentralHeating,
    residentialGarden: formData.residentialGarden,
    residentialAdditionalFeatures: formData.residentialAdditionalFeatures,
    residentialAdditonalFeatureText: formData.residentialAdditonalFeatureText,
    residentialBroadbandConnection: formData.residentialBroadbandConnection,
    residentialBroadbandSpeed: formData.residentialBroadbandSpeed,
    residentialEnergyType: formData.residentialEnergyType,
    residentialEnergySpecifyText: formData.residentialEnergySpecifyText,
    commercialType: formData.commercialType,
    commercialTenure: formData.commercialTenure,
    commercialCurrentBusinessClassification:
      formData.commercialCurrentBusinessClassification,
    commercialSize: formData.commercialSize,
    commercialSizeUnit: formData.commercialSizeUnit,
    commercialPropertySizeInSQM: formData.commercialPropertySizeInSQM,
    commercialCarParking: formData.commercialCarParking,
    commercialCarParkingSize: formData.commercialCarParkingSize,
    landTenure: formData.landTenure,
    landSizeInAcres: formData.landSizeInAcres,
    landSizeAccuracy: formData.landSizeAccuracy,
    landPermissionForDevelopment: formData.landPermissionForDevelopment,
    landPermissionNumber: formData.landPermissionNumber,
    landPermissionImage: formData.landPermissionImage,
  }),
  stepThree: ({
    CreateProperty: {
      formData: { offerPrice, offerCondition },
    },
  }) => ({
    offerCondition,
    offerPrice,
  }),
  stepHour: ({
    CreateProperty: {
      formData: { fullVideoUrl, propertyImages },
    },
  }) => ({
    fullVideoUrl,
    propertyImages,
  }),
  loading: (state) => state.CreateProperty.loading,
};
