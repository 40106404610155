import { combineReducers } from "redux";
import SignUp from "./signup/reducer";
import CreateProperty from "./create-property/reducer";

// right now we have only 1 reducer, but lets use this format of combineReducers so you can add more later if you need to.
const rootReducer = combineReducers({
  SignUp,
  CreateProperty
});

export default rootReducer;
