import React from "react";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import ScrollTop from "components/scrollTop";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { createPropertySelector } from "store/selectors";
import { createPropertySaveForm } from "store/actions";

const offerConditionOpts = [
  "Fixed Price",
  "offers in the region of",
  "Offers over",
  "Offers invited",
];

export default function Stepthree() {
  const history = useHistory();
  const prevData = useSelector(createPropertySelector.stepThree);
  const dispatch = useDispatch();
  const [fields, setFields] = useState(prevData);

  const { offerCondition, offerPrice } = fields;

  const onFieldChange = (e) => {
    let d = e.target;
    setFields((prev) => ({
      ...prev,
      [d.name]: d.value,
    }));
  };

  const onSubmit = () => {
    if (!offerCondition || !offerPrice) {
      alert("All fields are required!");
      return;
    }
    dispatch(createPropertySaveForm({ ...prevData, ...fields }));
    history.push("/step4");
  };

  return (
    <>
      <ScrollTop />
      <Header />
      <section className="common-steps-main ">
        <Container>
          <Row>
            <div className="list-main stepthree">
              <div className="list-heading">
                <h2>Property Value</h2>
                <a href="#">Watch Help Videos</a>
              </div>
              <div className="list-main-under">
                {/* <Stepper/> */}

                <div className="custom-steeper">
                  <div className="common-step step1 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                      Property <br /> information
                    </span>
                  </div>

                  <div className="common-step step2 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>Property Type</span>
                  </div>

                  <div className="common-step step3 position-relative">
                    <div className="stepbox process">03</div>
                    <span>Value</span>
                  </div>

                  <div className="common-step step4 position-relative">
                    <div className="stepbox uundeprocss">04</div>
                    <span>
                      Images and <br /> Video
                    </span>
                  </div>

                  <div className="common-step step5 position-relative">
                    <div className="stepbox uundeprocss">05</div>
                    <span>Submit</span>
                  </div>
                </div>
                <div className="new_class">
                  <div className="wraping-col">
                    <div className="form-heading">
                      {/* <h3>Property Value</h3> */}
                      <Button
                        className="adaa ml-auto c-grey"
                        onClick={() => history.push("/areaprice")}
                      >
                        {" "}
                        See sold house prices in this area
                      </Button>
                    </div>

                    <div className="suggestion-value text-center">
                      <h6>Suggested Range</h6>
                      <h4>£10,000 - £15,000</h4>
                    </div>

                    <Form className="confirmation-form ero-col">
                      <Col lg={12} md={12} sm={12}>
                        <FormGroup className="position-relative">
                          <div className="reals-me-first">
                            <Label for="exampleEmail">Your Price</Label>
                            <a href="#" className="read-me">
                              Read me first
                            </a>
                          </div>
                          <Input
                            type="number"
                            min={0}
                            value={offerPrice}
                            onChange={onFieldChange}
                            name="offerPrice"
                            id="exampleEmail"
                            placeholder=""
                          />
                          <div className="eru-iocn">
                            <p>£</p>
                          </div>
                          <a href="# " className="read-me mt-2">
                            RICS Surveyor valuation
                          </a>
                        </FormGroup>
                      </Col>

                      <div className="offer-coditions">
                        <div className="newselect mb-3">
                          {offerConditionOpts.map((v, i) => (
                            <label
                              key={i}
                              className="newselectyes lebel-yes"
                              htmlFor={"foryesnN" + i}
                            >
                              <input
                                checked={v === offerCondition}
                                type="radio"
                                name="offerCondition"
                                onChange={onFieldChange}
                                value={v}
                                id={"foryesnN" + i}
                              />
                              <div className="plan-yesno">
                                <span>{v}</span>
                              </div>
                            </label>
                          ))}
                        </div>
                      </div>

                      <div className="floor-plan-btns steps-btn mt-5">
                        <Button
                          className="back-btn"
                          onClick={() => history.push("/step2")}
                        >
                          {" "}
                          Back
                        </Button>
                        <Button className="Continue-btn" onClick={onSubmit}>
                          {" "}
                          Continue
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
