import * as CONST from "./actionType";

const formInit = {
  address: "",
  postalCode: "",
  ownershipStatus: "",
  propertyType: "",
  residentialType: "House",
  residentialStyle: "Detached",
  residentialAge: "Brand New",
  residentialTenure: "Freehold",
  residentialBedroomCount: 3,
  residentialBathroomCount: 3,
  residentialReceptionRooms: 3,
  residentialCentralHeating: "Full Cental Heating",
  residentialGarden: "Private Garden",
  residentialAdditionalFeatures: "Conservatory",
  residentialBroadbandConnection: "yes",
  residentialEnergyType: "Gas",
  residentialEnergySpecifyText: "",
  residentialBroadbandSpeed: "",
  residentialAdditonalFeatureText: "",
  commercialType: "",
  commercialTenure: "",
  commercialCurrentBusinessClassification: "A1 - Shops",
  commercialSize: "",
  commercialSizeUnit: "Sqft",
  commercialPropertySizeInSQM: "",
  commercialCarParking: "",
  commercialCarParkingSize: "",
  landTenure: "",
  landSizeInAcres: "",
  landSizeAccuracy: "",
  landPermissionForDevelopment: "",
  landPermissionNumber: "",
  landPermissionImage: "",
  minSuggestedRange: "",
  maxSuggestedRange: "",
  offerPrice: "",
  offerCondition: "",
  fullVideoUrl: "",
  propertyImages: [
    {
      isCoverPhoto: true,
      position: 0,
      mediaType: "image",
    },
    {
      isCoverPhoto: false,
      position: 1,
      mediaType: "image",
    },
    {
      isCoverPhoto: false,
      position: 2,
      mediaType: "image",
    },
    {
      isCoverPhoto: false,
      position: 3,
      mediaType: "image",
    },
    {
      isCoverPhoto: false,
      position: 4,
      mediaType: "image",
    },
    {
      isCoverPhoto: false,
      position: 0,
      mediaType: "video",
    },
    {
      isCoverPhoto: false,
      position: 1,
      mediaType: "video",
    },
    {
      isCoverPhoto: false,
      position: 2,
      mediaType: "video",
    },
    {
      isCoverPhoto: false,
      position: 3,
      mediaType: "video",
    },
    {
      isCoverPhoto: false,
      position: 4,
      mediaType: "video",
    },
  ],
  addressCoordinates: [],
};

let initialState = {
  formData: { ...formInit },
  loading: false,
};

const CreateProperty = (state = initialState, action) => {
  switch (action.type) {
    case CONST.CREATE_PROPERTY:
      return {
        ...state,
        loading: true,
      };
    case CONST.CREATE_PROPERTY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CONST.CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        formData: { ...formInit },
        loading: false,
      };
    case CONST.CREATE_PROPERTY_SAVE_FORM:
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
      };
    case CONST.API_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
export default CreateProperty;
