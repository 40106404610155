import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "./Sidebar";
import Dashboard from "./Seller/Dashboard";
import { Switch, Route, } from "react-router";
import { Container } from "react-bootstrap";


export default function Profile() {

  return (
    <>
      <Header />
        <Container>
      <div className="seller_container">
        <Sidebar />
        <Dashboard />
      </div>
      </Container>
      <Footer />
    </>
  );
}
