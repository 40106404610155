import React from "react";
import Header from "components/Header";
import Logo from "assets/images/logo2.png";
import Footer from "components/Footer";
import Sidebar from "../Sidebar";

import { Row, Col, Button, Container } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

// images
import Mastrocard from "assets/images/mastro.png";
import Paypal from "assets/images/paypal.png";
import Googlepay from "assets/images/googlepay.png";
import Applepay from "assets/images/applepay.png";

export default function Addnewcard() {
  return (
    <>
      <Header />
      <Container>
      <div className="seller_container">
        <Sidebar />
        <div className="profile_right_side">
          <div className="d-flex justify-content-between">
            <h4 className="dashboard_text">Payment Methods</h4>
          </div>

          <div className="card-add-here">
            <div className="card-add-btn">
              <h6>Add New Card</h6>
            </div>

            <div className="card-listsaa">
              <ul>
                <li>
                  <div className="card-one">
                    <div className="card-logo">
                      <img src={Mastrocard} alt="" className="mastrocard" />
                    </div>
                    <div className="card-number">
                      <div className="numone">****</div>
                      <div className="numone">****</div>
                      <div className="numone">****</div>
                      <div className="numone">8945</div>
                    </div>
                    <div className="card-dates">
                      <div className="expriydate">
                        <p className="expy">EXPIRY DATE</p>
                        <p>05/2020</p>
                      </div>
                      <div className="expriydate">
                        <p className="expy">CARD HOLDER NAME</p>
                        <p>John Wilamson</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="ad-card-details">
              <h4>Add card details</h4>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Name</Label>
                      <Input
                        type="test"
                        name="Name"
                        id="exampleEmail"
                        placeholder="Jimmy nikk"
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Card number</Label>
                      <Input
                        type="number"
                        name="email"
                        id="exampleEmail"
                        placeholder="4589 1245 3214 8596"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Expiry Date</Label>
                      <Input
                        type="date"
                        name="email"
                        id="exampleEmail"
                        placeholder="06 / 2024"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="CVV-input">
                      <Label for="exampleEmail">CVV</Label>
                      <Input
                        type="number"
                        name="email"
                        id="exampleEmail"
                        placeholder="XXX"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="details-teo-btn mt-5">
                  <Button className="view-more">Back</Button>
                  <Button className="edit-details">Add Card</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      </Container>
     
      <Footer />
    </>
  );
}
