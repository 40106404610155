import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup, CloseButton } from "react-bootstrap";
import facebook from "../../assets/svg/facebook-f.svg";
import gamail from "../../assets/images/gmail.png";
import linkedIn from "assets/images/linkedinn.png";
import { validate } from "backend_helper/validations";
import close_icon from "assets/svg/close_icon.svg";
export default function Forgotpassword({
  showspass,
  handleClose3,
  handleShow3,
  handleShow,
  handleOpen4,
  handleClose4,
}) {
  const [fields, setfields] = useState({
    email: "",
    password: "",
    userType: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const [error, setError] = useState();
  const changeHalder = (e) => {
    const { name, value } = e.target;
    setError((prev) =>
      value !== "" || undefined || null
        ? { ...prev, [name + "Error"]: false, [name]: value }
        : { ...prev, [name + "Error"]: true, [name]: value }
    );
    setfields((prev) => ({ ...prev, [name]: value }));
  };

  //submit
  const submit = (e) => {
    e.preventDefault();
    const res = validate(fields);
    setError(res);
  };

  const { email, password } = fields;
  return (
    <>
      <Modal
        show={showspass}
        onHide={handleClose3}
        backdrop="static"
        centered={true}
        keyboard={false}
        className="login_modal forgotmodal login_modal2"
      >
        <Modal.Header>
          <Modal.Title>Forgot Password</Modal.Title>
          <p className="modal-des">
            Enter the email address. We will send you one time password to reset
            your password
          </p>
          <p
            className="m-0 close_btn"
            onClick={() => handleClose3()(handleShow())}
          >
            {/* <CloseButton
              variant="white"
              disabled={false}
              style={{ width: "15px", height: "15px", color: "#fff" }}
            /> */}
            <img
              src={close_icon}
              style={{ width: "24px" }}
            />
          </p>
        </Modal.Header>
        <Modal.Body>
          <Form className="sign-input" onSubmit={submit}>
            <div className="my-2">
              <Form.Label className="input_label">Email</Form.Label>
              <InputGroup className="position-relative">
                <Form.Control
                  type="email"
                  className="inputField form-control"
                  placeholder="Jimmyni.01@gmail.com"
                  style={{
                    borderRadius: 8,
                  }}
                  autoCorrect={false}
                  isInvalid={error?.emailError}
                  required={error?.emailError || ''}
                  name="email"
                  value={email}
                  onChange={changeHalder}
                />
                <div className="email-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.365"
                    height="15.629"
                    viewBox="0 0 17.365 15.629"
                  >
                    <path
                      id="Path_3778"
                      data-name="Path 3778"
                      d="M278.971,280a4.39,4.39,0,0,1,4.394,4.385v6.859a4.392,4.392,0,0,1-4.394,4.385h-8.578A4.391,4.391,0,0,1,266,291.244v-6.859A4.385,4.385,0,0,1,270.393,280Zm1.381,5.678.069-.069a.671.671,0,0,0-.01-.868.73.73,0,0,0-.459-.226.66.66,0,0,0-.487.174l-3.915,3.126a1.36,1.36,0,0,1-1.737,0l-3.907-3.126a.664.664,0,0,0-.93.929l.114.113,3.951,3.082a2.734,2.734,0,0,0,3.394,0Z"
                      transform="translate(-266 -280)"
                      fill="#636363"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
              </InputGroup>
            </div>

            <div className="btn-group-div mt-5">
              <button
                className="back_btn"
                onClick={() => handleShow3()(handleClose3())}
              >
                Back
              </button>
              <button
                className="logIn_btn log22"
                onClick={() => handleOpen4()(handleClose3())}
              >
                Send OTP
              </button>
            </div>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
