import React from "react";
import ScrollTop from "components/scrollTop";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Button, Form } from "reactstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { createPropertySelector } from "store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { createPropertySaveForm } from "store/actions";
import { uploadMediaRequest } from "utils/helper";
import { useEffect } from "react";
import { useState } from "react";
import { DeleteIcon } from "./DeleteIcon";
import { RenderTag } from "./RenderTag";
import { AddEditTagModal } from "./AddEditTagModal";

export default function Stepfour() {
  const history = useHistory();

  const prevData = useSelector(createPropertySelector.stepHour);
  const dispatch = useDispatch();
  const [propertyImages, setImages] = React.useState(
    prevData.propertyImages || []
  );

  const [modalData, setModalData] = useState(false);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleUploadPlaceholderClick = (index, isCoverPhoto) => {
    setSelectedImageIndex(index);
    isCoverPhoto
      ? document.getElementById("input-file-cover").click()
      : document.getElementById("input-file").click();
  };

  const onSelectFile = (isCoverPhoto) => async (e) => {
    let file = e.target.files[0];

    let img = {
      file: file,
      base64: URL.createObjectURL(file),
      isCoverPhoto: isCoverPhoto,
      position: selectedImageIndex,
      mediaType: "image",
    };

    setImages((_state) => {
      return _state.map((image) => {
        if (
          img.mediaType === image.mediaType &&
          img.position === image.position
        ) {
          return { ...img };
        }
        return image;
      });
    });
  };

  const deleteImage = (idx) => {
    setImages((_state) =>
      _state.map((image) => {
        if (image.mediaType === "image" && image.position === idx) {
          return {
            mediaType: "image",
            position: idx,
            isCoverPhoto: false,
          };
        }
        return image;
      })
    );
  };

  const onSubmit = () => {
    const isValid = !propertyImages.find(
      ({ mediaType, file, tag }) => mediaType === "image" && (!file || !tag)
    );

    if (!isValid) {
      alert("All the tags and images are required!");
      return;
    }

    dispatch(
      createPropertySaveForm({
        ...prevData,
        propertyImages,
      })
    );

    history.push("/step5");
  };

  return (
    <>
      <ScrollTop />
      <Header />
      <section className="common-steps-main">
        <Container>
          <Row>
            <div className="list-main stepthree">
              <div className="list-heading">
                <h2>Property Images</h2>
                <a href="#">Watch Help Videos</a>
              </div>
              <div className="list-main-under">
                {/* <Stepper/> */}

                <div className="custom-steeper">
                  <div className="common-step step1 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>
                      Property <br /> information
                    </span>
                  </div>

                  <div className="common-step step2 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>Property Type</span>
                  </div>

                  <div className="common-step step3 active-line position-relative">
                    <div className="stepbox active-step">
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--ic"
                        width="18"
                        height="18"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                        />
                      </svg>
                    </div>
                    <span>Value</span>
                  </div>

                  <div className="common-step step4  position-relative">
                    <div className="stepbox process">04</div>
                    <span>
                      Images and <br /> Video
                    </span>
                  </div>

                  <div className="common-step step5 position-relative">
                    <div className="stepbox uundeprocss">05</div>
                    <span>
                      Review and <br></br> Submit
                    </span>
                  </div>
                </div>

                <div className="wraping-col">
                  <div className="form-heading-steo4">
                    <div className="left-cont-srep4">
                      {/* <h3>Property Images</h3> */}
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: "20px",
                          color: "#190835",
                        }}
                      >
                        Add 5 Images
                      </p>
                    </div>
                    {/* <div className="right-upload">
                      <div className="file">
                        <label htmlFor="input-file">
                          <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--prime"
                            width="24"
                            height="24"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M18.22 20.75H5.78A2.64 2.64 0 0 1 3.25 18v-3a.75.75 0 0 1 1.5 0v3a1.16 1.16 0 0 0 1 1.25h12.47a1.16 1.16 0 0 0 1-1.25v-3a.75.75 0 0 1 1.5 0v3a2.64 2.64 0 0 1-2.5 2.75ZM16 8.75a.74.74 0 0 1-.53-.22L12 5.06L8.53 8.53a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06a.74.74 0 0 1-.53.22Z"
                            />
                            <path
                              fill="currentColor"
                              d="M12 15.75a.76.76 0 0 1-.75-.75V4a.75.75 0 0 1 1.5 0v11a.76.76 0 0 1-.75.75Z"
                            />
                          </svg>
                          Upload
                        </label>
                        <input
                          id="input-file"
                          type="file"
                          multiple
                          onChange={onSelectFile(false)}
                        />
                      </div>
                    </div> */}
                    <input
                      accept="image/*"
                      id="input-file"
                      type="file"
                      onChange={onSelectFile(false)}
                      style={{ display: "none" }}
                    />
                  </div>

                  <div className="uploaded-images">
                    <Row>
                      <Col lg={6} md={6} sm={12}>
                        <div
                          className="left-cover-img position-relative"
                          style={{ width: "100%", cursor: "pointer" }}
                          onClick={() => {
                            handleUploadPlaceholderClick(0, true);
                          }}
                        >
                          <RenderTag
                            tag={propertyImages[0]?.tag}
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalData({
                                position: 0,
                                tag: propertyImages[0]?.tag,
                              });
                            }}
                          />
                          {propertyImages[0]?.base64 ? (
                            <div
                              className="imgbox"
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={propertyImages[0]?.base64}
                                alt=""
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "8px",
                                  objectFit: "cover",
                                }}
                                className="img-fluid"
                              />
                              <DeleteIcon onClick={() => deleteImage(0)} />
                            </div>
                          ) : (
                            <UploadImagePlaceholder
                              style={{ height: "100%" }}
                            />
                          )}
                          <div className="cover-upload ">
                            <div className="file pointer">
                              <label htmlFor="input-file-cover">
                                Cover Image
                              </label>
                              <input
                                id="input-file-cover"
                                type="file"
                                accept="image/*"
                                onClick={(e) => (e.target.value = null)}
                                onChange={onSelectFile(true)}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        <div className="right-box-img">
                          {propertyImages.map(
                            (i, idx) =>
                              !i.isCoverPhoto &&
                              i.mediaType === "image" &&
                              (i.base64 ? (
                                <div
                                  key={idx}
                                  className="imgbox position-relative overflow-hidden"
                                >
                                  <RenderTag
                                    style={{ fontSize: "11px" }}
                                    tag={i?.tag}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setModalData({
                                        position: idx,
                                        tag: i?.tag,
                                      });
                                    }}
                                  />

                                  <img
                                    src={i.base64}
                                    alt=""
                                    style={{
                                      borderRadius: "8px",
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                    className="img-fluid"
                                  />

                                  {/* <div className="cover-upload justify-content-end"> */}
                                  <DeleteIcon
                                    onClick={() => deleteImage(idx)}
                                  />
                                  {/* </div> */}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    position: "relative",
                                    width: "48%",
                                    cursor: "pointer",
                                  }}
                                  className="imgbox"
                                  onClick={() => {
                                    handleUploadPlaceholderClick(idx, false);
                                  }}
                                >
                                  <RenderTag
                                    style={{ fontSize: "11px" }}
                                    tag={i?.tag}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setModalData({
                                        position: idx,
                                        tag: i?.tag,
                                      });
                                    }}
                                  />
                                  <UploadImagePlaceholder key={idx} />
                                </div>
                              ))
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Form className="confirmation-form ero-col">
                    <div className="floor-plan-btns steps-btn mt-5">
                      <Button
                        className="back-btn"
                        onClick={() => history.push("/step3")}
                      >
                        {" "}
                        Back
                      </Button>
                      <Button className="Continue-btn" onClick={onSubmit}>
                        {" "}
                        Continue
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Footer />

      <AddEditTagModal
        modalData={modalData}
        onClose={() => setModalData(null)}
        onChange={({ position, value }) =>
          setImages((_state) => {
            return _state.map((image) => {
              if (
                image?.position === position &&
                image?.mediaType === "image"
              ) {
                return {
                  ...image,
                  tag: value,
                };
              }
              return image;
            });
          })
        }
      />
    </>
  );
}

/**
 *
 * @param {Object} param0
 * @param {String} param0.className
 * @param {React.CSSProperties} param0.style
 * @returns
 */

const UploadImagePlaceholder = ({ className, style }) => (
  <div className={`imgbox position-relative ${className || ""}`} style={style}>
    <svg
      aria-hidden="true"
      role="img"
      className="iconify iconify--bi"
      width="32"
      height="32"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 16 16"
    >
      <g fill="#cccccc">
        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z" />
        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71l-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
      </g>
    </svg>
  </div>
);

const getBase64 = async (file) =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      reject(error);
    };
  });
