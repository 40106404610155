import React, { useEffect } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Propertydetail from "./Propertydetail";

const Detailpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Propertydetail />
      <Footer />
    </div>
  );
};

export default Detailpage;
