import Header from "../../../components/Header";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import Fade from "react-reveal/Fade";
import managed from "assets/images/Image03-1.png";

function ProviderHome() {
  return (
    <>
      <div className="homer_container">
        <Header />
        <div className="herobanner">
          <Container>
            <div className="hero_container">
              <div className="hero_content">
                <Fade bottom>
                  <h3>
                    Are you a service provider
                    <br />
                    in this property world?
                  </h3>
                  <div className="donwload_app">
                    <div className="col-md-12 col-xl-12 col-sm-12 my-4">
                      <div className="sub-heading">
                        Then you need to join UB SOLD!
                      </div>
                      <div className="col-md-12 col-xl-12 col-sm-12 my-4">
                        <button className="login_btn login_btn2">
                          JOIN US
                        </button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </Container>
        </div>
      </div>
      {/* join form */}
      <section className="roboto">
        <Container>
          <Row className="align-items-center pb-3">
            <Col lg={2}>
              <div className="font-28 c-blue fw-7 roboto">
                Join us <br></br> today!
              </div>
            </Col>
            <Col lg={10}>
              <form>
                <Row className="pt-4">
                  <Col sm={5}>
                    <div className="mb-2 ">
                      <Form.Label className="input_label pt-0">
                        Your Business Name
                      </Form.Label>
                      <InputGroup className="position-relative">
                        <Form.Control
                          type="text"
                          className="inputField form-control shadow-input"
                          placeholder="ABC"
                          style={{
                            borderRadius: 8,
                            background: "#F4F4F4 0% 0% no-repeat padding-box",
                          }}
                          name="email"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col sm={5}>
                    <div className="mb-2 ">
                      <Form.Label className="input_label pt-0">
                        Contact number
                      </Form.Label>
                      <InputGroup className="position-relative">
                        <Form.Control
                          type="text"
                          className="inputField form-control shadow-input"
                          placeholder="eg. 07700 900123"
                          style={{
                            borderRadius: 8,
                            background: "#F4F4F4 0% 0% no-repeat padding-box",
                          }}
                          name="email"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3 align-items-end">
                  <Col sm={5}>
                    <div>
                      <Form.Label className="input_label pt-0">
                        Your Business's Street address
                      </Form.Label>
                      <InputGroup className="position-relative">
                        <Form.Control
                          type="text"
                          className="inputField form-control shadow-input"
                          placeholder="eg. New street"
                          style={{
                            borderRadius: 8,
                            background: "#F4F4F4 0% 0% no-repeat padding-box",
                          }}
                          name="email"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col sm={5}>
                    <div>
                      <Form.Label className="input_label pt-0">
                        Email address
                      </Form.Label>
                      <InputGroup className="position-relative">
                        <Form.Control
                          type="text"
                          className="inputField form-control shadow-input"
                          placeholder="eg. email@gmail.com"
                          style={{
                            borderRadius: 8,
                            background: "#F4F4F4 0% 0% no-repeat padding-box",
                          }}
                          name="email"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col sm={2}>
                    <button className="theme-btn black start-btn">Start</button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
          <Col className="mx-auto" sm={10}>
            <Row className="align-items-center pt-5">
              <Col className="text-center" sm={12} md={5}>
                <img src={managed}></img>
              </Col>
              <Col sm={12} md={7}>
                <div className="font-24 fw-6" style={{ color: "#001978" }}>
                  Everything is Managed
                </div>
                <div className="font-16 pt-2" style={{ color: "#686868" }}>
                  Our buyers and sellers control every aspect of their property
                  move using our platform. This also means they want the best
                  services that can help them support that move, that’s where
                  you come in!
                </div>
              </Col>
            </Row>
            <Row className="align-items-center pt-5">
              <Col sm={12} md={7}>
                <div className="font-24 fw-6" style={{ color: "#001978" }}>
                  Be part of our family
                </div>
                <div className="font-16 pt-2" style={{ color: "#686868" }}>
                  We believe we are stronger together, our partner program
                  enables you to become a valued part of the UB Sold family.
                </div>
              </Col>
              <Col className="text-center" sm={12} md={5}>
                <img src={managed}></img>
              </Col>
            </Row>
          </Col>
        </Container>
      </section>
    </>
  );
}

export default ProviderHome;
