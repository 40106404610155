import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Availabilitymid from "./Availabilitymid";


import ScrollTop from "components/scrollTop";

const Availability = () => {
  return (
    <div>
      <ScrollTop />
      <Header />
      <Availabilitymid/>
      <Footer />
    </div>
  );
};

export default Availability;
