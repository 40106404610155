import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import Image03 from "assets/images/Image03-1.png";
import { Link } from "react-router-dom";
import Propertyone from "assets/images/main.jpg";
import User from "assets/images/m_userimg.png";
import { Button } from "react-bootstrap";

const Buydashboard = () => {
  const [state, setState] = useState({
    tab: "tab1",
  });
  return (
    <React.Fragment>
      <div className="profile_right_side">
        {/* <div className="propety-details-box">
              <div className="new-offers">
                    <div className="heading-newoffer">

                      <div className="heading-left-offer">
                      <h4>New Offers</h4>
                         <div className="filter">
                            <p>
                            <svg  aria-hidden="true" role="img" className="iconify iconify--material-symbols" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M14 13v7h-4v-7L2.95 4h18.1Zm-2-.7L16.95 6h-9.9Zm0 0Z"/></svg>
                              Lowest First</p>
                         </div>
                      </div>
                        <div className="right-close">
                        <svg  aria-hidden="true" role="img" className="iconify iconify--ion" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z" fill="currentColor"/></svg>
                        </div>
                    </div>

                  <div className="new-ffwe-list">
                      <ul>


                      {[1,2].map(()=>{
                        return(
                          <li>

                          <p>You received £140,000,00 offer for your property for 3BHK, New Longstone. Below are your listing details and buyer details.</p>
                         <div className="list-box">
                              <div className="left-list-house">
                                   <div className="left-img-house">
                                        <img src={Propertyone} alt="" className="img-fluid"/>
                                   </div>
                                   <div className="left-img-houseconts">
                                       <h6>3 BHK Flat/Apartment</h6>
                                       <h5 className="green-col">£ 150,000,00</h5>
                                   </div>

                              </div>
                              <div className="right-list-house">
                              <div className="right-img-person">
                                 <img src={User} alt="" className="img-fluid"/>
                              </div>
                                   <div className="right-img-personconts">
                                       <p><strong>David Ben</strong></p>
                                       <p>First Time buyer</p>
                                   </div>
                              </div>

                         </div>

                           </li>
                        );
                      })}


                      </ul>
                  </div>
              </div>
        </div> */}

        <div className="listing-barseller">
          <p>Listing</p>
          <Button>+ Post Property</Button>
        </div>

        <div className="tab-frame verify-frame">
          <div className="clearfix dashboard-clear">
            <input
              type="radio"
              name="tab"
              id="tab1"
              checked={state.tab == "tab1"}
              onClick={() => setState({ tab: "tab1" })}
            />
            <label for="tab1">Uploaded Listings</label>

            <input
              type="radio"
              name="tab"
              id="tab2"
              checked={state.tab == "tab2"}
              onClick={() => setState({ tab: "tab2" })}
            />
            <label for="tab2">Sold Listings</label>
          </div>

          {state.tab == "tab1" && (
            <div className="Uploaded-Listings-online mt-4">
              <div className="listing_card">
                {[1, 2].map((d) => {
                  return (
                    <Fade bottom cascade collapse>
                      <div className="listing_texts">
                        <div className="listed-img position-relative">
                          <span className="listed-tag">Listed</span>
                          <img src={Image03} />
                        </div>
                        <div className="listing_right">
                          <div>
                            <p className="m-0 price_text d-flex justify-content-between">
                              $ 150,0000{" "}
                              <span className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#999999"
                                  className="bi bi-three-dots-vertical"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                </svg>{" "}
                              </span>
                            </p>
                            <p className=" bhk_title">3 BHK Flat</p>
                            <p className="listing_subtitle">
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--mdi"
                                width="18"
                                height="18"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#3399FF"
                                  d="m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53L12 21.35Z"
                                />
                              </svg>
                              11 Save
                            </p>
                            <p className="listing_subtitle">
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--ic"
                                width="18"
                                height="18"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#3399FF"
                                  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3z"
                                />
                              </svg>
                              566 views
                            </p>
                            <p className="listing_subtitle">
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--typcn"
                                width="18"
                                height="18"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#3399FF"
                                  d="M17.657 5.304c-3.124-3.073-8.189-3.073-11.313 0a7.78 7.78 0 0 0 0 11.13L12 21.999l5.657-5.565a7.78 7.78 0 0 0 0-11.13zM12 13.499c-.668 0-1.295-.26-1.768-.732a2.503 2.503 0 0 1 0-3.536c.472-.472 1.1-.732 1.768-.732s1.296.26 1.768.732a2.503 2.503 0 0 1 0 3.536c-.472.472-1.1.732-1.768.732z"
                                />
                              </svg>
                              4571 Giraffe Hill Drive,Frisco,Taxes 3031
                            </p>
                          </div>
                          <div className="d-flex gap-3 listing_action_btn">
                            <Link
                              to="/profile/details-page"
                              className="property_btn not_active"
                            >
                              Preview ad
                            </Link>
                            <a href="#" className="property_btn">
                              Edit Details
                            </a>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  );
                })}
              </div>
            </div>
          )}

          {state.tab == "tab2" && (
            <div className="Sold-Listings-online mt-4">
              <div className="listing_card">
                {[1].map((d) => {
                  return (
                    <Fade bottom cascade collapse>
                      <div className="listing_texts">
                        <div className="image-sold-div position-relatve">
                          <span className="sold-badge">
                            <svg
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--ic"
                              width="18"
                              height="18"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ffffff"
                                d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                              />
                            </svg>
                            <p>Sold</p>
                          </span>
                          <img src={Image03} />
                        </div>

                        <div className="listing_right">
                          <div>
                            <p className="m-0 price_text d-flex justify-content-between">
                              $ 150,0000{" "}
                              <span className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#999999"
                                  className="bi bi-three-dots-vertical"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                </svg>{" "}
                              </span>
                            </p>
                            <p className=" bhk_title">3 BHK Flat</p>
                            <p className="listing_subtitle">
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--mdi"
                                width="18"
                                height="18"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#3399FF"
                                  d="m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53L12 21.35Z"
                                />
                              </svg>
                              11 Save
                            </p>
                            <p className="listing_subtitle">
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--ic"
                                width="18"
                                height="18"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#3399FF"
                                  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3z"
                                />
                              </svg>
                              566 views
                            </p>
                            <p className="listing_subtitle">
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--typcn"
                                width="18"
                                height="18"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#3399FF"
                                  d="M17.657 5.304c-3.124-3.073-8.189-3.073-11.313 0a7.78 7.78 0 0 0 0 11.13L12 21.999l5.657-5.565a7.78 7.78 0 0 0 0-11.13zM12 13.499c-.668 0-1.295-.26-1.768-.732a2.503 2.503 0 0 1 0-3.536c.472-.472 1.1-.732 1.768-.732s1.296.26 1.768.732a2.503 2.503 0 0 1 0 3.536c-.472.472-1.1.732-1.768.732z"
                                />
                              </svg>
                              4571 Giraffe Hill Drive,Frisco,Taxes 3031
                            </p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Buydashboard;
