import * as CONST from "./actionType";

export const signUp = (data, callback) => ({
  type: CONST.SIGNUP,
  payload: { data, callback },
});
export const signUpSuccess = (data) => ({
  type: CONST.SIGNUP_SUCCESS,
  payload: data,
});
export const signUpFail = (error) => ({
  type: CONST.SIGNUP_FAIL,
  payload: error,
});

export const logInTdsf = (data, callback) => ({
  type: CONST.LOGIN_USER,
  payload: { data, callback },
});
export const updateToken = (data, callback) => ({
  type: CONST.UPDATE_TOKEN,
  payload: { data, callback },
});
export const loginSuccess = (data) => ({
  type: CONST.LOGIN_SUCCESS,
  payload: data,
});
export const loginFail = (data) => ({
  type: CONST.API_ERROR,
  payload: data,
});

export const sendOtp = (data, callback) => ({
  type: CONST.SEND_OTP,
  payload: { data, callback },
});
export const sendOtpSuccess = (data) => ({
  type: CONST.SEND_OTP_SUCCESS,
  payload: data,
});
export const sendOtpFail = (data) => ({
  type: CONST.SEND_OTP_FAIL,
  payload: data,
});

export const sendOtpVerify = (data, callback) => ({
  type: CONST.SEND_OTP_VERIFY,
  payload: { data, callback },
});
export const sendOtpVerifySuccess = (data) => ({
  type: CONST.SEND_OTP_VERIFY_SUCCESS,
  payload: data,
});
export const sendOtpVerifyFail = (data) => ({
  type: CONST.SEND_OTP_VERIFY_FAIL,
  payload: data,
});
export const forgotPassword = (data, callback) => ({
  type: CONST.SEND_OTP_VERIFY,
  payload: { data, callback },
});
export const forgotPasswordSuccess = (data) => ({
  type: CONST.SEND_OTP_VERIFY_SUCCESS,
  payload: data,
});
export const forgotPasswordFail = (data) => ({
  type: CONST.SEND_OTP_VERIFY_FAIL,
  payload: data,
});

export const updateToken2 = (data) => ({
  type: CONST.UPDATE_TOKEN2,
  payload: data,
});
