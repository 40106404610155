import React from "react";
import { Link } from "react-router-dom";

import Email from "assets/svg/email.svg";
import Lock from "assets/svg/lock.svg";

const Settingsidebar = () => {
  return (
    <section className="profile_left_side buyersetting-left">
      <div className="settig-sidebar">
        <h6>Setting</h6>
        <Link to={"/buyerprofile"}>
          <svg
            aria-hidden="true"
            role="img"
            className="iconify iconify--ion"
            width="30"
            height="30"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 512 512"
          >
            <path
              fill="#151415"
              d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z"
            />
          </svg>
        </Link>
      </div>
      <div className="profile_menu-buyer">
        <ul>
          <div className="BTN-rnables">
            <Link to="#" className="menu_list">
              <li>
                <img src={Email} />
                <span>Email Notification</span>
              </li>
            </Link>
            <div className="enable-disable-btn">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <Link to="/buyerprofile/change-password" className="menu_list">
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <g id="ic_terms" transform="translate(-18.5 -559)">
                  <rect
                    id="Rectangle_1863"
                    data-name="Rectangle 1863"
                    width="30"
                    height="30"
                    rx="8"
                    transform="translate(18.5 559)"
                    fill="#2d1159"
                    opacity="0.1"
                  />
                  <path
                    id="Path_3755"
                    data-name="Path 3755"
                    d="M1118.692,395.286v1.224a3.326,3.326,0,0,1,2.379,3.16v3.941a3.37,3.37,0,0,1-3.409,3.333h-6.753a3.37,3.37,0,0,1-3.409-3.333V399.67a3.335,3.335,0,0,1,2.379-3.16v-1.224a4.407,4.407,0,1,1,8.814,0Zm-4.4-2.919a2.953,2.953,0,0,1,2.977,2.919v1.052H1111.3V395.27a2.954,2.954,0,0,1,2.994-2.906Zm.7,10.151a.71.71,0,0,1-1.421,0v-1.761a.71.71,0,1,1,1.421,0Z"
                    transform="translate(-1080.786 175.089)"
                    fill="#2d1159"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>

              <span>Change Password</span>
            </li>
          </Link>
        </ul>
      </div>
    </section>
  );
};

export default Settingsidebar;
