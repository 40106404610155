import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import Sliderproperties from "./Sliderproperties";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// images
// import Cardone from "assets/images/card-one.jpg";
import Main from "assets/images/main.jpg";
import Bed from "assets/images/bed.png";
import Car from "assets/images/car.png";
import Bath from "assets/images/bath.png";

const Searchfiltermidcol = () => {
  const history = useHistory();
  const goToDetail = () => {
    history.push('/profile/details-page')
  }
  return (
    <section className="Floorplanmid-main">
      <Container>
        <div className="top-filter-main">
          <Form className="serach-fitler-form">
            <div className="search-main">
              <div className="search-here">
                <FormGroup className="position-relative">
                  <Input
                    type="text"
                    name="search"
                    id="exampleEmail"
                    placeholder="London, United Kingdom"
                  />
                  <div className="search-icon-her">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--ion"
                      width="22"
                      height="22"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                        d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        stroke-width="32"
                        d="M338.29 338.29L448 448"
                      />
                    </svg>
                  </div>

                  <div className="closed-icon">
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--ion"
                      width="22"
                      height="22"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm52.7 283.3L256 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3l52.7-52.7-52.7-52.7c-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3 6.2-6.2 16.4-6.2 22.6 0l52.7 52.7 52.7-52.7c6.2-6.2 16.4-6.2 22.6 0 6.2 6.2 6.2 16.4 0 22.6L278.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6-6.2 6.3-16.4 6.3-22.6 0z"
                        fill="#CECECEFA"
                      />
                    </svg>
                  </div>
                </FormGroup>
              </div>
            </div>

            <div className="serch-btn-div">
              <div className="buttonone-drop common-btn-drop">
                <UncontrolledDropdown>
                  <DropdownToggle>Residential</DropdownToggle>
                  <DropdownMenu>
                    <ul className="submenu-down downone">
                      <li>
                        <Input type="select" name="select" id="exampleSelect">
                          <option>Residential</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {/* <div className="buttonteo-drop common-btn-drop">
              <UncontrolledDropdown>
                <DropdownToggle>140001</DropdownToggle>
                <DropdownMenu>
                  <ul className="submenu-down downone">
                    <li>
                      <Input type="select" name="select" id="exampleSelect">
                        <option>140001</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div> */}

              <div className="buttonthree-drop common-btn-drop">
                <UncontrolledDropdown>
                  <DropdownToggle>$15k - no max</DropdownToggle>
                  <DropdownMenu className="third-menu">
                    <ul className="submenu-down downthree">
                      <li>
                        <Input type="select" name="select" id="exampleSelect">
                          <option>$15,000</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </li>
                      <p>-</p>
                      <li>
                        <Input type="select" name="select" id="exampleSelect">
                          <option>$15,000</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className="buttonfour-drop common-btn-drop">
                <UncontrolledDropdown>
                  <DropdownToggle>Any Beds</DropdownToggle>
                  <DropdownMenu className="third-menu">
                    <ul className="submenu-down downthree">
                      <li>
                        <Input type="select" name="select" id="exampleSelect">
                          <option>no min</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </li>
                      <p>-</p>
                      <li>
                        <Input type="select" name="select" id="exampleSelect">
                          <option>no max</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className="buttonfour-drop common-btn-drop">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    Filters <span className="filter-count">0</span>
                  </DropdownToggle>
                  <DropdownMenu className="fourth-filter">
                    <div className="filter-head">
                      <h6>Filter</h6>
                      <p>
                        <a href="#">Clear All</a>
                      </p>
                    </div>
                    <Row>
                      <Col md={12} sm={12}>
                        <FormGroup>
                          <Label for="exampleEmail">Home Type</Label>
                          <Input type="select" name="select" id="exampleSelect">
                            <option>House</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col md={12} sm={12}>
                        <FormGroup>
                          <Label for="exampleEmail">Style</Label>
                          <Input type="select" name="select" id="exampleSelect">
                            <option>Detached</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col md={12} sm={12}>
                        <FormGroup>
                          <Label for="exampleEmail">Bathrooms</Label>
                          <Input type="select" name="select" id="exampleSelect">
                            <option>No min</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="checks-box">
                      <Row>
                        <Col lg="4">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" />
                              Parking
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Garden
                            </Label>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Garage
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Include Extended
                              Warrenty
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" /> Centralised Heating
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="thireslector">
                      <Row>
                        <Col md={12} sm={12}>
                          <FormGroup>
                            <Label for="exampleEmail">Tenure</Label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                            >
                              <option>Freehold</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col md={12} sm={12}>
                          <FormGroup>
                            <Label for="exampleEmail">Property Condition</Label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                            >
                              <option>Excellent</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col md={12} sm={12}>
                          <FormGroup>
                            <Label for="exampleEmail">Reception Rooms</Label>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                            >
                              <option>No min</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className="buttonfive-drop">
                <Button>Save search</Button>
              </div>
            </div>
          </Form>
        </div>

        <div className="search-reasult-main">
          <div className="search-filter-head">
            <h6>Search Results for </h6>
            <div className="result-div">
              <h2>London, United Kingdom</h2>
              <p>( 13 Results found )</p>
            </div>
          </div>

          <Sliderproperties />

          <div className="result-all-herr">
            {[1, 2, 3, 4, 5, 6].map((d) => {
              return (
                <div className="properties-details-listone mb-4">
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <div className="propety-images-stuc">
                        <div className="property-stuckleft">
                          <img src={Main} alt="" className="img-fluid" />
                        </div>
                        <div className="property-stuckrigth">
                          <div className="property-stuckone">
                            <img src={Main} alt="" className="img-fluid" />
                          </div>
                          <div className="property-stucktwo">
                            <img src={Main} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <div className="propety-images-details">
                        <div className="propety-box-stuck">
                          <div className="box-stuck">
                            <h6>3 BHK FLAT FOR SALE</h6>
                            <div className="three-iocn">
                              <div className="heart-col">
                                <svg
                                  aria-hidden="true"
                                  role="img"
                                  className="iconify iconify--ph"
                                  width="20"
                                  height="20"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 256 256"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M128 224a7.8 7.8 0 0 1-3.9-1C119.8 220.6 20 163.9 20 92a60 60 0 0 1 108-36a60 60 0 0 1 108 36c0 30.6-17.7 62-52.6 93.4a314.3 314.3 0 0 1-51.5 37.6a7.8 7.8 0 0 1-3.9 1Zm-3.9-15ZM80 48a44 44 0 0 0-44 44c0 55.2 74 103.7 92 114.7c18-11 92-59.5 92-114.7a44 44 0 0 0-84.6-17a8 8 0 0 1-14.8 0A43.8 43.8 0 0 0 80 48Z"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="box-one-price">
                            <h3 className="green-big">$ 150,000,00</h3>
                          </div>

                          <div className="flat-details">
                            <h4>3 BHK Flat</h4>
                            <p>
                              Added on{" "}
                              <span className="font-big">17 Dec, 2022</span>
                            </p>
                          </div>
                          <div className="location-icon">
                            <p>
                              <svg
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--ic"
                                width="24"
                                height="24"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#3399FF"
                                  d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
                                />
                              </svg>
                              4571 Giraffe Hill Drive, Frisco, Texas, 75034
                            </p>
                          </div>
                        </div>

                        <div className="propety-boxtwo-stuck mt-3">
                          <div className="facilit-list">
                            <ul>
                              <li>
                                <div className="facilit-list-one">
                                  <div className="facilit-listicon">
                                    <img src={Bed} />
                                  </div>
                                  <p>3 Bedrooms</p>
                                </div>
                              </li>
                              <li>
                                <div className="facilit-list-one">
                                  <div className="facilit-listicon">
                                    <img src={Car} />
                                  </div>
                                  <p>3 Parking</p>
                                </div>
                              </li>
                              <li>
                                <div className="facilit-list-one">
                                  <div className="facilit-listicon">
                                    <svg
                                      aria-hidden="true"
                                      role="img"
                                      className="iconify iconify--maki"
                                      width="15"
                                      height="15"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 15 15"
                                    >
                                      <path
                                        fill="#001978"
                                        d="M13 8c0 3.31-2.19 6-5.5 6S2 11.31 2 8a5.33 5.33 0 0 1 5 3.61V7H4.5A1.5 1.5 0 0 1 3 5.5v-3a.5.5 0 0 1 .9-.3l1.53 2l1.65-3a.5.5 0 0 1 .84 0l1.65 3l1.53-2a.5.5 0 0 1 .9.3v3A1.5 1.5 0 0 1 10.5 7H8v4.61A5.33 5.33 0 0 1 13 8z"
                                      />
                                    </svg>
                                  </div>
                                  <p>Garden</p>
                                </div>
                              </li>
                            </ul>

                            <div className="details-twwwo-btn">
                              <Button className="view-more">Send Offer</Button>
                              <Button type="button" className="edit-details" onClick={goToDetail}>
                                 
                                View Details
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Searchfiltermidcol;
