import ScrollTop from "components/scrollTop";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { createPropertySaveForm } from "store/actions";
import Confirmation from "store/confirmation";
import { createPropertySelector } from "store/selectors";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";

export default function Stepone() {
  const history = useHistory();
  const [showConfirm, setshowConfirm] = useState(false);
  const prevData = useSelector(createPropertySelector.stepOne);
  const dispatch = useDispatch();
  const [formFields, setFormFeilds] = useState(prevData);
  const [inputPropertyLocation, setInputPropertyLocation] = useState(false);

  const onChangeField = (e) => {
    let { name, value } = e.target;
    setFormFeilds({ ...formFields, [name]: value });
  };

  const afterConfirm = (value) => {
    setshowConfirm(false);
    if (value) {
      dispatch(createPropertySaveForm({ ...prevData, ...formFields }));
      history.push("/step2");
    } else {
      setInputPropertyLocation(true);
    }
  };

  const getDataFromLocal = () => {
    const { name, email, phone, address } =
      JSON.parse(localStorage.getItem("authUser")) ?? {};
    setFormFeilds({
      ...formFields,
      name,
      email,
      phone,
      address,
    });
  };

  useEffect(() => {
    getDataFromLocal();
  }, []);

  return (
    <>
      <Confirmation
        show={showConfirm}
        title={"Are you selling this property?"}
        handleClose={afterConfirm}
      ></Confirmation>

      <ScrollTop />
      <Header />
      <Container>
        <div className="list_your_property">
          <section className="common-steps-main">
            <Fade>
              <Row>
                {!inputPropertyLocation ? (
                  <div className="list-main">
                    <div className="list-heading">
                      <h2>Address and Ownership</h2>
                      <a href="#" style={{ borderBottom: "2px solid" }}>
                        Watch Help Videos
                      </a>
                    </div>
                    <div className="list-main-under">
                      {/* <Stepper /> */}
                      <div className="custom-steeper">
                        <div className="common-step step1 position-relative">
                          <div className="stepbox process">
                            1
                            {/* <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--ic"
                            width="18"
                            height="18"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#111"
                              d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                            />
                          </svg> */}
                          </div>
                          <span>
                            {/* Property <br /> information */}
                            Address and <br /> Ownership
                          </span>
                        </div>

                        <div className="common-step step2 position-relative">
                          <div className="stepbox uundeprocss">02</div>
                          <span>Property Details</span>
                        </div>

                        <div className="common-step step3 position-relative">
                          <div className="stepbox uundeprocss">03</div>
                          <span>Value</span>
                        </div>

                        <div className="common-step step4 position-relative">
                          <div className="stepbox uundeprocss">04</div>
                          <span>
                            Images and <br /> Video
                          </span>
                        </div>

                        <div className="common-step step5 position-relative">
                          <div className="stepbox uundeprocss">05</div>
                          <span>Submit and Review</span>
                        </div>
                      </div>
                      <div className="new_class">
                        <div className="form-heading">
                          <h3 className="fw-7">Please confirm your details</h3>
                          <a href="#" className="fw-6">
                            Amend
                          </a>
                        </div>

                        <Form className="confirmation-form">
                          <Row>
                            <Col lg={7} md={12} sm={12}>
                              <FormGroup>
                                <Label for="name">Full Name</Label>
                                <Input
                                  type="text"
                                  name="name"
                                  value={formFields?.name}
                                  id="name"
                                  onChange={onChangeField}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={5} md={12} sm={12}>
                              <FormGroup>
                                <Label for="phone">Phone number</Label>
                                <div className="phoneNumber_select">
                                  <Input
                                    type="tel"
                                    name="country-code"
                                    className="country_code"
                                    value="+91"
                                    id="country-code"
                                    placeholder="7888888959"
                                  ></Input>
                                  <Input
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    value={formFields?.phone}
                                    onChange={onChangeField}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={7} md={12} sm={12}>
                              <FormGroup>
                                <Label for="email">Email address</Label>
                                <Input
                                  type="email"
                                  name="email"
                                  id="email"
                                  value={formFields?.email}
                                  onChange={onChangeField}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg={5} md={12} sm={12}>
                              <FormGroup>
                                <Label for="postalCode">Post Code</Label>
                                <Input
                                  type="text"
                                  name="postalCode"
                                  id="postalCode"
                                  value={formFields?.postalCode}
                                  onChange={onChangeField}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <FormGroup>
                            <Label for="address">Address</Label>
                            <Input
                              type="address"
                              name="address"
                              id="address"
                              value={formFields?.address}
                              onChange={onChangeField}
                            />
                          </FormGroup>

                          <Button
                            className="btn-submit"
                            onClick={() => {
                              if (
                                !formFields?.address ||
                                !formFields?.postalCode ||
                                !formFields?.name ||
                                !formFields?.email ||
                                !formFields?.phone
                              ) {
                                alert("All fields are required!");
                                return;
                              }
                              setshowConfirm(true);
                            }}
                          >
                            Continue
                          </Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="list-main">
                    <div className="list-heading">
                      <h2>Address and Ownership</h2>
                      <a href="#" style={{ borderBottom: "2px solid" }}>
                        Watch Help Videos
                      </a>
                    </div>
                    <div className="list-main-under">
                      {/* <Stepper /> */}
                      <div className="custom-steeper">
                        <div className="common-step step1 position-relative">
                          <div className="stepbox process">
                            1
                            {/* <svg
                            aria-hidden="true"
                            role="img"
                            className="iconify iconify--ic"
                            width="18"
                            height="18"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#111"
                              d="M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z"
                            />
                          </svg> */}
                          </div>
                          <span>
                            {/* Property <br /> information */}
                            Address and <br /> Ownership
                          </span>
                        </div>

                        <div className="common-step step2 position-relative">
                          <div className="stepbox uundeprocss">02</div>
                          <span>Property Details</span>
                        </div>

                        <div className="common-step step3 position-relative">
                          <div className="stepbox uundeprocss">03</div>
                          <span>Value</span>
                        </div>

                        <div className="common-step step4 position-relative">
                          <div className="stepbox uundeprocss">04</div>
                          <span>
                            Images and <br /> Video
                          </span>
                        </div>

                        <div className="common-step step5 position-relative">
                          <div className="stepbox uundeprocss">05</div>
                          <span>Submit and Review</span>
                        </div>
                      </div>
                      <div className="new_class">
                        <div className="form-heading">
                          <div>
                            <h3 className="fw-7">Property location</h3>
                            <p
                              style={{
                                fontSize: "18px",
                                letterSpacing: "0.18px",
                                color: "#190835",
                              }}
                            >
                              Please enter the address of the property to be
                              listed
                            </p>
                          </div>
                        </div>

                        <Form className="confirmation-form">
                          <FormGroup>
                            <Label for="postalCode">Postal Code</Label>
                            <Input
                              type="text"
                              name="postalCode"
                              id="postalCode"
                              value={formFields?.postalCode}
                              onChange={onChangeField}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="address">Address</Label>
                            <Input
                              type="address"
                              name="address"
                              id="address"
                              value={formFields?.address}
                              onChange={onChangeField}
                            />
                          </FormGroup>
                          <div className="floor-plan-btns steps-btn mt-5">
                            <Button
                              className="back-btn"
                              onClick={() => setInputPropertyLocation(false)}
                            >
                              Back
                            </Button>
                            <Button
                              className="Continue-btn"
                              onClick={() => {
                                if (
                                  !formFields?.address ||
                                  !formFields?.postalCode
                                ) {
                                  alert("All fields are required!");
                                  return;
                                }
                                dispatch(
                                  createPropertySaveForm({
                                    ...prevData,
                                    formFields,
                                  })
                                );
                                history.push("/step2");
                              }}
                            >
                              Continue
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                )}
              </Row>
            </Fade>
          </section>
        </div>
      </Container>
      <Footer />
    </>
  );
}
